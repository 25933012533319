import { MaterialModule } from './../material-module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkvirtualComponent, IsAllSelectedPipe } from './components/cdkvirtual/cdkvirtual.component';
import { HttpClientModule } from '@angular/common/http';
import { PopoverModule } from 'ngx-smart-popover';
import { formChangesDirective } from './directives/form-Changes.directive';
import { TenantManagementCommunication } from './Communication/Common/TenantManagement.Service';
import { inputtypeDirective } from './directives/inputtype.directive';
import { NummaxLength } from './directives/num-maxlength.directive';
import { NotificationServiceCommunication } from './Communication/Common/NotificationServices.Service';
import { heightCalcDirective } from './directives/heightCalc.directive';



import { RouteLoaderService } from './Communication/Common/route-loader.service';
import { RouteLoaderDataService } from './Communication/Common/route-loader.data.service';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { AgCommonModule } from '../ag-common/ag-common.module';
import { AlphaDictonaryComponent } from './components/alpha-dictonary/alpha-dictonary.component';
import { SlideActionComponent } from './components/slide-action/slide-action.component';
import { AllowedSpecialCharacterDirective } from './directives/allowedSpecialCharacter.directive';
import { SignalRCommunication } from './Communication/Common/SignalRCommunication.Service';
import { CharacterCountDirective } from './directives/app-character-count.directive';
@NgModule({
  declarations: [CdkvirtualComponent,formChangesDirective,
    //Directives
    inputtypeDirective,
    AllowedSpecialCharacterDirective,
    heightCalcDirective,
    NummaxLength,
    MenuComponent,
    CreateModalComponent,
    CustomTableComponent,
      AlphaDictonaryComponent, SlideActionComponent,IsAllSelectedPipe, CharacterCountDirective],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PopoverModule,
    RouterModule,
    AgCommonModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    AgCommonModule,
    ReactiveFormsModule,
    CdkvirtualComponent,
    HttpClientModule,
    PopoverModule,
    formChangesDirective,
//Directives
    inputtypeDirective,
    AllowedSpecialCharacterDirective,
    NummaxLength,
    heightCalcDirective,
    MenuComponent,
    CreateModalComponent,
    AlphaDictonaryComponent,
      CustomTableComponent,
      SlideActionComponent,
      CharacterCountDirective
  ],
  providers: [
    TenantManagementCommunication,
    NotificationServiceCommunication,
    RouteLoaderService,
    RouteLoaderDataService,
    SignalRCommunication
  ]

})
export class SharedModule { }
