import { Injectable } from '@angular/core';
import moment from 'moment';
import { LocalizedMonthsModel } from 'src/app/shared/shared-models';
import * as DefaultErrors from '../../../assets/errors/error.en-US.json';
import * as EnUs from '../../../assets/i18n/en-US.json';
import { findIana } from 'windows-iana';


declare var $: any;
const DEFAULT_LOCALE = 'en-US';
const DEFAULT_CURRENCY = 'USD';


export const enum JsonDataSourceType {
    ContactType
}

@Injectable(
    {
        providedIn: 'root'
    }
)
export class Localization {

    public captions: any;
    public localeCode: string = DEFAULT_LOCALE;
    public currencyCode: string = DEFAULT_CURRENCY;
    public currencySymbol = '';
    public setFloatLabel:  string = 'always';
    public setFloatLabelNever: string = 'never';
    public isNewStyle: boolean = false;
    public propDateFormatlocaleCode: string = DEFAULT_LOCALE;
    inputDateFormat : string;

    /**
     * Locale Days with Valid ID.
     */


    private errorCaptions: any = {};

    constructor() {
        this.SetLocaleBasedProperties();
    }

    public SetLocaleBasedProperties() {
        this.setLocaleCode();
        this.setCurrencySymbol();
        this.captions = this.getCaptions();
        this.errorCaptions = this.getErrorCaptions();
        this.inputDateFormat = moment.localeData(this.propDateFormatlocaleCode).longDateFormat('L');
    }

    private getJsonUrl(type: JsonDataSourceType): string {
        let url = '';
        if(type === JsonDataSourceType.ContactType) {
            url = `./assets/i18n/DataSource/${this.GetUserLanguage()}.ContactTypes.json`;
        }
        return url;
    }

    private loadJson(type: JsonDataSourceType): any {
        let url: string = this.getJsonUrl(type);
        let jsonResult: any;
        $.ajax({
            url: url,
            async: false,
            success: function (result) {
                if (typeof result == 'object') {
                    jsonResult = result;
                }
                else {
                    jsonResult = [];
                }
            },
            error: function (result) {
                jsonResult = [];
            }
        });

        if (jsonResult.length == 0) {
            $.ajax({
                url: `./assets/i18n/DataSource/${this.localeCode}.ContactTypes.json`,
                async: false,
                success: function (result) {
                    jsonResult = result;
                },
                error: function (result) {
                    jsonResult = [];
                }
            });
        }

        if (jsonResult.length == 0) {
            $.ajax({
                url: `./assets/i18n/DataSource/en-US.ContactTypes.json`,
                async: false,
                success: function (result) {
                    jsonResult = result;
                },
                error: function (result) {
                    jsonResult = [];
                }
            });
        }
        return jsonResult;
    }

    /**
     * Returns the language based JSON
     */
    public getCaptions() {
        let userLanguage = this.GetUserLanguage();
        let languageJsonValue = (userLanguage == '' || userLanguage == null ? DEFAULT_LOCALE : userLanguage) + '.json';

        let languageJson: any = this.getLocaleLanguageJson(languageJsonValue);
        if (languageJson != null) {
            if (!languageJson[0].success) {
                languageJsonValue = this.localeCode + '.json';
                languageJson = this.getLocaleLanguageJson(languageJsonValue);
            }
            return languageJson[0].json;
        }
    }

    private getLocaleLanguageJson(languageJsonValue: string) {
        let languageJson = [];
        $.ajax({
            url: './assets/i18n/' + languageJsonValue,
            async: false,
            success: function (result) {
                if (typeof result == 'object') {
                    languageJson.push({
                        'json': result,
                        'success': true
                    });
                }
                else {
                    languageJson.push({
                        'json': EnUs,
                        'success': false
                    });
                }
            },
            error: function (result) {
                languageJson.push({
                    'json': EnUs,
                    'success': false
                });
            }
        });
        return languageJson;
    }

    private async getLocaleJSON(name: string): Promise<any> {
        // TO DO need to implement Promise instead of ajax( jquery )
        // let url: string = `./assets/i18n/${name}`;
        // let value: any = this.http.get<any>(url).toPromise();
        // let json: any = await value;
    }

    /**
     * Returns the Localized error string from Error.json file.
     * @param code Error Code (as in error.json)
     */
    public getError(code: number): string {
        if (!code || code == -1 || code == -2 || code == 0) {
            return (
                this.errorCaptions[-2] +
                '.' +
                '<br><br>' +
                '<span> Error Code: ' +
                code +
                '</span>'
            );
        } else {
            if (this.errorCaptions[code]) {
                return this.errorCaptions[code];
            } else {
                return (
                    this.errorCaptions[-2] +
                    '.' +
                    '<br><br>' +
                    '<span> Error Code: ' +
                    code +
                    '</span>'
                );
            }
        }
    }

    private GetUserLanguage(): string {
        let userPreferred = this.GetUserInfo('language'); let propertyLanguage = this.GetPropertyInfo('UserLanguage');
        let userLanguage = 'en-US'
        if(this.validateString(userPreferred)) {
            userLanguage = userPreferred;
        } else if (this.validateString(propertyLanguage)) {
            userLanguage = propertyLanguage;
        }
        return userLanguage;
    }

    public validateString(input: string) {
        if (input != 'null' && input != null && input != undefined && input != '') {
            return true;
        }
        return false;
    }
    private getErrorCaptions() {
        let userLanguage = this.GetUserLanguage();
        let errorJsonValue = 'error.' + (userLanguage == '' || userLanguage == null ? DEFAULT_LOCALE : userLanguage) + '.json';
        let errorJson: any = this.loadErrorJSON(errorJsonValue);

        if (errorJson != null) {
            if (!errorJson[0].success) {
                errorJsonValue = 'error.' + this.localeCode + '.json';
                errorJson = this.loadErrorJSON(errorJsonValue);
            }
            return errorJson[0].json;
        }
    }

    /**
     * This method returns list of error messages
     * @param errorCode Error code
     */
    public getErrorText(errorCode: number[]): string {
        let errorTxt = '';
        if (errorCode && errorCode.length > 0) {
            errorCode.forEach((errocodeObj)=>{
                errorTxt += '<span>' + this.getError(errocodeObj) + '</span></br></br>';
            })
        }
        return errorTxt;
    }
    private loadErrorJSON(errorJsonValue: string) {
        let errorJson = [];
        $.ajax({
            url: './assets/errors/' + errorJsonValue,
            async: false,
            success: function (result) {
                if (typeof result == 'object') {
                    errorJson.push({
                        'json': result,
                        'success': true
                    });
                }
                else {
                    errorJson.push({
                        'json': DefaultErrors,
                        'success': false
                    });
                }
            },
            error: function (result) {
                errorJson.push({
                    'json': DefaultErrors,
                    'success': false
                });
            }
        });
        return errorJson;
    }

    private ReadCookie(name: string) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    public GetPropertyInfo(name: string) {
        return this.GetLocalStorageValue('propertyInfo', name);
    }
    public GetUserInfo(name: string) {
        return this.GetsessionStorageValue('_userInfo', name);
    }
    public GetUserSettings(name: string) {
        return this.GetLocalStorageValue('userSettings', name);
    }
    public GetLocalStorageValue(key: string, name: string) {
        var nameEQ = name + '=';
        var propertyInfo = localStorage.getItem(key)
        if (propertyInfo != null) {
            var ca = propertyInfo.split(';');

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    private setLocaleCode() {
        const localeCode = this.GetPropertyInfo('Language'); //bug fix for 27078
        this.localeCode = localeCode ? localeCode : DEFAULT_LOCALE;
        moment.locale(this.localeCode);
    }

    private setLocaleCurrency() {
        const currencyCode = this.GetPropertyInfo('Currency');
        this.currencyCode = currencyCode == null ? DEFAULT_CURRENCY : currencyCode;
      }

    getLongDaysOfWeek(): string[] {
        return moment.weekdays(true);
    }

    generateMonthsArr(): LocalizedMonthsModel[] {
      const monthsShortKeys: string[] = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      const monthsLong: string[] = moment.months();
      const monthsShort: string[] = moment.monthsShort();
      const returnArr: LocalizedMonthsModel[] = [];
      for (let i = 0; i < 12; i++) {
        returnArr.push({
          id: i,
          short: monthsShort[i],
          long: monthsLong[i],
          code: monthsShortKeys[i]
        });
      }
      return returnArr;
    }

      private setCurrencySymbol() {
        const decimalNumber = 1;
        this.currencySymbol = decimalNumber
          .toLocaleString(this.localeCode, {
            style: 'currency',
            currency: this.currencyCode,
            minimumFractionDigits: 0
          })
          .replace(/[\d]/g, '')
          .trim();
      }
    convertDateTimeToAPIDateTime(value: Date) {
        return moment(value).format("YYYY-MM-DDTHH:mm")
    }

    convertDateObjToAPIdate(value: Date | string, separator: string = "-"): string {
        if (typeof value == "string") {
            value = this.getDate(moment(value).format("YYYY-MM-DDTHH:mm"));
        }
        return (
            value.getFullYear() +
            separator +
            (value.getMonth() + 1) +
            separator +
            value.getDate()
        );
    }

    getDate(input: any): Date {

        if ((typeof input) === 'string') {
            // Including T in the ISO format if not present
            if (input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
                input = input.replace(' ', 'T');
            }
            let dateString: string = input;
            // yyyy-MM-ddTHH:mm or yyyy-MM-ddTHH:mm:ss
            if (input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/) || input.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
                let splitDateArray = input.split('-');  //Contains the yyyy, MM, ddTHH:mm
                let splitTimeArray = splitDateArray[2].split('T'); //contains the dd, HH:mm:ss
                let splitHourArray = splitTimeArray[1].split(':') // contains HH, mm
                // Appending timezone to support Safari browser
                dateString = `${input}${this.getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitTimeArray[0]), Number(splitHourArray[0]))}`;
            }
            // yyyy-MM-dd
            else if (input.match(/^\d{4}-\d{2}-\d{2}$/)) {
                let splitDateArray = input.split('-');
                dateString = `${input}T00:00${this.getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitDateArray[2]))}`;
            }
            // yyyy-MM-ddTHH:mm [am/pm]
            else if (input.match(/^\d{4}-\d{2}-\d{2}T\d{1,2}:\d{1,2} [ap][m]$/i)) {
                const inputDate = input.split('T')[0];
                const inputTime = this.convertAMPMTimeStringTo24Time(input.split('T')[1]);
                let splitDateArray = input.split('-');  //Contains the yyyy, MM, ddTHH:mm
                let splitTimeArray = splitDateArray[2].split('T');  //contains the dd, HH:mm:ss
                let splitHourArray = splitTimeArray[1].split(':') // contains HH, mm
                dateString = `${inputDate}T${inputTime}${this.getTimezoneOffSet(Number(splitDateArray[0]), Number(splitDateArray[1]) - 1, Number(splitTimeArray[0]), Number(splitHourArray[0]))}`;
            }
            return new Date(dateString);
        }
        if (typeof input === 'number') {
            // Custom logic for number param goes here
        }
        return new Date(input);
    }

    // Returns client's timezone offset (eg: +05:30)
    getTimezoneOffSet(year: number, monthIndex: number, day: number, hour = 0): string {
        var date = new Date(year, monthIndex, day, hour),
            timezoneOffset = date.getTimezoneOffset(),
            hours = ('00' + Math.floor(Math.abs(timezoneOffset / 60))).slice(-2),
            minutes = ('00' + Math.abs(timezoneOffset % 60)).slice(-2),
            string = (timezoneOffset >= 0 ? '-' : '+') + hours + ':' + minutes;
        return string;
    }

    convertAMPMTimeStringTo24Time(input: string): string {
        const matches = input.toLowerCase().match(/(\d{1,2}):(\d{2}) ([ap]m)/);
        const hours = this.getHour(matches);
        return (hours.toString().length === 1 ? ('0' + hours.toString()) : hours.toString()) + ':' + matches[2] + ':00';
    }

    getHour(matches): number {
        if (matches[3] == 'am' && parseInt(matches[1]) === 12) {
            return 0
        }
        else if (matches[3] == 'am' && parseInt(matches[1]) < 12) {
            return parseInt(matches[1])
        }
        else if (matches[3] === 'pm' && parseInt(matches[1]) === 12) {
            return 12
        }
        else {
            return 12 + parseInt(matches[1])
        }
    }

    /**
  * Returns formatted date and day as "Wednesday, April 17, 2019"
  * @param Date
  */
    getLocalizedDayMonthDateYear(value: Date): string {
        return `${this.getLocalizedDay(value)}, ${moment(value).format("LL")}`;
    }


    getLocalizedDay(value: Date): string {
        return moment(value).format("dddd");
    }


    /**
     * Converts a javascript date to ISO date format string (C# API can understand this format).
     * @param Date javascript date or Javascript ISO string.    *
     */
    ConvertDateToISODate(dt: Date): string {
        try {
            if (typeof dt == "string") dt = this.getDate(moment(dt).format("YYYY-MM-DDTHH:mm"));
            return (
                dt.getFullYear() +
                "-" +
                (dt.getMonth() + 1 > 9
                    ? dt.getMonth() + 1
                    : "0" + (dt.getMonth() + 1)) +
                "-" +
                (dt.getDate() > 9 ? dt.getDate() : "0" + dt.getDate())
            );
        } catch (ee) { }
    }

    getformattedDateFromDDMMYYYY(date: string, separator: string = '/'): Date {
        let f = `DD${separator}MM${separator}YYYY`;
        return this.getDate(moment(date, f).format("YYYY-MM-DDTHH:mm"));
      }


      ToDate(value: string, inputFormat: string): Date {
        return this.getDate(moment(value, inputFormat).format("YYYY-MM-DDTHH:mm"));
      }

      public GetsessionStorageValue(key: string, name: string) {
        var nameEQ = name + "=";
        var propertyInfo = sessionStorage.getItem(key)
        if (propertyInfo != null) {
          var ca = propertyInfo.split(";");

          for (var i = 0; i < ca.length; i++) {
            var c = ca[i].trim();
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
          }
        }
        return null;
      }

        /**
   * Converts a javascript date to localized date string.
   * @param Date javascript date.
   */
  LocalizeDate(value: Date | string): string {
    if (typeof value == 'string') {
      value = this.getDate(moment(value).format('YYYY-MM-DDTHH:mm'));
    }
    return moment(value).format('DD MMM YYYY');
    /*
        * using Javascript native method
        let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return value.toLocaleDateString(this.localeCode, options);
        */
  }

    /**
   * Converts a javascript date to localized time string.
   * @param Date javascript date.    *
   */
  LocalizeTime(value: Date | string, isCapital = true): string {
    // in retail and common
    // LocalizeTime(value: Date | string, isCapital = false): string {

    if (typeof value == 'string') {
      value = this.getDate(moment(value).format('YYYY-MM-DDTHH:mm'));
    }
    if (isCapital) {
      return moment(value).format('LT');
    } else {
      return moment(value)
        .format('LT')
        .toLowerCase();
    }
  }

  getFirstDayOfWeek(): number {
    const localeData = moment.localeData();
    return localeData.firstDayOfWeek();
  }

  replacePlaceholders(caption: string, placeholders: string[], values: string[] | number[]): string {
    caption = caption.replace(/[{}]/g, "");

    placeholders.forEach((x,i)=>{
        caption = caption.replace(placeholders[i], values[i].toString());
    })
    return caption;
  }

    /**
   *  Converts to localized currency
   * @param value Valid number with decimal seperator as '.'
   * @param currencySymbolRequired Flag to enable/disable currency symbol.
   * @param minFraction Defines the minimum fraction in localized currency.
   */

  public localizeCurrency(value: any, currencySymbolRequired: boolean = true, minFraction: number = 2): string {
    let decimalNumber: number;
    if ((!value && value != '0') || value.length === 0 || isNaN(Number(value))) {
      return parseFloat('0').toFixed(minFraction);
    }

    decimalNumber = parseFloat(parseFloat(value).toFixed(minFraction));
    if (isNaN(decimalNumber)) { decimalNumber = 0.0; }
    return currencySymbolRequired
      ? decimalNumber.toLocaleString(this.localeCode, {
        style: 'currency',
        currency: this.currencyCode,
        minimumFractionDigits: minFraction
      })
      : decimalNumber.toLocaleString(this.localeCode, {
        minimumFractionDigits: minFraction
      });
  }

  ToggleLoader(loader) {
    const loadingContainer = document.getElementById('cover-spin');
    if (loadingContainer) {
        if (loader) {
            loadingContainer.style.display = 'block';
        } else {
            loadingContainer.style.display = 'none';
        }
    }
}

LocalizeDateTimeFormatSecondsDDMMMYYYYheader(value: Date): string {
    if (typeof value == 'string') {
       value = this.getDate(value);
       }
    return this.localizeDisplayDate(value) + ' ' + this.getLocalizedPropertyCurrentTime();
  }

  getLocalizedCurrentDateTime(): string{
    let timeZoneStr: string = '';
    let dateStr ='';
    timeZoneStr = this.GetTimeZone();
    dateStr = this.getDate(new Date()).toLocaleDateString(this.propDateFormatlocaleCode, { timeZone: timeZoneStr });
    return this.localizeDisplayDate(dateStr) + ' ' + this.getLocalizedPropertyCurrentTime();
}

localizeDisplayDate(value: Date | string, isDateTime: boolean = false): string {
  if (typeof value == 'string') {
    value = this.getDate(value);
  }
  const displayStyle: string = isDateTime ? this.inputDateFormat : this.inputDateFormat;
  return moment(value).format(displayStyle);
}

protected getLocalizedPropertyCurrentTime(): string {
  let timeStr: string = '';
  let timeZoneStr: string = '';
  timeZoneStr = this.GetTimeZone();
  timeStr = this.getDate(new Date()).toLocaleTimeString(this.propDateFormatlocaleCode, { timeZone: timeZoneStr });
  
  return timeStr;
}

public GetTimeZone()
{
    const propertyTimeZone = this.GetPropertyInfo1('TimeZone');
        try {
            const result = findIana(propertyTimeZone);
            if(result && result.length > 0){
              return result[0]; // gets relevant IANA Time zone for Windows Time zone
            }else
            {
              return propertyTimeZone; //sets IANA Time zone
            }
        }
        catch (error) {
            throw new Error(`INVALID TIME ZONE SET - ${this.GetPropertyInfo1('TimeZone')}`);
          }
}

public GetPropertyInfo1(name: string) {
  return this.GetsessionStorageValue('propertyInfo', name);
}

getCurrentDate(): Date {
  return new Date();
}

  setLocalCookie(cookieIdname,custID){
    document.cookie = cookieIdname+"=" + custID + ";";
  }
  getLocalCookie(cookieIdname){
    let name = cookieIdname+"=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  clearLocalCookie(cookieIdname){
    document.cookie = cookieIdname+'=; Expires='+new Date();
  }

  public SetSupportUserMailId(email: string){
    sessionStorage.setItem('supportUserMailId', email);
  }

}
