import { Injectable } from '@angular/core';
import { Login } from './login.model';
import { BaseResponse, ServiceParams } from '../shared/models/http.model';
import { TenantLoginService } from './TenantLoginService.service';

@Injectable({
  providedIn: 'root'
})
export class LoginBusiness {

  constructor(private _LoginDataService : TenantLoginService) { }

  public validateLogin(data : ServiceParams) : Promise<BaseResponse<Login>> {
    
    return this._LoginDataService.validateLogin(data);
  } 
  public mapToAPI(userName:any,password:any, tenantid:any,productid:any,tenantCode:any) : Login{

    return{
      userName : userName,
      password : password,
      tenantId : tenantid,
      productId : productid,
      tenantCode: tenantCode
    }
  }

  public GetBearerToken(_userName : string):Promise<string>
  {
      return this._LoginDataService.GetBearerToken(_userName);
  } 

  public async GetTenantbyEnvironmentConfig():Promise<string>
  {
      let result:any = await this._LoginDataService.GetTenantbyEnvironmentConfig();
    //  console.log(result.result)
      return result.result;
  } 
}
