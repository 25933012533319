import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/core/localization/Localization';
import { EnableResortFinance, menuPosition } from '../../shared/global.constant';
import { ExpireSessionService } from 'src/app/login/expire-session.service';
import { APITenant } from 'src/app/tenant-management/tenent-setup/tenant-setup.model';
import { MatDialog } from '@angular/material/dialog';
import { TenantSetupDataService } from 'src/app/tenant-management/tenent-setup/tenant-setup.data.service';
import { UpdateTenantComponent } from 'src/app/core/layout/update-tenant/update-tenant.component';





@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TenantSetupDataService]

})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {

  menuList: any;
  menuItems: any;
  isMenu: boolean;
  position: string;
  moreTextName = 'More';
  moreListItem: any = [];
  isSubMenu = false;
  searchOpen = false;
  menusearchOpen = false;
  headerPopOver: any;
  logOutPopOver: any;
  lowerLevelSubMenu: any;
  verticalFlag = false;
  levelMenu: menuPosition;
  menuType = menuPosition;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('userPopOver') userPopUp: ElementRef;
  @ViewChild('logOutPopOver') logPopOver: ElementRef;
  @ViewChild('navBar') navBar: ElementRef;
  @ViewChild('RouterOutlet') outlet: RouterOutlet;

  selectedItem: any;
  userName: string;
  userText: string; // change after login creation
  userRole: string;
  firstName: string;
  lastName: string;
  captions: any;

  currentTenantId: any;
  tenantList: APITenant[];
  tenantName: string;
  tenantId: number;
  isAdminUser = true;


  @Input('menu')
  set MenuValue(value) {
    this.menuList = value;
    this.levelMenu = value.menuType;
  }

  constructor(public router: Router
    ,         private _localization: Localization
    ,         private _expireSessionService: ExpireSessionService,
              public dialog: MatDialog,
              private _tenantSetupDataService: TenantSetupDataService,

    ) {
  }

  ngOnInit() {
    this.captions = this._localization.captions;
    this.userName = this._localization.GetUserInfo("userName");
    this.firstName = this._localization.GetUserInfo("firstName");
    this.lastName = this._localization.GetUserInfo("lastName");
    this.userRole = this._localization.GetUserInfo("roleName");
    if (this.firstName == "undefined" || this.lastName == "undefined" || this.firstName == undefined || this.lastName == undefined) {
      this.userText = this.userName ? this.userName.charAt(0).toUpperCase() : '';
    }
    else {
      this.userText = this.firstName.charAt(0).toUpperCase() + this.lastName.charAt(0).toUpperCase();
    }
    if (this.levelMenu === menuPosition.combo) {
      this.selectedItem = this.menuList.menu.find(x => x.routePath === this.router.url);
      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
        this.selectedItem = this.menuList.menu.find(menu => menu.routePath === this.router.url);
        this.selectedItem = {...this.selectedItem};
      });
    }

    this.currentTenantId = 0;
    this.isAdminUser = sessionStorage.getItem('loggedUser') == "agilysys" ? true:false;
    this.currentTenantId = parseInt(sessionStorage.getItem('tenantId'));
    this.initTenants();
      }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.text === val2.text;
  }

  ngAfterViewInit() {
    if (this.levelMenu === menuPosition.initial) {
      setTimeout(() => {
        this.bindHeaderData();
      }, 1);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  // Calculation for more options
  bindHeaderData() {
    const menuItem = cloneDeep(this.menuList.menu);
    let menuTobeShown = [];
    this.moreListItem = [];
    let headerWidth = 0;
    let moreCalc;
    const parentelementWidth = this.navBar.nativeElement.clientWidth;
    moreCalc = false;


    menuItem.forEach((menuObj)=>{
      const elementWidth = this.getTextWidth(menuObj.text, '100 14px LatoWeb');
      headerWidth += elementWidth + 66;
      if ((parentelementWidth - headerWidth) >= elementWidth) {
        moreCalc = false;
        menuTobeShown.push(menuObj);
      } else {
        moreCalc = true;
      }
  })
    if (moreCalc) {
      menuTobeShown = [];
      headerWidth = 0;
      const moreWidth = this.getTextWidth(this.moreTextName, '100 14px LatoWeb');
      const LegendsWidth = parentelementWidth - (moreWidth + 66);

      menuItem.forEach((menuObj)=>{
        const elementWidth = this.getTextWidth(menuObj.text, '100 14px LatoWeb');
        headerWidth += elementWidth + 66;
        if ((LegendsWidth - headerWidth) >= elementWidth) {
          menuTobeShown.push(menuObj);
        } else {
          this.moreListItem.push({ imgPath: menuObj.imgPath, text: menuObj.text, routePath: menuObj.routePath, visibility: menuObj.visibility });
        }
    })
    }
    this.menuItems = menuTobeShown;
    this.onResize();
  }

  // Text width
  getTextWidth(text, font) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text.toUpperCase());
    if (canvas) {
      canvas.remove();
    }
    return Math.ceil(metrics.width);
  }

  userPop(logOutPopOver) {
    this.logOutPopOver = logOutPopOver;
    const logOut = this.logOutPopOver.element.nativeElement;
    logOut.querySelectorAll('div')[0].style.display = 'none';
    setTimeout(() => {
      const popover = logOut.querySelectorAll('div');
      const leftValue = popover[0].style.left.split('px');
      popover[0].style.left = (Number(leftValue[0])) + 'px';
      popover[0].style.top = 60 + 'px';
      popover[0].style.display = 'block';
    }, 100);
  }

  onResize() {
    // For closing the more pop over
    if (this.headerPopOver) {
      this.headerPopOver.hide();
    }

    // For closing the logout pop over
    if (this.logOutPopOver) {
      this.logOutPopOver.hide();
    }
  }

  openPopUp(popOver) {
    this.headerPopOver = popOver;
  }

  async logout() {
    this._expireSessionService.logout();
    sessionStorage.removeItem('_authorizedUser');
  }

  onSearch(e: any) {
    /* Search Logic Goes Here */
  }

  openGlobalSearch(e, type) {
    this.searchOpen = !this.searchOpen;
    this.menusearchOpen=false;
  }

  openMenuSearch() {
    this.menusearchOpen = !this.menusearchOpen;
    this.searchOpen=false;
  }

  OptionSelected(event) {
    this.searchOpen = false;
  }

  MenuOptionSelected(event) {
  this.menusearchOpen = false;
}

  navigateTo(option) {
    this.router.navigate([option.value.routePath],{state:{ShowPopup: true}});
  }


  async initTenants() {
    this.tenantList = await this.getTenants();
    this.setTenant();
  }

  setTenant() {
    let tenant = this.tenantList.find(x => x.id === this.currentTenantId);
    this.tenantName = tenant ? tenant.tenantName : '';
    this.tenantId =  tenant.id;
}

  public async getTenants(): Promise<APITenant[]> {
    try {
      return await this._tenantSetupDataService.getTenants();
    } catch(e) {
        console.error(e);
        throw e;
    }
  }

  async changeTenant(e) {
    this.tenantList = await this.getTenants();
    this.setTenant();
    this.dialog.open(UpdateTenantComponent, {
      width: '400px',
      height: '250px',
      disableClose: true,
      data: {
        tenantListOriginal: this.tenantList,
        currentTenantId: this.currentTenantId
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        console.log("Update Session with New Tenant", res);
        var id = sessionStorage.getItem('tenantId')
        sessionStorage.setItem('tenantId', (res.id).toString());
       var propInfo = sessionStorage.getItem('propertyInfo');
       var updatedPropInfo = propInfo.replace('TenantId='+id.toString(),'TenantId='+(res.id).toString())
       sessionStorage.setItem('propertyInfo',updatedPropInfo);
       sessionStorage.setItem('platformTenantId',res.platformTenantId);
       sessionStorage.setItem(EnableResortFinance, res.enableResortFinance.toString());
        window.location.reload();
      }
    });
  }

}
