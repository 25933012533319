import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Localization } from 'src/app/core/localization/Localization';
import { HttpCallService } from './http-call.service';
import { ServiceParams, BaseResponse } from '../../models/http.model';
import { environment } from 'src/environments/environment';
import { Utilities } from '../../directives/utilities';

@Injectable()
export class TenantManagementCommunication extends HttpCallService {

    constructor(httpclient: HttpClient, localization: Localization, Route: ActivatedRoute,_utils:Utilities) {
        super(environment["TenantManagement"], httpclient, localization,_utils, Route);
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putPromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean, customParams: any) {
        if(handleErr !== false) {
            handleErr = true;
        }
        const response$: Promise<BaseResponse<T>> = super.putPromiseWithCustomHeader<BaseResponse<T>>(params,customParams);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean,customParams: any): Promise<T> {
        if(handleErr !== false) {
            handleErr = true;
        }
        const response$: Promise<BaseResponse<T>> = super.postPromiseWithCustomHeader<BaseResponse<T>>(params,customParams);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    public async deletePromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean, customParams: any): Promise<T> {
        if(handleErr !== false) {
            handleErr = true;
        }
        const response$: Promise<BaseResponse<T>> = super.deletePromiseWithCustomHeader<BaseResponse<T>>(params,customParams);
        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }
}
