import { Localization } from 'src/app/core/localization/Localization';
import { Injectable } from '@angular/core';
import _ from 'lodash';
import { cloneDeep } from 'lodash';



export const PROPERTY_DATE = 'propertyDate';



@Injectable({
  providedIn: 'root'
})
export class CommonPropertyInformation {
  protected _currentDate: Date;


  constructor(public localization: Localization) {
  }

  public get CurrentDate() {
      this.setDate();
      return cloneDeep(this._currentDate);
  }


  public setDate() {
    const propertyData: any = sessionStorage.getItem(PROPERTY_DATE);
    if (propertyData) {
        this._currentDate = this.localization.getDate(propertyData);
    } else {
        this._currentDate = this.localization.getCurrentDate();
    }
    this._currentDate.setHours(0, 0, 0, 0);
}

public set UpdateDate(newDate: Date) {
  this._currentDate = this.localization.getDate(newDate);
  this._currentDate.setHours(0, 0, 0, 0);
}

public SetPropertyDate(newDate: Date, updatelocalstore: boolean = true) {
  this.UpdateDate = newDate;
  if (updatelocalstore) {
      sessionStorage.setItem(PROPERTY_DATE, cloneDeep(this._currentDate).toString());
      localStorage.setItem(PROPERTY_DATE, cloneDeep(this._currentDate).toString());
  }
}

}
