import { Injectable } from '@angular/core';
import { TenantManagementCommunication } from 'src/app/shared/Communication/Common/TenantManagement.Service';
import { TenantRoutes } from 'src/app/core/Extensions/tenant-route';
import { Login } from './login.model';

@Injectable({
  providedIn: 'root',
})

export class LoginDataService {

  constructor(private _tenantManagementCommunication: TenantManagementCommunication) {
  }

  public  async validateLogin(data : Login): Promise<boolean> {
    try {
      return await this._tenantManagementCommunication.postPromise<boolean>(
            { route: TenantRoutes.ValidateLogin,
              body: data }, true);
    } catch (error) {
        console.error(error);
    }
  }  

  public async GetBearerToken(_userName :string):Promise<string>
  {
    try {
      return await this._tenantManagementCommunication.getPromise<string>({route: TenantRoutes.GetBearerToken,uriParams: { username: _userName }});
    } catch(e) {
        console.error(e);
                    throw e;
    }
  }    
}
