import { Component, OnInit, Inject, ElementRef, Renderer2, ViewChild  } from '@angular/core';
import { APITenant } from 'src/app/tenant-management/tenent-setup/tenant-setup.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ButtonValue } from 'src/app/ag-common/models/ag-models';
import { Localization } from '../../localization/Localization';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-update-tenant',
  templateUrl: './update-tenant.component.html',
  styleUrls: ['./update-tenant.component.scss']
})
export class UpdateTenantComponent implements OnInit {

  currentTenantId: any;
  tenantListOriginal: APITenant[];
  tenantList: APITenant[];
  updateTenantForm: UntypedFormGroup;
  captions: any;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  floatLabel: string;
  inputSearch: string;
  initialTenantList = [];
  @ViewChild('myInput') myInput: ElementRef;


  constructor(private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private localization: Localization,
    private dialogRef: MatDialogRef<UpdateTenantComponent>) {
      this.floatLabel = this.localization.setFloatLabel;
     }

  ngOnInit() {
    this.captions = this.localization.captions;
    this.currentTenantId = (this.dialogData) ? this.dialogData.currentTenantId : '';
    this.initTenants();
    this.initForm();
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }


  initTenants() {
    this.tenantListOriginal = (this.dialogData) ? this.dialogData.tenantListOriginal: [];
    this.tenantList = cloneDeep(this.tenantListOriginal);
    this.tenantList = this.tenantList.filter(x => x.id !== this.currentTenantId);
    this.tenantList.sort((a, b) => a.tenantName.localeCompare(b.tenantName))
    this.initialTenantList = this.tenantList;
    console.log('layout tenantlist', this.tenantList);
  }

  initForm() {
    this.updateTenantForm = this.fb.group({
      tenants: ['']
    });
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_update,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.updateTenantForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe((res) => {
      this.actionButton.disabledproperty = !(this.updateTenantForm.dirty && this.updateTenantForm.valid);
    })

  }


  onAction(e) {
    console.log("Updated Tenant", this.updateTenantForm.value);
    this.dialogRef.close(this.updateTenantForm.value.tenants);
  }

  onCancel(e) {
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  tenantChange(e) {
    /* Tenant Change Logic Goes Here */
  }


  private _filter(value: string) {
    if(value){
      const filterValue = value.toLowerCase();
      return this.tenantList = this.initialTenantList.filter(x => (x.tenantName.toLowerCase().includes(filterValue)) || (x.id.toString().toLowerCase().includes(filterValue)))
    } else {
      return this.tenantList = this.initialTenantList;
    }

  }

  filterOptions(event) {
    this._filter(event.target.value);
  }

  openedChange(opened: boolean) {
    this.myInput.nativeElement.focus()
      if (!opened) {
      this.inputSearch = ''
      this._filter("");

    }
  }

}
