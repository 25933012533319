import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './../login/login.component';
import { MaterialModule } from '../material-module';
import { ReactiveFormsModule } from '@angular/forms';
import { SetPasswordComponent } from '../login/set-password/set-password.component';
import { LoginManagementCommunication } from '../shared/Communication/Common/LoginManagement.Service';
import { SharedModule } from './../shared/shared.module';
import { PopoverModule } from 'ngx-smart-popover';
import { UpdateTenantComponent } from './layout/update-tenant/update-tenant.component';
import { AgCommonModule } from '../ag-common/ag-common.module';

@NgModule({
    declarations: [LayoutComponent, LoginComponent, SetPasswordComponent, UpdateTenantComponent],
    imports: [
        CommonModule,
        CoreRoutingModule,
        MaterialModule,
        ReactiveFormsModule,
        PopoverModule,
        SharedModule,
        AgCommonModule
    ],
    exports: [],
    providers: [LoginManagementCommunication]
})
export class CoreModule { }
