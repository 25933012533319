import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TableSearchHeader, ButtonValue, AgToggleConfig, AgDateConfig, SplitMoreAction } from '../../models/ag-models';
import { formTypes } from 'src/app/shared/enums/shared-enums';


@Component({
  selector: 'app-table-search-header',
  templateUrl: './table-search-header.component.html',
  styleUrls: ['./table-search-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableSearchHeaderComponent {
  actionButton: ButtonValue;
  searchForm: UntypedFormGroup;
  headerOption: TableSearchHeader;
  switchInput: AgToggleConfig;
  simplesearchInput: string;
  fromModule:boolean = false;
  @Input() noResizeSimplesearch: boolean;
  @Input() menuActions: SplitMoreAction[];
  @Output() searchChange = new EventEmitter();
  @Output() showAdvancedSearchChange = new EventEmitter();
  @Output() showInactive = new EventEmitter();
  @Output() createType = new EventEmitter();
  @Output() advanceSearchAccountEmit = new EventEmitter();
  @Output() advanceSearchContactEmit = new EventEmitter();
  @Input() isEditMode: boolean;
  @Input() showAdvancedSearch: boolean;
  @Input() showLinks:boolean;
  @Input() showSearchDates:boolean;
  @Input() searchStartDateInput:AgDateConfig;
  @Input() searchEndDateInput:AgDateConfig;
  @Input() showsearchDatesButton:boolean;
  @Input() searchDatesButton: ButtonValue;
  @Output() searchDateChange = new EventEmitter();
  @Output() simpleDateSearch = new EventEmitter();
  
  @Output() linksClickEmit = new EventEmitter();
  fromType : boolean = false;
  toType : boolean = false;
  @Input('isClearSearchText')
  set clearSearchTextField(value: boolean){
    if(value){
      this.clearSearchText();
    }
  }

  @Input('fromModule')
  set fromModuleValue(value: boolean){
    this.fromModule = value;
  }

  @Input('fromAccountContactModule')
  set fromAccountContactModuleValue(value){
    if(value == formTypes.fromType){
      this.fromType = true;
    }
    else{
      this.toType = true;
    }
  }


  @Input('searchHeaderOption')
  set setBtnText(value) {
    if (value) {
      this.headerOption = value;
      this.actionButton = {
        type: this.headerOption.buttonType?this.headerOption.buttonType:'primary',
        label: this.headerOption.createBtnLabel,
        disabledproperty: this.headerOption.createBtnDisabled
      };

      this.switchInput = {
        group: this.searchForm,
        formControlName: 'showInactive',
        label: this.headerOption.toggleLabel,
        disabled: this.headerOption.toggleDisabled
      };
    }
  }

  constructor(private form: UntypedFormBuilder) {
    this.searchForm = this.form.group({
      showInactive: false
    }); 
  }

  // Event Handlers
  searchValueChange($event) {
    this.searchChange.emit($event);
  }
  toggleChange(arg) {
    this.showInactive.emit(arg);
  }

  handleClick(arg) {
    this.createType.emit(arg);
  }

  toggleAdvancedSearch() {
    if(this.fromType){
      this.showAdvancedSearch = false;
      this.advanceSearchAccountEmit.emit(this.showAdvancedSearch);
    }
    else if(this.toType){
      this.showAdvancedSearch = false;
      this.advanceSearchContactEmit.emit(this.showAdvancedSearch);
    }
    else{
      this.showAdvancedSearch = false;
      this.showAdvancedSearchChange.emit(this.showAdvancedSearch);
    }
  }

  linksClick(){
    this.linksClickEmit.emit(this.showLinks)
  }

  clearSearchText(){
    this.simplesearchInput = '';
  }

  onSearchDateChange(e){
    this.searchDateChange.emit(e);
  }

  onSearchDates(e){
    this.simpleDateSearch.emit(e);
  }
 
}
