import { Component, OnInit, Input, Output, EventEmitter ,ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DropdownOptions } from 'src/app/ag-common/models/ag-models';
import { Localization } from 'src/app/core/localization/Localization';
import { MultiDropdownInputs } from '../../../ag-common/models/ag-models';
import { MatCheckbox } from '@angular/material/checkbox'; 
import { Utilities } from 'src/app/shared/directives/utilities';

@Component({
  selector: 'app-multiselect-list',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiselectListComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder: string;
  selectOptions: DropdownOptions[];
  showRequired: boolean;
  displayAll: boolean;
  disabled: boolean;
  maxSelectionLimit:number;
  selectionLimitExceededErrorMessage:string;
  captions: any;
  defaultAllSelected : boolean;
  @ViewChild('checkboxAll') 
  checkboxAll: MatCheckbox;
  @Output() selectChange = new EventEmitter();
  @Input('inputs')
  set inputOptions(value: MultiDropdownInputs) {
    this.disabled = value.disabled;
    this.className = value.className ? value.className : '';
    this.errorMessage = value.errorMessage? value.errorMessage : '';
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.selectOptions = value.selectOptions? value.selectOptions: [];
    this.showRequired = value.showRequired ? value.showRequired : false;
    this.displayAll = value.displayAll!=undefined ? value.displayAll : true;
    this.defaultAllSelected = value.defaultAllSelected? value.defaultAllSelected : false;     
    this.maxSelectionLimit = value.maxSelectionLimit? value.maxSelectionLimit : 0;     
    this.selectionLimitExceededErrorMessage = value.selectionLimitExceededErrorMessage? value.selectionLimitExceededErrorMessage : ''; 
    if(this.selectOptions.length>0 && this.defaultAllSelected){
      this.form.controls[this.formControlName].patchValue([...this.selectOptions]); 
    } 
    if(this.form)   
      this.form.addControl(`isAllSelected_${this.formControlName}`, new UntypedFormControl(this.defaultAllSelected));
  }
  constructor(private _localization: Localization,private _utilities: Utilities,) { }

  ngOnInit() {
    this.captions = this._localization.captions;   
  }

  equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.id === objTwo.id;
    }
  }

  filterDropDownSelected(event: any, data: DropdownOptions[], name: string) {

    if (this.maxSelectionLimit == 0 || (this.maxSelectionLimit>0 && this.form.get(name).value.length<= this.maxSelectionLimit)) {                
      this.form.get(`isAllSelected_${name}`).setValue(data.length === this.form.get(name).value.length);
      this.checkboxAll.checked=data.length === this.form.get(name).value.length;     
    }
    else if (this.maxSelectionLimit > 0 || this.form.get(name).value.length > this.maxSelectionLimit) { 
      let slicedList=this.form.get(name).value.slice(0, this.maxSelectionLimit);
      this.form.controls[name].patchValue([...slicedList]); 
    }
  }

  onSelectionChange(e) {
    this.selectChange.emit(e.value);
  }

  toggleAllSelection(event, controlName, fullList) {
    if (event.checked) {     
      if(this.maxSelectionLimit==0 )
      { 
        this.form.controls[controlName].patchValue([...fullList]);
        this.form.get(`isAllSelected_${controlName}`).setValue(true);
          this.checkboxAll.checked = false;    
          this.selectChange.emit(fullList);
      }
      else if (this.maxSelectionLimit > 0 )
      { 
        if ( fullList.length <= this.maxSelectionLimit) {
          this.form.controls[controlName].patchValue([...fullList]);
          this.form.get(`isAllSelected_${controlName}`).setValue(true);
            this.checkboxAll.checked = true;    
            this.selectChange.emit(fullList);
        }
        else if (fullList.length > this.maxSelectionLimit) {  
          this.checkboxAll.checked=false;
        }
      }
    } else {
      this.form.controls[controlName].patchValue([]);
      this.form.get(`isAllSelected_${controlName}`).setValue(false);
        this.checkboxAll.checked = false;        
        this.selectChange.emit([]);
    }
    this.form.controls[controlName].markAsDirty();
    
  }
}
