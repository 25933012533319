
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarType } from 'src/app/shared/enums/enums';
import { SnackBarInputs } from '../../models/ag-models';


@Component({
  selector: 'app-ag-snack-bar',
  templateUrl: './ag-snack-bar.component.html',
  styleUrls: ['./ag-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgSnackBarComponent implements OnInit {
  message: string;
  type: SnackBarType;
  flag:boolean = true;
  constructor(
    public snackBarRef: MatSnackBarRef<AgSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public snackBarInputs: SnackBarInputs) { }

  ngOnInit(): void {
    this.message = this.snackBarInputs.message;
    this.type = this.snackBarInputs.type;

  }

}
