import { Component, HostListener } from '@angular/core';
import { ExpireSessionService } from './login/expire-session.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(   
    private expireSession: ExpireSessionService ) {    
  }
  
  title = 'Ui-Code';
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.resetTimer();
  }
  
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.resetTimer();
  }

  resetTimer() {
    if (this.expireSession.resetOnTrigger) {
      this.expireSession.resetTimer();
    }
  }
}
