import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { TenantRoutes } from '../core/Extensions/tenant-route';
import { TenantLoginService } from './TenantLoginService.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { ADB2CAuthConfiguration } from 'src/app/shared/auth.config';

@Injectable({
providedIn: 'root'
})
export class ExpireSessionService {
  private _count: number = 0;
  private _logOffAfter: number;
  private _timeoutSeconds: number;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  public resetOnTrigger: boolean = false;
  public timeoutExpired: Subject<number> = new Subject<number>();
  tenantId:any;
  userSessionId: string = "userSession";

  constructor( private loginService: TenantLoginService,
  private router: Router
  , private oauthService: OAuthService
  , private adb2cAuthConfiguration: ADB2CAuthConfiguration) {
    this.timeoutExpired.subscribe(n => {
      /* Timeout Subscribe Function Goes Here */
    });
  }
  public startTimer(logOffAfter: any) {
    if (logOffAfter == 0) {
      logOffAfter = this._logOffAfter;
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this._timeoutSeconds = logOffAfter * 60;
    this.timer = timer(this._timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }
  public stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }
  public resetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timer = timer(this._timeoutSeconds * 1000);
    this.timerSubscription = this.timer.subscribe(n => {
      this.timerComplete(n);
    });
  }
  private timerComplete(n: number) {
    this.timeoutExpired.next(++this._count);
    this.stopTimer();
    this.logout();
  }
  logout() {
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.classList.remove('new-mat-view');
    this.stopTimer();
    this.UpdateSession();
    sessionStorage.removeItem('userSession');
    if(sessionStorage.getItem('supportUserMailId')){
      this.router.navigate(["supportlogin"]);
      } else {
      this.router.navigate(["login"]);
     }
    if(this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled)
    {
        console.log('adb2c logout');
        this.oauthService.logOut(); //ADB2C logout
    }
  }

  UpdateSession() {
    let sessionData = {
      isActive: false,
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };
    const sessionId:string = sessionStorage.getItem(this.userSessionId);
    if (!sessionId) {
      return;
    }
    let serviceParams = {
      route: TenantRoutes.UpdateSession,
      uriParams: { sessionId: sessionId },
      header: '',
      body: sessionData,
      showError: false,
      baseResponse: true
    };
    this.loginService.makePutCall(serviceParams);
  }

  ngOnDestroy() {
    this.stopTimer();
  }

}

