import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ExpireSessionService } from 'src/app/login/expire-session.service';
import { RouteLoaderService } from '../../shared/Communication/Common/route-loader.service';
import { menuPosition } from '../../shared/global.constant';
import { MatDialog } from '@angular/material/dialog';
import { UpdateTenantComponent } from './update-tenant/update-tenant.component';
import { APITenant } from 'src/app/tenant-management/tenent-setup/tenant-setup.model';
import { TenantSetupDataService } from 'src/app/tenant-management/tenent-setup/tenant-setup.data.service';
import { NotificationModel, SignalRMessage, SignalRNotificationType } from 'src/app/shared/data-services/signalR.model';
import { SignalrService } from 'src/app/shared/data-services/signalr.service';
import { Utilities } from 'src/app/shared/directives/utilities';
import { EventConstants } from 'src/app/shared/enums/shared-enums';
import { SnackBarType } from 'src/app/shared/enums/enums';
import { Localization } from 'src/app/core/localization/Localization';
import {CommonPropertyInformation}   from 'src/app/shared/data-services/common-property-information.service'


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RouteLoaderService, TenantSetupDataService]
})
export class LayoutComponent implements OnInit {
  menuList: any;
  currentTenantId: any;
  tenantList: APITenant[];
  tenantName: string;
  tenantId: number;
  isAdminUser = true;
  propertyDateTime;
  logOutClicked: boolean = false;



  constructor(private _expireSessionService: ExpireSessionService,
    public dialog: MatDialog,
    private routeDataService: RouteLoaderService,
    private _tenantSetupDataService: TenantSetupDataService,
    private signalR: SignalrService,
    private utilities:Utilities,
    private localization: Localization,
    private propertyInfo: CommonPropertyInformation
  ) { }

  ngOnInit() {
    this.currentTenantId = 0;
    this.isAdminUser = sessionStorage.getItem('loggedUser') == "agilysys" ? true:false;
    this.currentTenantId = parseInt(sessionStorage.getItem('tenantId'));
    this.initTenants();
    this.routeDataService.loadSettings().then(result => {
      const value = this.routeDataService.GetChildMenu('/');
      const propertyInfo = sessionStorage.propertyInfo
      const propertyIdRegex = /PropertyId=(\d+);/;
      const match = propertyInfo.match(propertyIdRegex);
      const currentPropertyId = match ? match[1] : null;
      if(!this.isAdminUser){
        value[4].linkedElement[1].visibility = false;
      }
      this.menuList = {
        menu: value,
        menuType: menuPosition.initial,
      };
    });
    this.StartSignalrConnection();
    this.toggleStyle();
    this.time();
  }

  async initTenants() {
    this.tenantList = await this.getTenants();
    this.setTenant();
  }

  setTenant() {
    let tenant = this.tenantList.find(x => x.id === this.currentTenantId);
    this.tenantName = tenant ? tenant.tenantName : '';
    this.tenantId =  tenant.id;

    console.log('layout tenantlist', this.tenantList);
  }

  public async getTenants(): Promise<APITenant[]> {
    try {
      return await this._tenantSetupDataService.getTenants();
    } catch(e) {
        console.error(e);
        throw e;
    }
  }

  async changeTenant(e) {
    this.tenantList = await this.getTenants();
    this.setTenant();
    this.dialog.open(UpdateTenantComponent, {
      width: '400px',
      height: '250px',
      disableClose: true,
      data: {
        tenantListOriginal: this.tenantList,
        currentTenantId: this.currentTenantId
      }
    }).afterClosed().subscribe((res) => {
      if (res) {
        console.log("Update Session with New Tenant", res);
        var id = sessionStorage.getItem('tenantId')
        sessionStorage.setItem('tenantId', (res.id).toString());
       var propInfo = sessionStorage.getItem('propertyInfo');
       var updatedPropInfo = propInfo.replace('TenantId='+id.toString(),'TenantId='+(res.id).toString())
       sessionStorage.setItem('propertyInfo',updatedPropInfo);
       sessionStorage.setItem('platformTenantId',res.platformTenantId);
        window.location.reload();
      }
    });
  }

  logout() {
    this.logOutClicked = true;
    this._expireSessionService.logout();
    sessionStorage.removeItem('_authorizedUser');
    this.signalR.UnSubscribeToEvent(EventConstants.TenantEvents);
  }
  private StartSignalrConnection() {
    this.signalR.startConnection();
    this.signalR.startedConnection.then(res => {
      this.addPropertyListener();
    });
  }
  private addPropertyListener() {
    this.signalR.addListener(this, EventConstants.TenantEvents,this.signalRPropertyListener)
      .catch((err) => console.log('Failure error ' + err));

    this.signalR.hubConnection.onreconnected((reconnect)=>{
      const list=this.signalR.GetSignalREvents();
      list.forEach(e=>{this.signalR.subscribeToEvent(e);});
      });

  }
  async signalRPropertyListener(message: SignalRMessage<NotificationModel>): Promise<void> {
    if(message && message.content && message.content.notificationType==SignalRNotificationType.ToasterNotification)
    {
      const content =JSON.parse(message.content.notificationObjectString);

        this.utilities.showToastMessage(content.message, SnackBarType.Success);
      }

  }

toggleStyle() {
    this.localization.isNewStyle = true;
    this.setView();
  }

  setView() {
    const bodyTag = document.getElementsByTagName('body')[0];
    if (this.localization.isNewStyle) {
      bodyTag.classList.add('new-mat-view');
      this.localization.setFloatLabel = 'always';
    } else {
      bodyTag.classList.remove('new-mat-view');
      this.localization.setFloatLabel = 'never';
    }
  }

  time() {
    this.propertyDateTime = this.localization.getLocalizedCurrentDateTime();
  }


  ngAfterViewInit() {
    if (!this.logOutClicked) {
      setInterval(() => this.time(), 500);
    }
  }


}
