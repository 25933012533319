
export enum CodeSetups {
    CurrencyCode
}

export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7,
    Confirmation = 8,
    CustomDefault = 9
}

export enum AlertResult {
    YES = "YES",
    NO = "NO",
    CANCEL = "CANCEL",
    CONTINUE = 'CONTINUE'
}

export enum ActionMode {
    create,
    update,
    cancel,
    delete,
    add,
    view,
    copy
}

export enum ContactType {
    email = 'Email',
    phone = 'Phone'
}

export enum GuestProfileMailTypes {
    personal = 9,
    office = 10,
}

export enum PhoneTypes {
    mobile = 1,
    home = 2,
    office = 3,
    fax = 4
}

export enum MailTypes {
    personal = 9,
    office = 10,
}

export enum ContactReferenceType {
    MANAGER = 1,
    CONTACT = 2,
    ACCOUNT = 3,
    INQUIRY = 4
}
export enum ContactInformationReferenceType {
    CONTACT = 1,
    ACCOUNT
}
export enum ContactAddressReferenceType {
    CONTACT = 1,
    ACCOUNT
}

export enum DisplayLine {
    all = 0,
    holiday = 1,
    hotDates = 2
}

export enum ManagerType {
    Sales = 1,
    ConferenceService,
    Catering
}
export enum InquiryDeniedReportType {
    Inquiry = 1,
    Denied = 2,
    Both = 3,
    BookingCreated = 4
}

export enum SalesCallFilterType {
    Account = 1,
    Manager = 2,
    CallType = 3,
    NextStep = 4,
    CallDate = 5
}

export enum AssociateType {
    Building = 1,
    SalesManager,
    ConferenceManager,
    CateringManager

}

export enum DialogViewOnly {
    print = 'print',
    cancel = 'cancel'
}

export enum SorTypeEnum {
    asc = 'asc',
    desc = 'desc'
}

export enum SnackBarType {
    Info = 'info',
    Error = 'error',
    Success = 'success',
    Warning = 'warn'
}


export enum PaymentMethods {
    Cash = 1,
    CreditCard = 2,
    RoomCharge = 3,
    PendingSettlement = 4,
    IDTECH = 5,
    RainCheck = 6,
    CardOnFile = 22,
    ARPost = 7,
    RedeemPoint = 8,
    CreditBook = 9,
    CompRedemption = 10,
    OfferRedemption = 11,
    V1GiftCard = 12,
    ExternalGiftCard = 13,
    V1GiftCardIdTech = 14,
    ExternalGiftCardIdTech = 15,
    ARAcctPost = 16,
    Other = 44
}

export enum DefaultOutletType {
    user = 1,
    course = 2
}

export enum SystemPasscodeTag {
    OverSell = 'OVERSELLRMTYPE',
    OverRide = 'OVERRIDEDONOTMOVE'
}

export enum PolicyType {
    DataRetentionPolicy = 1,
    ConsentPolicy = 2
}

export enum PolicyAction {
    Create = 1,
    Update = 2
}

export enum PolicyMenuType {
    Guest = 1,
    Contact = 2,
    Company = 3,
    WholeSaler = 4
}

export enum PolicyProducts {
    SPA = 1,
    Retail = 2,
    Golf = 3,
    Snc = 5,
    PMS = 6,
    Accounting = 10
}

export enum ReasonAction {
    Create = 1,
    Update = 2
}

export enum PolicyCategoryType {
    Guest = 1,
    Contact,
    GolfPlayer,
    ARAccount,
    Member,
    Condo
}

export enum StatusCodes {
    ClientClosed = 499,
    Success = 200
  }