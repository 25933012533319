export const JWT_TOKEN = '_jwt';
export const USER_SESSION = 'userSession';
export const REMEMBER_INFO = '_rememberInfo';
export const USER_INFO = '_userInfo';
export const USER_SETTINGS = 'userSettings';
export const PROPERTY_DATE = 'propertyDate';
export const PROPERTY_INFO = 'propertyInfo';
export const USE_RETAIL_INTERFACE = 'useRetailInterface';
export const VAT_ENABLED = 'VATEnabled';
export const PAYMENT_CONFIGURATION = 'paymentConfiguration';
export const USERS_SESSSIONS_INFO = 'usersSessionsInfo';
export const PROPERTY_CONFIGURATION_SETTINGS = 'PROPERTYCONFIGURATION';
export const FULL_STORY_ORG_ID = 'FullStoryOrgId';
export const UI_DEFAULTS_SETTINGS_VALUE ='UiDefaultsSettingsValue';
export const TENANT_PRODUCT_ID = 7;
export const SUPPORT_TENANT = 1001;
export const SUPPORT_USERNAME = 'AGYSSUPPORT';


