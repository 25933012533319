import { Compiler, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { TenantRoutes } from '../core/Extensions/tenant-route';
import { Localization } from '../core/localization/Localization';
import { TenantManagementCommunication } from '../shared/Communication/Common/TenantManagement.Service';
import { Utilities } from '../shared/directives/utilities';
import { BaseResponse, ServiceParams } from '../shared/models/http.model';
import { ExpireSessionService } from './expire-session.service';
import { LoginBusiness } from './login.business';
import { LoginDataService } from './login.data.service';
import { Login } from './login.model';
import { SetPasswordComponent } from './set-password/set-password.component';
import { TenantLoginService } from './TenantLoginService.service';
import { OAuthService, NullValidationHandler, OAuthEvent } from 'angular-oauth2-oidc';
import { AlertType, ButtonType } from 'src/app/shared/shared-models';
import { ADB2CAuthConfiguration } from '../shared/auth.config';
import { Product } from 'src/app/shared/global.constant';
import { ReplaySubject } from 'rxjs';
import { JWT_TOKEN, SUPPORT_TENANT, TENANT_PRODUCT_ID, SUPPORT_USERNAME, USER_SESSION } from '../shared/constants/app-constants';
import { CryptoUtility } from '../utilities/Crypto/crypto.utility';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [TenantManagementCommunication, LoginBusiness, LoginDataService, TenantLoginService]
})
export class LoginComponent implements OnInit {
  skipLoginFormLoad = false;
  hidePassword = false;
  loginError = false;
  currYear = 2024;
  preYear = 2019;
  userName: string;
  useridSubscribe: any;
  productId = 7;
  tenantId: number;
  userInfo: any;
  userProperties: any;
  propertyKey = 'propertyInfo';
  userKey = '_userInfo';
  propertyDateKey = 'propertyDate';
  private _autoLogOff: any = false;
  private _logOffAfter = 1;
  propcode: any;
  defaultProperty: any;
  propertyValues: any[];
  loginDetails: BaseResponse<Login>;
  tenantCode: string;
  useridText: string;
  form: UntypedFormGroup;
  error: string;
  bSuccess: boolean;
  data: Login;
  showCustomerID = false;
  captions: any;
  errorMessage: { userId: string; password: string; customerId: string; };
  floatLabel: string;
  hideLoginForm = false;
  ADB2CAuthenticationEnabled: boolean = false;
  loginSuccess = false;
  custId: any;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  uTempDataPrimary: string;
  uTempDataSecondary: string;
  iv: string;
  isSupportUser: boolean = false;
  allTenantDetails: any[] = [];
  tenantIdList: any[] = [];
  propertyIdListForATenant: any[] = [];
  allPropertyDetails: any[] = [];
  userDetail: any;
  showLoginloader: boolean = false;
  private intervalId: any; // Type 'any' can be replaced with 'number'
  private elapsedTime: number = 0;

  constructor(private router: Router, private _LoginBusiness: LoginBusiness, public dialog: MatDialog,
    private route: ActivatedRoute, private _compiler: Compiler, private localize: Localization,
    private sessionService: ExpireSessionService, private _LoginDataService: TenantLoginService,
    private utils: Utilities, private formBuilder: UntypedFormBuilder,
    private oauthService: OAuthService,
    private crypto: CryptoUtility,
    private adb2cAuthConfiguration: ADB2CAuthConfiguration) {
    this.captions = this.localize.captions;
    this.floatLabel = this.localize.setFloatLabel;
  }

  async ngOnInit() {
    document.querySelectorAll('body')[0].setAttribute('id', "bodyId");
    this.enableLoginloader(false);
    await this.initializeForm();
    if (!this.ADB2CAuthenticationEnabled) {
      let custId = this.localize.getLocalCookie('appTenantCustID');
      if (custId != '') {
        this.form.controls['customerId'].setValue(custId);
        this.form?.controls["customerId"].disable();
        this.getbuttonEmitvalue('');
      }
    }
    if (this.router.url && this.router.url === '/supportlogin') {
      this.isSupportUser = true;
      this.form.controls['customerId'].setValue(SUPPORT_TENANT);
      this.form?.controls["customerId"].disable();
      this.getbuttonEmitvalue('');
    }

  }

  setVal() {
    this.form.controls.customerId.setValidators([Validators.required]);
    this.form.controls.customerId.updateValueAndValidity();
    this.form.controls.customerId.markAsDirty();
  }
  removeVal() {
    this.form.controls.customerId.clearValidators();
    this.form.controls.customerId.updateValueAndValidity();
    this.form.controls.customerId.markAsDirty();
  }

  setGeneralLoginVal() {
    const userIdCtrl = this.form.get('userName');
    userIdCtrl.setValidators([Validators.required]);
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.form.get('password');
    passwordCtrl.setValidators([Validators.required]);
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  removeGeneralLoginVal() {
    const userIdCtrl = this.form.get('userName');
    userIdCtrl.clearValidators();
    userIdCtrl.updateValueAndValidity();
    userIdCtrl.markAsDirty();

    const passwordCtrl = this.form.get('password');
    passwordCtrl.clearValidators();
    passwordCtrl.updateValueAndValidity();
    passwordCtrl.markAsDirty();
  }

  async getCustomerId() {
    this.hideLoginForm = true;
    const serviceParams = {
      route: TenantRoutes.EnvironmentConfig,
      uriParams: '',
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
    await this._LoginDataService.makeGetCall(serviceParams).then((res: any) => {
      this.custId = res ? res.result : 0;
      if (this.custId == '0' || this.ADB2CAuthenticationEnabled) {
        this.showCustomerID = true;
        this.setVal();
      } else {
        this.enableLoginloader(false);
        this.showCustomerID = false;
        this.form.controls['customerId'].setValue(this.custId);
        this.form?.controls["customerId"].disable();
        this.removeVal();
      }
    });
    this.setValues();
    let tenantId = localStorage.getItem('TenantId');
    let adb2cEnabled = localStorage.getItem('ADB2CAuthenticationEnabled');
    if (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "true") {
      await this.configureAuth(tenantId);
    }
    //To load form for general authentication
    if (adb2cEnabled == null || (adb2cEnabled != null && adb2cEnabled.toLowerCase() == "false")) {
      this.hideLoginForm = false;
    }
  }

  setValues() {
    this.uTempDataPrimary = this._LoginDataService.GetUTempData(3);
    this.uTempDataSecondary = this._LoginDataService.GetUTempData(1);
  }
  private async initializeForm() {
    this.tenantId = 0;
    this.errorGenerator();
    this.skipLoginFormLoad = false;
    this.form = this.formBuilder.group({
      userName: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      customerId: new UntypedFormControl(''),
      tenantId: [''],
      propertyId: ['']
    });
    sessionStorage.setItem('productId', '7');
    await this.getCustomerId();
  }

  private async configureAuth(tenantId: string) {
    await this.GetADB2CAuthConfig(tenantId);
    this.ADB2CAuthenticationEnabled = this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled;
    if (this.ADB2CAuthenticationEnabled) {
      this.hideLoginForm = true;
      this.oauthService.configure(this.adb2cAuthConfiguration.authConfig);
      this.oauthService.customQueryParams = {
        'customerId': tenantId,
        'productId': Product.Tenant
      };
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.setStorage(localStorage);
      //Event Subscription
      this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
        switch (type) {
          case 'token_received':
            console.log([this.oauthService.state]);
            break;
          default:
            console.log([this.oauthService.state]);
            break;
        }
      });

      this.oauthService.loadDiscoveryDocument(this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl)
        .then(async doc => {
          console.log(doc);
          this.oauthService.tryLogin().then(async res => {
            if (this.oauthService.hasValidIdToken()) {
              this.hideLoginForm = true;
              await this.adb2cAuthLogin();
              //this.setUserSessionInfo(this.claims,this.oauthService.getIdToken());
            }
            else {
              this.hideLoginForm = false;
            }
            let _token = this.oauthService.getIdToken();
            //let _token = sessionStorage.getItem(JWT_TOKEN);
            console.log(_token);
          });
        });
    }
    else {
      this.hideLoginForm = false;
    }
  }

  public adb2cLogin() {
    this.oauthService.initCodeFlow();
  }

  public adb2cLogout() {
    this.oauthService.logOut();
  }

  public get adb2cClaims() {
    let claims = this.oauthService.getIdentityClaims();
    return claims;
  }

  async validateAdb2cCredentials(credentials, claims: any, strTenantId: string) {
    const serviceParams = {
      route: TenantRoutes.ADB2CLogin,
      uriParams: '',
      header: '',
      body: credentials,
      showError: true,
      baseResponse: true
    };
    if (!this.loginSuccess) {//  && !this.enableLocation
      // Validate credentials
      let token = this.oauthService.getIdToken();
      const tenantId = Number(strTenantId);
      const loginDetails = await this._LoginDataService.makePostCall(serviceParams);
      if (loginDetails.successStatus) {
        sessionStorage.setItem(JWT_TOKEN, token)
        loginDetails.result.token = token;
        this.userName = loginDetails.result.userLoginInfo.userName;
        const loginResponse: any = loginDetails;
        const jwtToken = JSON.parse(atob(token.split('.')[1]));
        const jwtExpiryTime = new Date(jwtToken.exp * 1000);
        const timeout = jwtExpiryTime.getTime() - Date.now();
        const loginDuration = Math.round(timeout / 1000);
        if (loginResponse.result.loginDuration) {
          loginResponse.result.loginDuration = loginDuration;
          sessionStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          localStorage.setItem('loginDuration', loginResponse.result.loginDuration);
          const tokenDuration = parseInt(sessionStorage.getItem('loginDuration'));
          //this.sessionService.startTimer(tokenDuration);

          sessionStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
          localStorage.setItem('jwtExpiryTime', jwtExpiryTime.toString());
        }
        this.loginDetails = loginDetails;
        this.tenantId = loginDetails.result.userLoginInfo.tenantId;
        sessionStorage.setItem('tenantId', loginDetails.result.userLoginInfo.tenantId);
        if (this.tenantId == 1001) {
          sessionStorage.setItem('loggedUser', 'agilysys');
        }
        else {
          sessionStorage.setItem('loggedUser', '');
        }
        await this.successCallBack(loginDetails.result);

      } else {
        if (loginDetails.errorCode == 5001) {
          this.enableLoginloader(false);
          this.loginError = true;
          this.hideLoginForm = false;
        }
        this.utils.showAlert(loginDetails.errorDescription, AlertType.Error, null, async (res) => {
          this.adb2cLogout();
        });
      }
    }
  }

  getADB2CEmailClaim(claims) {
    let email = "";

    if (claims != null && claims != undefined) {
      if (claims['emails'] != null && claims['emails'].length > 0) {
        email = claims['emails'][0];
      }
      else if (claims['email'] != null) {
        email = claims['email'];
      }
    }

    return email;
  }

  async adb2cAuthLogin() {
    let tenantId = localStorage.getItem('TenantId');
    this.form.controls.customerId.setValue(tenantId);
    let claims = this.adb2cClaims;
    const credentials = {
      email: this.getADB2CEmailClaim(claims),
      tenantId: tenantId,
      ProductId: Product.Tenant
    };
    if (!credentials.email) {
      this.utils.showAlert(this.captions.lbl_AzureTokenErrorMessage, AlertType.Error, ButtonType.Ok, (res => {
        this.adb2cLogout();
      }));
      return false;
    }
    if (Number(tenantId) == SUPPORT_TENANT) {
      this.localize.SetSupportUserMailId(credentials.email);
      this.enableLoginloader(false);
    }

    await this.validateAdb2cCredentials(credentials, claims, tenantId);
  }

  async GetADB2CAuthConfig(tenantId: string) {
    const iTenantId: number = Number(tenantId);
    const serviceParams = {
      route: TenantRoutes.GetADB2CAuthConfig,
      uriParams: { "tenantId": iTenantId, "productId": Product.Tenant },
      header: '',
      body: '',
      showError: true,
      baseResponse: true
    };
    let adb2cConfig: any;
    adb2cConfig = await this._LoginDataService.makeGetCall(serviceParams);
    this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled = adb2cConfig.result.adB2CAuthenticationEnabled;
    this.adb2cAuthConfiguration.DiscoveryDocumentConfigUrl = adb2cConfig.result.discoveryDocumentUrl;
    let adb2cUrl = this.localize.getLocalCookie('supportUserMailId') || sessionStorage.getItem('supportUserMailId') || this.isSupportUser ? '/Admin/supportlogin' : '/Admin/login';
    this.adb2cAuthConfiguration.authConfig = {
      redirectUri: window.location.origin + adb2cUrl,
      postLogoutRedirectUri: window.location.origin + adb2cUrl,
      responseType: 'code',
      issuer: adb2cConfig.result.issuer,
      strictDiscoveryDocumentValidation: false,
      tokenEndpoint: adb2cConfig.result.tokenEndPoint,
      loginUrl: adb2cConfig.result.loginUrl,
      clientId: adb2cConfig.result.clientId,
      scope: 'openid',
      skipIssuerCheck: true,
      clearHashAfterLogin: true,
      oidc: true
    };
  }

  async getbuttonEmitvalue(e): Promise<void> {
    if (!this.showLoginloader) {
      window.onbeforeunload = null;
      if (e) {
        this.enableLoginloader(true);
        e.preventDefault();
        this.form.markAsUntouched();
      }
      if (this.showCustomerID) {
        this.localize.setLocalCookie('appTenantCustID', this.form.get('customerId').value);
        localStorage.setItem('TenantId', this.form.get('customerId').value);
        let tenantId = localStorage.getItem('TenantId');
        // await this.configureAuth(tenantId);
        // localStorage.setItem('ADB2CAuthenticationEnabled', this.ADB2CAuthenticationEnabled.toString());
        this.form.get('customerId').markAsTouched();
        this.form?.controls["customerId"].disable();
        this.removeVal();
        // if (this.ADB2CAuthenticationEnabled) {
        //   this.removeGeneralLoginVal();
        //   await this.adb2cAuthValidation();
        // }
        // else {
          this.enableLoginloader(false);
          this.showCustomerID = false;
        // }
      }
      // else if ((this.ADB2CAuthenticationEnabled) || (this.ADB2CAuthenticationEnabled && this.isSupportUser)) {
      //   this.removeGeneralLoginVal();
      //   await this.adb2cAuthValidation();
      // }
      // else {
        this.form.controls['userName'].markAsTouched();
        this.form.controls['password'].markAsTouched();
        this.setGeneralLoginVal();
        this.generalAuthValidation();
      // }
    }
  }

  async loginwithSSO(e): Promise<void> {
    if (!this.showLoginloader) {
      window.onbeforeunload = null;
      if (e) {
        this.enableLoginloader(true);
        e.preventDefault();
        this.form.markAsUntouched();
      }
      if (this.showCustomerID) {
        this.localize.setLocalCookie('appTenantCustID', this.form.get('customerId').value);
        localStorage.setItem('TenantId', this.form.get('customerId').value);
        let tenantId = localStorage.getItem('TenantId');
        await this.configureAuth(tenantId);
        localStorage.setItem('ADB2CAuthenticationEnabled', this.ADB2CAuthenticationEnabled.toString());
        this.form.get('customerId').markAsTouched();
        this.form?.controls["customerId"].disable();
        this.removeVal();
        if (this.ADB2CAuthenticationEnabled) {
          this.removeGeneralLoginVal();
          await this.adb2cAuthValidation();
        }
        else {
          this.enableLoginloader(false);
          this.showCustomerID = false;
          this.utils.showAlert(this.captions.lbl_ssoAlert, AlertType.Info);
        }
      }
      else if ((this.ADB2CAuthenticationEnabled) || (this.ADB2CAuthenticationEnabled && this.isSupportUser)) {
        this.removeGeneralLoginVal();
        await this.adb2cAuthValidation();
      }
      // else {
      //   this.form.controls['userName'].markAsTouched();
      //   this.form.controls['password'].markAsTouched();
      //   this.setGeneralLoginVal();
      //   this.generalAuthValidation();
      // }
    }
  }

  async adb2cAuthValidation() {
    if (this.form.valid) {
      if (!this.loginSuccess) {//  && !this.enableLocation
        localStorage.setItem('TenantId', this.form.get('customerId').value);
        this.adb2cLogin();
      }
      else {
        this.adb2cAuthLogin();
      }
    }
  }

  async generalAuthValidation() {
    this.tenantCode = '';
    this.error = '';

    if (this.form.valid) {
      console.log(this.custId);
      this.custId = this.route.snapshot.queryParamMap.get('TenantId') != null ?
        this.route.snapshot.queryParamMap.get('TenantId') : this.custId;
      console.log(this.custId);
      const id: any = this.form.controls.customerId.value != '' ? this.form.controls.customerId.value : this.custId;
      this.tenantId = parseInt(id);
      const uname: string = this.form.controls.userName.value;
      let muname: string;
      if (uname.includes('@')) {
        muname = uname.substring(0, uname.indexOf('@'));
        this.tenantCode = uname.substring(uname.indexOf('@') + 1);

      } else {
        muname = uname;
      }
      const pass: string = this.form.controls.password.value;
      this.data = this._LoginBusiness.mapToAPI(muname, pass, this.tenantId, this.productId, this.tenantCode);
      sessionStorage.setItem('productId', '7');
      let serviceParams: ServiceParams = {
        route: TenantRoutes.ValidateLogin,
        uriParams: '',
        header: '',
        body: this.data,
        showError: true,
        baseResponse: true
      };
      if (this.uTempDataPrimary && this.uTempDataSecondary) {
        serviceParams.body.password = this.crypto.EncryptString(this.data.password, this.uTempDataPrimary, this.uTempDataSecondary);
        serviceParams.route = TenantRoutes.LoginEncrypted;
      }
      this.loginDetails = await this._LoginBusiness.validateLogin(serviceParams);
      let userDetails: any = this.loginDetails.result;

      if (this.loginDetails.successStatus) {
        this.tenantId = userDetails.userLoginInfo.tenantId == 0 ? this.tenantId : userDetails.userLoginInfo.tenantId;
        sessionStorage.setItem('_jwt', userDetails.token);
        sessionStorage.setItem('tenantId', this.tenantId.toString());
        if (userDetails.userLoginInfo.tenantId == 1001) {
          sessionStorage.setItem('loggedUser', 'agilysys');
        }
        else {
          sessionStorage.setItem('loggedUser', '');
        }
        this.successCallBack(this.loginDetails.result);
      }
      else {
        this.loginError = true;
        if (this.loginDetails.errorCode == 5001) {
          this.enableLoginloader(false);
          this.error = 'Invalid Credentials';

        } else if (this.loginDetails.errorCode == 5002) {
          this.error = 'User Account Locked';
        } else if (this.loginDetails.errorCode == 5003) {
          this.error = 'Inactive User! Please check with System Admin.';
        } else if (this.loginDetails.errorCode == 5004) {
          this.error = 'User does not have access! Invalid Tenant ID! ';
        } else if (this.loginDetails.errorCode == 5009) {
          this.error = 'User does not have access! ';
        } else {
          this.error = 'Unexpected error';
        }
      }
      sessionStorage.setItem('_authorizedUser', this.loginDetails.successStatus ? this.loginDetails.successStatus.toString() : null);





    }
  }

  setUpPassword(e: any, isSetPassword: boolean) {
    const componentDetails = {
      componentName: SetPasswordComponent,
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.dialog.open(SetPasswordComponent, {
      width: '55%',
      height: 'auto',
      disableClose: true,
      data: { title: e.title, componentDetails, setPassword: isSetPassword, userName: e.userName, tenantId: e.tenantId, firstName: e.firstName, lastName: e.lastName, passwordSetting: e.passwordSetting, uTempData: { uTempPri: this.uTempDataPrimary, uTempSec: this.uTempDataSecondary } }
    }).afterClosed().subscribe(res => {
      this.enableLoginloader(false);
    });
    this.form.controls.password.setValue('');
    this.error = '';
  }
  successCallBack(loginDetails) {
    if (!this.ADB2CAuthenticationEnabled) {
      this.userName = loginDetails.userLoginInfo.userName;
      this.userInfo = loginDetails.userLoginInfo;

      if (loginDetails.userLoginInfo.isNewUser === true) {
        const content = { title: 'SETUP PASSWORD', userName: this.userName, tenantId: this.tenantId, firstName: loginDetails.userLoginInfo.firstName, lastName: loginDetails.userLoginInfo.lastName, passwordSetting: loginDetails.passwordSetting };
        this.setUpPassword(content, true);
      } else if (loginDetails.userLoginInfo.isPasswordExpired === true) {
        const content = { title: 'CHANGE PASSWORD', userName: this.userName, tenantId: this.tenantId, firstName: loginDetails.userLoginInfo.firstName, lastName: loginDetails.userLoginInfo.lastName, passwordSetting: loginDetails.passwordSetting };
        this.setUpPassword(content, false);
      }
    } else {
      this.enableLoginloader(false);
      this.userInfo = loginDetails.userLoginInfo;
      sessionStorage.setItem('_authorizedUser', this.loginDetails.successStatus ? this.loginDetails.successStatus.toString() : null);
    }
    this.loginSuccess = true;
    if (this.check(this.loginDetails)) {
      this.error = '';
      this.router.navigate(['/home']);
      const props: any = this.loginDetails;
      if (props.result.userProperties.length != 0) {
        this.propertyValues = props.result.userProperties;
        this.defaultProperty = this.propertyValues.find(prop => {
          return prop.autoLogOff;
        });
        if (!this.defaultProperty) {
          this.defaultProperty = this.propertyValues[0];
        }
        this.setUserInfo(this.loginDetails.result);
        this.setpropertyvalues(this.defaultProperty);
      }
    }

  }
  check(loginDetails) {
    if ((!loginDetails.result.userLoginInfo.isNewUser && !loginDetails.result.userLoginInfo.isPasswordExpired) || this.ADB2CAuthenticationEnabled) {
      return true;
    } else {
      return false;
    }
  }
  setpropertyvalues(Selectedproperty: any) {
    const result = this.propertyValues.find(item => item.propertyCode === Selectedproperty.propertyCode);
    const userLanguageCode = this.userInfo != null && this.userInfo.languageCode != '' ? this.userInfo.languageCode : result.languageCode;
    const PropertyValues = 'Language=' + result.languageCode + '; PropertyCode=' + result.propertyCode +
      '; SubPropertyCode=' + result.subPropertyCode + '; Currency=' + result.currencyCode + '; TenantId=' + result.tenantId + '; userName=' + this.userName
      + '; UserId=' + this.userInfo.userId + '; PropertyId=' + result.propertyId + '; SubPropertyId=' + result.subPropertyId + '; PlatformTenantId=' + result.platformTenantId + '; PropertyDate=' + result.propertyDate
      + '; TimeZone=' + result.timeZone + '; PropertyName=' + result.propertyName
      + '; UserLanguage=' + userLanguageCode + '; ProductId=' + result.productId + ';';
    sessionStorage.setItem(this.propertyKey, PropertyValues);
    sessionStorage.setItem(this.propertyDateKey, result.propertyDate);
    this.setUserSettings(result);
    this.CreateUserSession(result, sessionStorage.getItem('_jwt'));
    this.localize.SetLocaleBasedProperties();
  }

  CreateUserSession(sessionInfo, token: string) {
    const sessionData = {
      id: 0,
      userId: Number(sessionInfo.userId),
      startTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
      propertyId: Number(sessionInfo.propertyId),
      productId: Number(sessionInfo.productId),
      timeZone: this.utils.GetClientTimeZone(),
      token
    };
    this.CreateSession(sessionData);
  }

  async CreateSession(data) {
    const serviceParams = {
      route: TenantRoutes.CreateSession,
      uriParams: '',
      header: '',
      body: data,
      showError: true,
      baseResponse: true
    };
    const result = await this._LoginDataService.makePostCall(serviceParams);
    let response = result.result;
    sessionStorage.setItem('userSession', response);
  }

  setUserSettings(properties) {
    const userSettings = `
    AutoLogOff=${properties ? properties.autoLogOff : ''};
    LogOffAfter=${properties ? properties.logOffAfter : ''};`;
    sessionStorage.setItem('userSettings', userSettings);
    sessionStorage.setItem('AutoLogOff', properties ? properties.autoLogOff : '');
    sessionStorage.setItem('LogOffAfter', properties ? properties.logOffAfter : '');
    this.setAutoLogOff();
  }
  setAutoLogOff() {
    this._autoLogOff = sessionStorage.getItem('AutoLogOff');
    if (this._autoLogOff == 'true') {
      this.sessionService.resetOnTrigger = true;
      this._logOffAfter = +sessionStorage.getItem('LogOffAfter');
      this.sessionService.startTimer(this._logOffAfter);
    } else {
      this.sessionService.resetOnTrigger = false;
    }
  }

  setUserInfo(loginDetails) {
    this.userProperties = loginDetails.userProperties ? loginDetails.userProperties : {};
    const language = loginDetails.userLoginInfo && loginDetails.userLoginInfo.languageCode;
    const userInfo = `userId=${loginDetails.userLoginInfo.userId};
                        userName=${loginDetails.userLoginInfo.userName};
                        firstName=${loginDetails.userLoginInfo.firstName};
                        lastName=${loginDetails.userLoginInfo.lastName};
                        roleId=${this.userProperties ? this.userProperties[0].roleId : 1};
                        roleName=${this.userProperties ? this.userProperties[0].roleName : ''};
                        language=${language};
                      `;
    sessionStorage.setItem('_userInfo', userInfo);
  }

  clearError() {
    this.error = '';
  }

  ngOnDestroy() {
    this.stopInterval();
    if (this.useridSubscribe) {
      this.useridSubscribe.unsubscribe();
    }
  }

  errorGenerator() {
    this.errorMessage = {
      userId: this.captions.enterUserId,
      password: this.captions.enterPassword,
      customerId: this.captions.enterCustomerId
    };
  }

  clearLclCookie(idname) {
    this.localize.clearLocalCookie(idname);
    this.form.controls['customerId'].setValue('');
    this.form?.controls["customerId"].enable();
    this.showCustomerID = true;
    this.form.controls['customerId'].setValidators([Validators.required]);
    this.form.controls['customerId'].updateValueAndValidity();
    this.form.markAsUntouched();
  }
  enableLoginloader(val: boolean) {
    if (this.form?.valid && val) {
      this.showLoginloader = val ? val : false;
      this.startInterval();
    } else {
      this.showLoginloader = false;
    }

  }

  startInterval() {
    // Set up the interval to execute a function every 1000 milliseconds (1 second)
    this.elapsedTime = 0;
    this.intervalId = setInterval(() => {
      this.elapsedTime += 1000; // Increment elapsed time by 1 second

      // Check if 30 seconds have passed
      if (document.getElementById("bodyId")?.getElementsByClassName("errorpop-container")?.length > 0) {
        this.stopInterval(); // Clear the interval if the condition is met
      }
      if (this.elapsedTime >= 50000) {
        this.stopInterval(); // Clear the interval if 50 seconds have passed
      }
    }, 1000);
  }

  stopInterval() {
    // Clear the interval when called
    this.enableLoginloader(false);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

}
