import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}
import { ADB2CAuthConfiguration } from 'src/app/shared/auth.config';
import { OAuthModule, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    OAuthModule.forRoot()
  ],
  providers: [
    HttpClient,
    {
      provide: OAuthService,
      useClass: OAuthService
    },
    UrlHelperService,
    ADB2CAuthConfiguration,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS, 
      useValue: OtherOptions
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }