import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { SlideActionBusiness } from './slide-action.business';

@Component({
  selector: 'app-slide-action',
  templateUrl: './slide-action.component.html',
  styleUrls: ['./slide-action.component.scss'],
  providers: [SlideActionBusiness],
  encapsulation:ViewEncapsulation.None
})
export class SlideActionComponent {
  @Output() closeSlide = new EventEmitter();
  @Input() header: string;
  
  slideMenuClose() {
    this.closeSlide.emit();
  }

}
