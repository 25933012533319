import { Injectable } from '@angular/core';
import { TenantRoutes } from 'src/app/core/Extensions/tenant-route';
import { Utilities } from '../directives/utilities';
import { TenantManagementCommunication } from '../Communication/Common/TenantManagement.Service';
import { userAccessModel } from './useraccess-model.model';


@Injectable(
    { providedIn: 'root' }
)
export class UserAccessDataService {

    constructor(private _authentication: TenantManagementCommunication
        , private _utilities: Utilities) { }

    public async getUserAccess(breakPointNumber: number): Promise<userAccessModel.BreakPointResult> {
        // default value - allow view only
        let result: userAccessModel.BreakPointResult = {
            isAllow: false,
            isViewOnly: true
        };
        const userRoleId = this._utilities.GetsessionStorageValue('_userInfo', 'roleId');
        let accesses = await this._authentication.getPromise<userAccessModel.BreakPointAccess[]>({
            route: TenantRoutes.GetBreakPoint
            , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
        });
        accesses = accesses ? accesses : [];
        let access: userAccessModel.BreakPointAccess = accesses.find(x => x.breakPointNumber == breakPointNumber);
        if (access) {
            result = {
                isAllow: access.allow,
                isViewOnly: access.view
            };
        }
        return result;
    }

    public async getUserAccesses(breakPointNumber: number[]): Promise<userAccessModel.BreakPointResult[]> {
        let result: userAccessModel.BreakPointResult[] = [];
        const userRoleId = this._utilities.GetsessionStorageValue('_userInfo', 'roleId');
        let accesses = await this._authentication.getPromise<userAccessModel.BreakPointAccess[]>({
            route: TenantRoutes.GetBreakPoint
            , uriParams: { roleID: userRoleId, bkpn: breakPointNumber }
        });
        accesses = accesses ? accesses : [];
        result = accesses.map(access => {
            return <userAccessModel.BreakPointResult>{
                isAllow: access.allow,
                isViewOnly: access.view,
                breakPointNumber: access.breakPointNumber
            };
        });
        return result;
    }

    public async isViewOnly(breakPointNUmber: number): Promise<boolean> {
        return true;
    }




}
