import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TenantManagementCommunication } from '../../../shared/Communication/Common/TenantManagement.Service';
import { Localization } from '../../../core/localization/Localization';
import { Utilities } from '../../../shared/directives/utilities';
import { MenuObj } from '../../menu/menu.model';
import { TenantRoutes } from 'src/app/core/Extensions/tenant-route';

@Injectable()
export class RouteLoaderDataService {
  constructor(private _tenantManagement: TenantManagementCommunication, private localization: Localization, private _utils: Utilities) { }


  async getProductMenus(): Promise<MenuObj[]> {
    let menus: MenuObj[] = [];
    menus = await this._tenantManagement.getPromise({
      route: TenantRoutes.MenuElements,
      uriParams: ""
    });
    menus = menus.filter(x => x.productID == 7 && x.parentID ==0 );
    menus[1].linkedElement.pop();
    if(sessionStorage.getItem('loggedUser') == '')
    {
      menus[0].linkedElement.shift();
      menus = menus.filter(x => x.elementID !=2514 );
    }

    return menus;
  }
  public getSettings():Observable<any> {
    const menuList: MenuObj[] = [
        {
           elementID:2499,
           tenantID:0,
           propertyID:0,
           productID:7,
           textId:3000,
           text:"TENANT",
           routePath:"/home",
           imgPath:"icon-home",
           order:0,
           visibility:true,
           disable:false,
           parentID:0,
           menuPosition:"Primary",
           menuAlignment:"Horizontal",
           externalLink:false,
           linkedElement:[
              {
                 elementID:2500,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3014,
                 text:"TENANT LIST",
                 routePath:"/home/tenantList",
                 imgPath:"icon-menu",
                 order:1,
                 visibility:true,
                 disable:false,
                 parentID:2499,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[

                 ],
                 "breakPointNumber":21001
              },
              {
                 elementID:2501,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3001,
                 text:"PROPERTIES",
                 routePath:"/home/properties",
                 imgPath:"",
                 order:2,
                 visibility:true,
                 disable:false,
                 parentID:2499,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[

                 ],
                 "breakPointNumber":21001
              },
              {
                 elementID:2503,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3003,
                 text:"LITERALS",
                 routePath:"/home/literals",
                 imgPath:"",
                 order:4,
                 visibility:true,
                 disable:false,
                 parentID:2499,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[

                 ],
                 "breakPointNumber":21003
              }
           ],
           "breakPointNumber":21000
        },
        {
           elementID:2504,
           tenantID:0,
           propertyID:0,
           productID:7,
           textId:3004,
           text:"SETTINGS",
           routePath:"/settings",
           imgPath:"icon-settings2",
           order:2,
           visibility:true,
           disable:false,
           parentID:0,
           menuPosition:"Primary",
           menuAlignment:"Horizontal",
           externalLink:false,
           linkedElement:[
              {
                 elementID:2505,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3005,
                 text:"USER SETUP",
                 routePath:"/settings/userSetup",
                 imgPath:"string",
                 order:1,
                 visibility:true,
                 disable:false,
                 parentID:2504,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[
                    {
                       elementID:2506,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3006,
                       text:"User Setup",
                       routePath:"/settings/userSetup/userSetup",
                       imgPath:"",
                       order:1,
                       visibility:true,
                       disable:false,
                       parentID:2505,
                       menuPosition:"ternary",
                       menuAlignment:"Combo",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21006
                    },
                    {
                       elementID:2507,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3007,
                       text:"Role Setup",
                       routePath:"/settings/userSetup/roleSetup",
                       imgPath:"",
                       order:2,
                       visibility:true,
                       disable:false,
                       parentID:2505,
                       menuPosition:"ternary",
                       menuAlignment:"Combo",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21007
                    },
                    {
                     elementID:2507,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3007,
                     text:"Department Setup",
                     routePath:"/settings/userSetup/departmentSetup",
                     imgPath:"",
                     order:2,
                     visibility:true,
                     disable:false,
                     parentID:2505,
                     menuPosition:"ternary",
                     menuAlignment:"Combo",
                     externalLink:false,
                     linkedElement:[

                     ],
                     "breakPointNumber":21007
                  },
                    {
                       elementID:2508,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3008,
                       text:"User Role Configuration",
                       routePath:"/settings/userSetup/roleConfig",
                       imgPath:"",
                       order:3,
                       visibility:true,
                       disable:false,
                       parentID:2505,
                       menuPosition:"ternary",
                       menuAlignment:"Combo",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21008
                    },
                    {
                     elementID:2508,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3009,
                     text:"Active User",
                     routePath:"/settings/userSetup/activeUser",
                     imgPath:"",
                     order:3,
                     visibility:true,
                     disable:false,
                     parentID:2505,
                     menuPosition:"ternary",
                     menuAlignment:"Combo",
                     externalLink:false,
                     linkedElement:[

                     ],
                    // "breakPointNumber":21008
                  }
                 ],
                 "breakPointNumber":21005
              },
              {
                 elementID:2509,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3009,
                 text:"POLICIES",
                 routePath:"/settings/policies",
                 imgPath:"",
                 order:2,
                 visibility:true,
                 disable:false,
                 parentID:2504,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[
                    {
                       elementID:2510,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3010,
                       text:"Data Retention Management",
                       routePath:"/settings/policies/dataRetension",
                       imgPath:"",
                       order:1,
                       visibility:true,
                       disable:false,
                       parentID:2509,
                       menuPosition:"Secondary",
                       menuAlignment:"Vertical",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21011
                    },
                    {
                       elementID:2511,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3011,
                       text:"Consent Policy Setup",
                       routePath:"/settings/policies/consentPolicysetup",
                       imgPath:"",
                       order:2,
                       visibility:true,
                       disable:false,
                       parentID:2509,
                       menuPosition:"Secondary",
                       menuAlignment:"Vertical",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21012
                    },
                    {
                       elementID:2512,
                       tenantID:0,
                       propertyID:0,
                       productID:7,
                       textId:3012,
                       text:"Retention Reasons",
                       routePath:"/settings/policies/retentionReasons",
                       imgPath:"",
                       order:3,
                       visibility:true,
                       disable:false,
                       parentID:2509,
                       menuPosition:"Secondary",
                       menuAlignment:"Vertical",
                       externalLink:false,
                       linkedElement:[

                       ],
                       "breakPointNumber":21013
                    },
                    {
                     elementID:2512,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3012,
                     text:"Data Retention Policy Setup",
                     routePath:"/settings/policies/dataRetentionPolicySettings",
                     imgPath:"",
                     order:3,
                     visibility:true,
                     disable:false,
                     parentID:2509,
                     menuPosition:"Secondary",
                     menuAlignment:"Vertical",
                     externalLink:false,
                     linkedElement:[

                     ],
                     // "breakPointNumber":21013
                  }
                 ],
                 "breakPointNumber":21009
              },
              {
               elementID:2509,
               tenantID:0,
               propertyID:0,
               productID:7,
               textId:3009,
               text:"SETUP",
               routePath:"/settings/setup",
               imgPath:"",
               order:2,
               visibility:true,
               disable:false,
               parentID:2504,
               menuPosition:"Secondary",
               menuAlignment:"Horizontal",
               externalLink:false,
               linkedElement:[
                  {
                     elementID:2510,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3010,
                     text:"JASPER DATA SYNC",
                     routePath:"/settings/setup/syncStatus",
                     imgPath:"",
                     order:1,
                     visibility:true,
                     disable:false,
                     parentID:2509,
                     menuPosition:"Secondary",
                     menuAlignment:"Vertical",
                     externalLink:false,
                     linkedElement:[

                     ],
                     "breakPointNumber":1500000                  
                  },
                  {
                     elementID:2511,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3011,
                     text:"ADB2C CONFIGURATION",
                     routePath:"/settings/setup/adb2cConfig",
                     imgPath:"",
                     order:2,
                     visibility:true,
                     disable:false,
                     parentID:2509,
                     menuPosition:"Secondary",
                     menuAlignment:"Vertical",
                     externalLink:false,
                     linkedElement:[

                     ],
                     "breakPointNumber":1500005
                  },
                  {
                     elementID:2512,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3012,
                     text:"V-CART CONFIGURATION",
                     routePath:"/settings/setup/vcartConfiguration",
                     imgPath:"",
                     order:3,
                     visibility:true,
                     disable:false,
                     parentID:2509,
                     menuPosition:"Secondary",
                     menuAlignment:"Vertical",
                     externalLink:false,
                     linkedElement:[

                     ],
                     "breakPointNumber":1500010
                  },
                  {
                     elementID:2512,
                     tenantID:0,
                     propertyID:0,
                     productID:7,
                     textId:3012,
                     text:"TENANT SETUP CONFIGURATION",
                     routePath:"/settings/setup/tenantSetupConfiguration",
                     imgPath:"",
                     order:4,
                     visibility:true,
                     disable:false,
                     parentID:2509,
                     menuPosition:"Secondary",
                     menuAlignment:"Vertical",
                     externalLink:false,
                     linkedElement:[

                     ],
                     "breakPointNumber":1500015
                  },
               ],
               "breakPointNumber":1500015
            }
           ],
           "breakPointNumber":21004
        },
        {
           elementID:2513,
           tenantID:0,
           propertyID:0,
           productID:7,
           textId:3013,
           text:"MENU",
           routePath:"/menu",
           imgPath:"icon-menu",
           order:3,
           visibility:true,
           disable:false,
           parentID:0,
           menuPosition:"Primary",
           menuAlignment:"Horizontal",
           externalLink:false,
           linkedElement:[

           ],
           "breakPointNumber":21014
        },
        {
           elementID:2514,
           tenantID:0,
           propertyID:0,
           productID:7,
           textId:3015,
           text:"COMMON",
           routePath:"/common",
           imgPath:"icon-settings2",
           order:4,
           visibility:true,
           disable:false,
           parentID:0,
           menuPosition:"Primary",
           menuAlignment:"Horizontal",
           externalLink:false,
           linkedElement:[
              {
                 elementID:2515,
                 tenantID:0,
                 propertyID:0,
                 productID:7,
                 textId:3002,
                 text:"LANGUAGE",
                 routePath:"/common/language",
                 imgPath:"",
                 order:1,
                 visibility:true,
                 disable:false,
                 parentID:2514,
                 menuPosition:"Secondary",
                 menuAlignment:"Horizontal",
                 externalLink:false,
                 linkedElement:[

                 ],
                 "breakPointNumber":21002
              },
              {
                elementID:2516,
                tenantID:0,
                propertyID:0,
                productID:7,
                textId:3002,
                text:"TITLES",
                routePath:"/common/titles",
                imgPath:"",
                order:1,
                visibility:true,
                disable:false,
                parentID:2514,
                menuPosition:"Secondary",
                menuAlignment:"Horizontal",
                externalLink:false,
                linkedElement:[

                ],
                "breakPointNumber":21002
             },
             {
              elementID:2515,
              tenantID:0,
              propertyID:0,
              productID:7,
              textId:3002,
              text:"NATIONALITIES",
              routePath:"/common/nationalities",
              imgPath:"",
              order:1,
              visibility:true,
              disable:false,
              parentID:2514,
              menuPosition:"Secondary",
              menuAlignment:"Horizontal",
              externalLink:false,
              linkedElement:[

              ],
              "breakPointNumber":21002
           },

           ],
           "breakPointNumber":0
        },
        {
          elementID:2514,
          tenantID:0,
          propertyID:0,
          productID:7,
          textId:3015,
          text:"UTILITIES",
          routePath:"/utilities",
          imgPath:"icon-settings2",
          order:4,
          visibility:true,
          disable:false,
          parentID:0,
          menuPosition:"Primary",
          menuAlignment:"Horizontal",
          externalLink:false,
          linkedElement:[
             {
               elementID:2505,
               tenantID:0,
               propertyID:0,
               productID:7,
               textId:3005,
               text:"EVENT VIEWER",
               routePath:"/utilities/eventviewer",
               imgPath:"string",
               order:1,
               visibility:true,
               disable:false,
               parentID:2504,
               menuPosition:"Secondary",
               menuAlignment:"Horizontal",
               externalLink:false,
               linkedElement:[]
             }
          ]
        }

    ];
    return of(menuList);
  }
}
