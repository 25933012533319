import {  Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { InputActionPlaceHolder, ButtonValue, AgToggleConfig, HeaderInput } from 'src/app/ag-common/models/ag-models';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CodeSetups } from 'src/app/shared/enums/shared-enums';

@Component({
  selector: 'app-single-input-header-action',
  templateUrl: './single-input-header-action.component.html',
  styleUrls: ['./single-input-header-action.component.scss']
  
})
export class SingleInputHeaderActionComponent implements OnInit, OnDestroy {
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  ActionForm: UntypedFormGroup;
  captions: any;
  isUpdate: boolean;
  updateCaption: any;
  toggleInput: AgToggleConfig;
  name: string;
  active: string;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);    
  nameMinLength: number; 
  nameMaxLength: number;
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input() validationType: string;
  @Input() inputPlaceholder: InputActionPlaceHolder;
  @Input() minValue: number;
  @Input() maxValue: number = 50;
  @Input() inputFor: CodeSetups;
  @Input() disablecancel:boolean;
  @Input() errorPaceholder:string;
  @Input('clearInput')
  set clearFormInputs(value) {
    if (value) {
      this.clearForm();
    }
  }

  @Input('disabled')
  set setDisabled(value: boolean) {
    if (value) {
      this.ActionForm.controls.name.disable();
      this.cancelButton.disabledproperty = value;
    } else {
      this.ActionForm.controls.name.enable();
      this.cancelButton.disabledproperty = value;
    }
  }


  @Input('editActionObj')
  set ActionObject(value: HeaderInput) {
    this.isUpdate = false;
    if (value) {
      this.isUpdate = value.toggleShow;
      this.saveButton.label = this.captions.btn_update;
      this.ActionForm.patchValue(value);
    }
    if (this.isUpdate) {
      this.toggleInput = {
        group: this.ActionForm,
        horizontal: false,
        label: 'Active',
        formControlName: 'isActive'
      }
    }
  }


  @Input('inputPlaceholder')
  set setPlaceHolder(value: InputActionPlaceHolder) {
    this.name = value.name;
  }


  constructor(private formBuilder: UntypedFormBuilder) {
    this.captions = {
      btn_update:"UPDATE",
      btn_add:"ADD",
      btn_cancel:"CANCEL",
      err_min_length:"Please enter minimum length"
    };
    let regexPattern = "[a-zA-Z0-9 ]{" + 1 + "," + 100 + "}$";
    this.ActionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(regexPattern), Validators.maxLength(this.nameMaxLength), Validators.minLength(this.nameMinLength)]],
      isActive: true,
      id: 0
    });

    this.toggleInput = {
      group: this.ActionForm,
      horizontal: false,
      label: 'Active',
      formControlName: 'isActive'
    }

    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_add,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
  }

  ngOnInit() {    
    this.ActionForm.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      this.saveButton.disabledproperty = !(this.ActionForm.dirty && this.ActionForm.valid);
    });
  }
  

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  onSave(event) {    
    this.save.emit(this.ActionForm.value);
    this.clearForm();
  }

  onCancel(arg) {
    this.clearForm();
    this.cancel.emit(this.ActionForm.value);
  }

  private clearForm() {
    this.isUpdate = false;
    this.saveButton.label = this.captions.btn_add;
    this.ActionForm.reset({
      isActive: true,
      id: 0,
      listOrder: 0
    });
  }
  private validateInput(): boolean {
    let value: string = this.ActionForm.controls.actionInput.value;
    value = value ? value : '';
    if (value.trim() == '') {
      this.ActionForm.controls.actionInput.setErrors({ incorrect: true });
      return false;
    }
    return true;
  }

}
