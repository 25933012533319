import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/core/localization/Localization';
import { Utilities } from "../../directives/utilities";
import { ServiceParams } from '../../models/http.model';

export class HttpCallService {

    constructor(host: string
        ,private http: HttpClient
        ,private localization: Localization
        , private _utils: Utilities
        ,private route: ActivatedRoute) {
        this.baseURL = host;
    }
    private baseURL: string = '';

    protected get<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.get<T>(url, { headers: this.setHeaders() });
    }

    protected put<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.put<T>(url, params.body, { headers: this.setHeaders() })
    }   

    protected putWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Observable<T> {
        let url: string = this.formURL(params);
        let header = this.setHeaders() ;
        header = this.updateHeaders(header,customHeaders);
        return this.http.put<T>(url, params.body, { headers: header})
    } 

    protected post<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.post<T>(url, params.body, { headers: this.setHeaders() });
    }

    protected postWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Observable<T> {
        let url: string = this.formURL(params);
        let header = this.setHeaders() ;
        header = this.updateHeaders(header,customHeaders);
        return this.http.post<T>(url, params.body, { headers: header });
    }
    protected postWithMinimumHeader<T>(params : ServiceParams) : Promise<T>{
        let url : string = this.formURL(params);
        return this.http.post<T>(url,params.body).toPromise();
    }
    protected delete<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        const httpOptions =
        {
            headers: this.setHeaders(),
            body: params.body
        };
        return this.http.delete<T>(url, httpOptions);
    }

    protected deleteWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Observable<T> {
        let url: string = this.formURL(params);
        let header = this.setHeaders() ;
        header = this.updateHeaders(header,customHeaders);
        const httpOptions =
        {
            headers: header,
            body: params.body
        };
        return this.http.delete<T>(url, httpOptions);
    }

    protected patch<T>(params: ServiceParams): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.patch<T>(url, params.body, { headers: this.setHeaders() });
    }

    protected getPromise<T>(params: ServiceParams): Promise<T> {
        return this.get<T>(params).toPromise();
    }

    public getCancellablePromise<T>(params: ServiceParams, notifier: Subject<void>): Promise<T> {
        return this.getCancellable<T>(params, notifier).toPromise();
    }


    private getCancellable<T>(params: ServiceParams, notifier: Subject<void>): Observable<T> {
        let url: string = this.formURL(params);
        return this.http.get<T>(url, { headers: this.setHeaders() })
               .pipe(takeUntil(notifier))
    }

    protected putPromise<T>(params: ServiceParams): Promise<T> {
        return this.put<T>(params).toPromise();
    }

    protected putPromiseWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Promise<T> {
        return this.putWithCustomHeader<T>(params,customHeaders).toPromise();
    }
  
    protected postPromise<T>(params: ServiceParams): Promise<T> {
        return this.post<T>(params).toPromise();
    }

    protected postPromiseWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Promise<T> {
        return this.postWithCustomHeader<T>(params,customHeaders).toPromise();
    }
    protected patchPromise<T>(params: ServiceParams): Promise<T> {
        return this.patch<T>(params).toPromise();
    }

    protected deletePromise<T>(params: ServiceParams): Promise<T> {
        return this.delete<T>(params).toPromise();
    }

    protected deletePromiseWithCustomHeader<T>(params: ServiceParams,customHeaders: any): Promise<T> {
        return this.deleteWithCustomHeader<T>(params,customHeaders).toPromise();
    }

    private formURL(params: ServiceParams): string {
        this.validate(params);
        let url: string = `${this.baseURL}/`;
        if (params.uriParams != undefined && params.uriParams != null && typeof params.uriParams == "object") {
            let route: string = params.route;
            let keys: string[] = Object.keys(params.uriParams);
            for (let i = 0; i < keys.length; i++) {
                var regEx = new RegExp("{" + keys[i] + "}", "ig");
                route = route.replace(regEx, params.uriParams[keys[i]]);
            }
            url += route;
        } else {
            url += params.route;
        }

        url = this.formatQueryString(url, params);
        return url;
    }

    private formatQueryString(url: string, params: ServiceParams): string {
        let queryParams: string[] = this.matchQueryStringRegex(url);
        for (var queryParam of queryParams) {
            var paramName = queryParam.split(":")[0];
            paramName = paramName ? paramName : "";
            paramName = paramName.replace("{", "");
            var qParamValue = params.uriParams[paramName];
            var qParamString = "";
            if (typeof qParamValue == "object" && qParamValue && qParamValue.length > 0) {
                for (var value of qParamValue) {
                    qParamString += `${paramName}=${value}&`
                }
                // To remove last &
                qParamString = qParamString.substr(0, qParamString.length - 1);
            }
            else {
                qParamString = `${paramName}=${qParamValue}`;
            }
            url = url.replace(queryParam, qParamString);
        }
        return url;
    }

    private matchQueryStringRegex(url: string): string[] {
        var regex = /{[A-Z]+:QueryString}/gi;
        var expMatch: RegExpExecArray;
        var result: string[] = [];
        while ((expMatch = regex.exec(url)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (expMatch.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the `m`-variable.
            expMatch.forEach((match, groupIndex) => {
                result.push(match);
            });
        }
        return result;
    }
    
    private setHeaders(): HttpHeaders {
        const userId = this.getUserInfo("userId");
        const tenantId = this.getPropertyInfo("TenantId");
        const propertyId = this.getPropertyInfo("PropertyId");  
        const platformTenantId = sessionStorage.getItem("platformTenantId");   
        const sessionId = sessionStorage.getItem("userSession");   
        return new HttpHeaders()
            .set('Accept-Language', navigator.language)
            .set('UserName', 'AGYS')
            .set("Authorization", 'Bearer ' + sessionStorage.getItem("_jwt"))
            .set("ProductId", sessionStorage.getItem("productId") ? sessionStorage.getItem("productId") :"7")
            .set("UserId", this.checkNumber(userId) ? userId : "0")
            .set("TenantId", this.checkNumber(tenantId) ? tenantId : "1")
            .set("PropertyId", this.checkNumber(propertyId) ? propertyId : "1")
            .set("platformTenantId", this.checkNumber(platformTenantId) ? platformTenantId : "0")
            .set("sessionId", sessionId ? sessionId : "");
    }

    private checkNumber(input: any) {
        let parsed = input ? Number(input) : undefined;
        return !isNaN(parsed);
    }

    private validate(params: ServiceParams): void {
        if (!params) {
            throw new Error('Route not defined');
        }
        if (params.route.includes('{') && !params.uriParams) {
            let message: string = `Route Param "${params.route.match('\{(.*?)\}')[1]}" is not defined in route "${params.route}"`;
            throw new Error(message);
        }
    }
    private getUserInfo(name: string) {
        var result = this.localization.GetsessionStorageValue("_userInfo",name);
        return result ? result : "";
    }
    private getPropertyInfo(name: string) {
        var result = this.localization.GetsessionStorageValue("propertyInfo",name);
        return result ? result : "";
    }
    public updateHeaders(header,customHeaders) {
        if(header.lazyUpdate[5].value != customHeaders.tenantId) {
            header.lazyUpdate[5].value = customHeaders.tenantId;
        }
        return header;
    }
    protected errorHandler(err: HttpErrorResponse): void {
        if (err.error && err.error.errorCode) {
            this.showBusinessError(err.error.errorCode);
         } else {
            this._utils.showError(err.message);
        }
    }
    private showBusinessError(errorCode: string) {
        let code: number = parseInt(errorCode);
        let message: string = this.localization.getError(code);
        this._utils.showError(message);
    }

}