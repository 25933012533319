

export const enum Product {
  SPA = 1,
  RETAIL = 2,
  GOLF = 3,
  Common = 4,
  SalesAndCatering = 5,
  PMS = 6,
  Tenant = 7,
  EnhancedInventory = 8,
  HouseKeeping = 9,
  Accounting = 10, 
  Folio = 11
}


export enum menuTypes {
  primary = 'Primary',
  secondary = 'Secondary',
  tertiary = 'Tertiary',
  lowerLevel = 'Lowerlevel'
}

export enum menuPosition{
  initial = "initial",
  none = 'None',
  horizontal = 'Horizontal',
  vertical = 'Vertical',
  combo = 'Combo'
}

export const COMPRESSION_LIMIT = 500;
// ALLOWED_IMAGE_SIZE is the max file size allowed.
// Size in MB
export const ALLOWED_IMAGE_SIZE = 2;

export const EnableResortFinance = 'EnableResortFinance'

