import { Component, Input,EventEmitter, Output } from '@angular/core';
import { customTableheaderAction } from '../../enums/shared-enums';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss']
})
export class CustomTableComponent {
   @Input() customtableheaderOptions
   @Input() tableContent;
   @Output() EmittedData = new EventEmitter();
   text:number = 0
   customTableheaderAction=customTableheaderAction

  emitTableData(eve, data, index) {
    // this.emitRequiredField(index);
    this.EmittedData.emit({
      fromType: 'input',
      array: this.tableContent,
      value: eve.target.value,
      Obj: data,
    });

  }

  emitedTableData(eve, data) {
    this.EmittedData.emit({
      fromType: 'input',
      array: this.tableContent,
      value: eve.target.value,
      Obj: data,
    });

  }


  emitDropdownData(eve,data,index)
  {
    // this.emitRequiredField(index);
    this.EmittedData.emit({
      fromType: 'select',
      array: this.tableContent,
      value: eve.target.value,
      Obj: data,
    });

  }

  setallInput(eve,headerObj,index)
  {
   this.tableContent.map(x=>{
     x[headerObj.key].input=eve.target.value;
   })

   this.setallEmit()

  // this.emitSelectAllRequired(index)


  }


  comonInput(eve,headerObj)
  {
    this.tableContent.map(x=>{
      x[headerObj.key]=eve.target.value;
    })

    this.setallEmit()
  }

  comonDropdown(eve,headerObj,index)
  {
    this.tableContent.map(x=>{
      x[headerObj.key]=eve.target.value;
    })

    this.setallEmit()
    // this.emitSelectAllRequired(index)
  }

  setallDropdown(eve,headerObj,index)
  {
    this.tableContent.map(x=>{
      x[headerObj.key].select=eve.target.value;
      x[headerObj.key].value=eve.target.value;
    })

    this.setallEmit()
    // this.emitSelectAllRequired(index)
  }

  setallEmit()
  {
    this.EmittedData.emit({
      fromType: 'setall',
      array: this.tableContent,
    });

  }

  emitRequiredField(i)  {
   if(this.tableContent[i].retentionPeriod.input != "0" || this.tableContent[i].retentionPeriod.select != 0 || this.tableContent[i].retentionFrom != "0" || this.tableContent[i].reason != 0){
     if(this.tableContent[i].retentionPeriod.select > 0) {
      document.getElementsByClassName("data-retention-dropdown1")[i].classList.remove('required')
     }
     else{
      document.getElementsByClassName("data-retention-dropdown1")[i].classList.add('required')
     }
     if(this.tableContent[i].retentionPeriod.input == '' || this.tableContent[i].retentionPeriod.input == "0" )
     {
       document.getElementsByClassName('data-retention-input')[i].classList.add('required')
     }
     else{
      document.getElementsByClassName('data-retention-input')[i].classList.remove('required')
     }

    if(this.tableContent[i].retentionFrom == '') {
      if(document.getElementsByClassName("retentionFrom")[i]) {
        document.getElementsByClassName("retentionFrom")[i].classList.add('required');
      }
    } else {
      if(document.getElementsByClassName("retentionFrom")[i]) {
        document.getElementsByClassName("retentionFrom")[i].classList.remove('required');
      }
    }
    if(this.tableContent[i].reason == '') {
      if(document.getElementsByClassName("reason")[i]) {
        document.getElementsByClassName("reason")[i].classList.add('required');
      }
    } else  {
      if(document.getElementsByClassName("reason")[i]) {
        document.getElementsByClassName("reason")[i].classList.remove('required');
      }
    }
   }
   else if(this.tableContent[i].retentionPeriod.input == "0" && this.tableContent[i].retentionPeriod.select == 0 && this.tableContent[i].retentionFrom == '0' && this.tableContent[i].reason == 0){
    document.getElementsByClassName("data-retention-dropdown1")[i].classList.remove('required');
    document.getElementsByClassName("retentionFrom")[i].classList.remove('required');
    document.getElementsByClassName("reason")[i].classList.remove('required');
    document.getElementsByClassName('data-retention-input')[i].classList.remove('required')
   }
    console.log("header option",this.customtableheaderOptions);
  }

  emitSelectAllRequired(index)  {
    this.tableContent.map((x,i)=>{
      if(this.tableContent[i].retentionPeriod.input != "0" || this.tableContent[i].retentionPeriod.select != 0 || this.tableContent[i].retentionFrom != "0" || this.tableContent[i].reason != 0){
      if(this.tableContent[i].retentionPeriod.select > 0) {
        document.getElementsByClassName("data-retention-dropdown1")[i].classList.remove('required')
      } else {
      document.getElementsByClassName("data-retention-dropdown1")[i].classList.add('required')
      }
      if(this.tableContent[i].retentionPeriod.input == '' || this.tableContent[i].retentionPeriod.input == "0" )  {
        document.getElementsByClassName('data-retention-input')[i].classList.add('required')
      } else {
        document.getElementsByClassName('data-retention-input')[i].classList.remove('required')
      }

      if(this.tableContent[i].retentionFrom == '') {
        if(document.getElementsByClassName("retentionFrom")[i]) {
          document.getElementsByClassName("retentionFrom")[i].classList.add('required')
        }
      } else {
        if(document.getElementsByClassName("retentionFrom")[i]) {
          document.getElementsByClassName("retentionFrom")[i].classList.remove('required');
        }
      }
      if(this.tableContent[i].reason == ''){
        if(document.getElementsByClassName("reason")[i]) {
          document.getElementsByClassName("reason")[i].classList.add('required');
        }
      } else {
        if(document.getElementsByClassName("reason")[i]) {
          document.getElementsByClassName("reason")[i].classList.remove('required');
        }
      }
      }
      else if(this.tableContent[i].retentionPeriod.input == "0" && this.tableContent[i].retentionPeriod.select == 0 && this.tableContent[i].retentionFrom == '0' && this.tableContent[i].reason == 0){
        document.getElementsByClassName("data-retention-dropdown1")[i].classList.remove('required');
        document.getElementsByClassName("retentionFrom")[i].classList.remove('required');
        document.getElementsByClassName("reason")[i].classList.remove('required');
        document.getElementsByClassName('data-retention-input')[i].classList.remove('required');
      }
    })
  }

}
