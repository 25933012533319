
import { TableAction } from './components/cdkvirtual/cdkvirtual.model';

export enum MatDialogButton {
    save,
    update,
    cancel
}
export enum ContextStructureType {
    tenent = 1,
    grouping = 2,
    property = 3
}

export interface Calendar {
    Sun: string;
    Mon: string;
    Tue: string;
    Wed: string;
    Thu: string;
    Fri: string;
    Sat: string;
    Sunday: string;
    Monday: string;
    Tuesday: string;
    Wednesday: string;
    Thursday: string;
    Friday: string;
    Saturday: string;
    January: string;
    February: string;
    March: string;
    April: string;
    May: string;
    June: string;
    July: string;
    August: string;
    September: string;
    October: string;
    November: string;
    December: string;
    Jan: string;
    Feb: string;
    Mar: string;
    Apr: string;
    Jun: string;
    Jul: string;
    Aug: string;
    Sep: string;
    Oct: string;
    Nov: string;
    Dec: string;
  }

export enum ContextFromType {
    add,
    edit,
    view,
    databaseconfig
}

export enum ProviderList {
    mqsql,
    postgres,
    oracle
}

export enum LanguageList {
    english = 1,
    french = 2,
    tamil = 3
}

export enum CurrencyList {
    dollar = 1,
    rupees = 2,
    rand = 3
}

export interface localizationJSON {
    breakpoint: any;
    alertPopup: any;
}

export enum ContactType {
    phone = 1,
    email = 2
}

export interface headeroption {
    key: string;
    displayName: string;
}

export interface LocalizedMonthsModel {
    id: number;
    short: string;
    long: string;
    code: string;
  }

export enum menuAlignment {
    None,
    Horizontal,
    Vertical,
    Combo
}



export interface TableOptions {
    actions?: TableAction[];
    defaultsortingColoumnKey: string;
    defaultSortOrder: string;
    columnFreeze?: {
        firstColumn: boolean;
        lastColumn: boolean;
    };
    deleteMsgKey?: string;
    isDragDisabled?: boolean;
    isInternalEdit?: boolean;
    inlineActions?: TableAction[];
    isHeaderCheckboxAllowed?: boolean;
    noDataLabel?: string;
    ignoreSort?: boolean;
    showDeleteMsg?: boolean;
    commentKey?: string;
    readOnlyKey?: string;
    isReadOnly?: boolean;
    maxRecordCount?: number;
    checkboxKey?: string;
}





export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7
}

export enum ButtonType {
    YesNo = 1,
    YesNoCancel = 2,
    OkCancel = 3,
    Ok = 4,
    SaveCancel = 5,
    Continue = 6,
    ContinueCancel = 7,
    AddCancel = 8,
    Save = 9,
    Update = 10,
    Custom = 11
}

export enum AlertAction {
    CONTINUE = 'CONTINUE',
    CANCEL = 'CANCEL',
    YES = 'YES',
    NO = 'NO',
    RESV = 'RESEV',
    ALL = "ALL"
}

export interface TenantNode {
    data: TenantDataNode;
    children?: TenantNode[];
}

export interface TenantDataNode {
    contextCode: string;
    contextId: string;
    contextName: string;
    contextType: ContextStructureType;
    id: number;
    parentContextId: string;
}