export enum ActionMode {
    create,
    update,
    cancel,
    delete,
    add,
    view
  }
  
  export enum TableActions {
    comment = 'comment',
    edit = 'edit',
    delete = 'delete',
    drag = 'drag',
    userBlock = 'userBlock',
    salescallletter = 'salescallletter',
    done = 'done',
    cancel = 'cancel',
    copyFunctionDetails = 'copyFunctionDetails',
    copyFunction = 'copyFunction',
    menu = 'menu',
    splitFunction = 'splitFunction',
    preview = 'preview',
    discount = 'discount'
  }
  
  
  export enum EventConstants{
    PropertyEvents ='PropertyEvents',
    UserEvents = "UserEvents",
    TenantEvents = "TenantEvents"
}

  export enum TaskReportType {
    Other,
    Function,
    RoomNight
  }
  
  
  
  export enum TaskReportStatus {
    InProgress = 1,
    Completed,
    NotCompleted
  }
  
  
  export enum BookingActions {
    transfer = 1,
    cancel,
    delete,
    lostandDenial,
    edit,
    create,
    cancelLetter,
    view
  }
  
  export enum InquiryToBookingAction {
    openBooking = 1,
    openBookingFromTemplate
  }
  
  export enum BookingStatus {
    Prospect = 2,
    Tentative = 4,
    Definite = 8,
    Cancelled = 6,
    Released = 10
  }
  
  export enum BookingFromTemplateAction {
    Default = 1,
    InquiryToBooking
  }
  
  export enum ConfirmationStatus {
    Confirmed = 1,
    Draft
  }
  
  export enum ReservationStatus {
    None = 0,
    Active = 2,
    Cancelled = 4,
    Closed = 8,
    Lost = 16
  }
  
  export enum SearchFilter {
    Active = 1,
    Cancelled = 2,
    Closed = 3
  }
  
  export enum ScheduleStatus {
    Daily = 1,
    Inclusive = 2,
    SplitDaily = 3
  }
  
  export enum CreditStatus {
    SalesManager = 1,
    CateringManager = 3,
    ConferenceServiceManager = 2
  
  }
  
  export enum ScheduleType {
    Daily = 1,
    Inclusive = 2,
    SplitDaily = 3
  }
  
  export enum ContactActions {
    edit,
    delete,
    salescallletter
  }
  
  export enum CommentsActions {
    edit,
    delete
  }
  
  export enum SalesCallActions {
    edit,
    delete,
    salescallletter
  }
  
  export enum AccountProfileModes {
    groupsummary = 'groupsummary',
    bookings = 'bookings',
    transient = 'transient'
  }
  export enum RevenueTypes {
    projected = 1,
    forcasted = 2
  }
  
  export enum RevenuePeriods {
    allYear = "allYear",
    thisYear = "thisYear",
    lastYear = "lastYear"
  }
  export enum BookingStatusTypes {
    active = 'active',
    cancelled = 'cancelled',
    closed = 'closed'
  }
  export enum ProfileType {
    groupsummary = 1,
    bookings = 2,
    transient = 3
  }
  export enum RevenueType {
    projected = 1,
    forcasted = 2
  }
  
  export enum RevenuePeriod {
    allYear = 1,
    thisYear = 2,
    lastYear = 3
  }
  export enum BookingState {
    active = 1,
    cancelled = 2,
    closed = 3
  }
  export enum AccountActions {
    edit,
    delete
  }
  
  export enum CompetitorActions {
    input = 'input'
  }

  export enum BudgetDataTypes {
    Daily = 1,
    Monthly,
    Managers,
    FoodAndBeverage
  }
  
  export enum Managers {
    salesManager = 1,
    conferenceServiceManager = 2,
    cateringManager = 3,
    all = 4
  }
  
  export enum AccountTabs {
    accountDetails,
    miscellaneous,
    contact,
    comment,
    task,
    inquiry,
    profile,
    webSettings
  }
  
  export enum ContactSearchType {
    None = 0,
    StandAlone = 1,
    ByName = 2,
    StandAloneAndAccountId = 3
  }
  
  export enum AccountSearchType {
    Default = 0,
    AccountCombine = 1
  }
  export enum RateSetup {
    byRateType = 1,
    byDate = 2
  }
  
  export enum RateTypes {
    rateA = 'rateA',
    rateB = 'rateB',
    rateC = 'rateC'
  }
  
  export const enum SettingModule {
    systemSetup = 'SYSTEMSETUP'
  }
  
  export const enum SettingScreen {
    salesAndCateringGroup = 'SALESANDCATERINGGROUPSETTING',
    defaults = 'DEFAULTSSETTING',
    integration = 'INTEGRATIONSETTING'
  }
  
  export enum AccountInquiryStatus {
    Denied,
    Pending
  }
  
  export enum LetterType {
    booking = 1,
    cancellation = 2,
    inquiryDenial = 3,
    salesCall = 4,
    account = 5,
    beo = 8,
    check = 9,
  }
  
  export enum TemplateType {
    booking = 1,
    cancellation = 2,
    inquiryDenial = 3,
    salesCall = 4,
    account = 5,
    menuItem = 6,
    menu = 7,
    beo = 8,
    check = 9
  }
  
  export enum TaskType {
    Function = 1,
    RoomNight,
    Booking,
    Inquiry,
    Denial,
    SalesCall,
    Contact
  }
  
  export enum TaskBasedOn {
    BookingArrivalDate,
    CutoffDate,
    BookedDate,
    DefiniteDate,
    BookingDepartureDate,
    FunctionArrivalDate,
    FunctionDepartureDate,
    TraceDate
  }
  export enum DefaultTraceTo {
    salesManager = 1,
    conferenceServiceManager = 2,
    cateringManager = 3,
    all = 4,
    loggedInUser = 5
  }
  export enum TaskModes {
    existing = 'existing',
    new = 'new',
    alltasks = 'alltasks',
    assignedToMe = 'asignedToMe',
    assignedByMe = 'assignedByMe',
    assignedBoth = 'assignedBoth',
    completed = 'completed'
  }
  
  export enum TaskIntegrationHeader {
    AssignedOn = 'assignedOn',
    AssignedBy = 'assignedByName',
    AssignedTo = 'assignedToName',
    Priority = 'taskPriority',
    completed = 'completed'
  }
  
  export enum TaskPriority {
    High,
    Medium,
    Low
  }
  
  export enum TaskStatus {
    NotCompleted,
    Completed
  }
  
  export enum MenuType {
    Description = 1,
    Name,
    PriceAndUnit
  }
  
  
  
  
  export const enum Product {
    SPA = 1,
    RETAIL = 2,
    GOLF = 3,
    COMMON = 4,
    SALESANDCATERING = 5,
    PMS = 6,
    TENANT = 7,
    EnhancedInventory = 8,
    HouseKeeping = 9,
    Accounting = 10
  }
  
  export enum CateringSetupMenuType {
    all = 0,
    inventory = 1,
    beverage = 2,
    audioVisual = 4,
    miscellaneous = 8,
    food = 16,
    setup = 32,
    rental = 64
  
  
  }
  export enum CommentActions {
    edit,
    delete
  }
  
  export enum AccountConversionSearchType {
    PatternSearch,
    AdvancedSearch
  }
  
  
  
  export enum AccountInquiryActions {
    create,
    convertToBooking,
    convertToBookingTemplate,
    edit,
    delete,
    inquiryDenialLetter
  }
  
  export enum BookingInquiryStatus {
    All,
    Inquiry,
    Denial
  }
  
  export enum CodeSetups {
    FunctionRoomSetup,
    CancellationPolicies,
    DepositPolicies,
    RoleSetup
  }
  
  
  
  export enum FunctionDetailsType {
    Daily = 1,
    Inclusive = 2
  }
  
  export enum FunctionItemType {
    Food = 16,
    Beverage = 2,
    AudioVisual = 4,
    Miscellaneous = 8,
    Setup = 1,
    Rental = 64,
    All = 0
  }
  export enum PrintTypes {
    letter = 1,
    legal = 2
  }
  
  
  export enum fromPage {
    dashboard = 'dashboard',
    functionBook = 'functionBook',
    beoPost = 'beoPost',
    accountInquiry = 'accountInquiry',
    accounts = 'Accounts'
  }
  
  export enum BasedOnTypes {
    blocked = 1,
    contracted
  }
  export enum DefaultHotelContact {
    salesManager = 1,
    cateringManager = 3,
    conferenceServiceManager = 2
  }
  
  export enum RecentRecords {
    Five = 5,
    Thousand = 1000,
    HomePageWidgetcount = 4
  }
  
  
  export enum AssociateType {
    None = 0,
    Building = 1,
    SalesManager,
    ConferenceManager,
    CateringManager
  
  }
  
  
  export enum fileCombineForms {
    fromAccount,
    toAccount
  }
  
  export enum contactCombineForms {
    fromContact,
    toContact
  }
  
  export enum formTypes {
    fromType,
    toType
  }
  
  export enum RetailItemType {
    RetailItemRetailPOSOnly = 1,
    RetailItemAvailableForSpaPackages,
    SpaServices,
    SpaPackage,
    AppointmentAddon,
    Deposit,
    RentalItem,
    InternalGiftCard,
    ExternalGiftCard,
    RevenueItem
  }
  
  export enum BEOMemoTemplateType {
    function = 1,
    setup,
    menus,
    audioVisual,
    beverage,
    misc,
    billing
  }
  
  export enum DialogCloseOption {
    Success = 1,
    Cancel,
    Close
  }
  
  export enum BarText {
    accountNumber = 1,
    accountName = 2,
    meetingName = 3,
    functionType = 4,
    functionName = 5,
    peopleandCovers = 6,
    setupType = 7,
    meetingNameSetupTypeSetupFor = 8,
    setupBreakdown = 9,
    beoNumber = 10
  }
  
  export enum DayView {
    oneDayView,
    twoDayView,
    threeDayView,
    weekView,
    monthView,
    customView
  }
  
  export enum MenuItemType {
    Menu = 2,
    FoodType = 4
  }
  export enum MenuAction {
    view = 1,
    edit,
    delete
  }
  
  export enum InventoryType {
    All = 0,
    Setup = 1,
    Beverage = 2,
    AudioVisual = 4,
    Miscellaneous = 8,
    Food = 16,
    Rental = 64
  }
  
  
  export enum FunctionRoomStatus {
    All = 0,
    Definite = 8,
    Tentative = 4,
    Prospect = 2,
    WhiteHold = 10
  }
  export enum DataProviderType {
    Booking = 1,
    Account,
    Manager,
    BEO
  }
  
  export enum PrintType {
    Html,
    Pdf,
    Word
  }
  
  export enum ScreenType {
    Bookings = 1,
    Function,
    Room
  }
  
  export enum ObjectType {
    Date = 1,
    Dropdown,
    Text,
    Comment
  }
  
  export enum columnSeparation {
    Single = 1,
    Two,
    Three,
    Four
  }
  
  export enum FunctionOptions {
    All = 1,
    Function = 2,
    Option = 3
  }
  
  export enum EventType {
    All = 1,
    Internal = 2,
    External = 3
  }
  
  export enum SyncFeature {
    Contact = 1,
    AccountTask,
    BookingTask,
    BookingFunction
  }
  export enum StayStatus {
    None = 0,
    Reserved = 1,
    InHouse,
    History,
    NoShow,
    Cancel
  }
  
  export enum NotificationTriggerConstants {
    BeoEmail = 'Beo {0} Sent Successfully',
    LetterEmail = 'Letter {0} Sent Successfully',
    ProformaEmail = 'Proforma {0} Sent Successfully'
  }
  

  export enum customTableheaderAction
  {
    string,
    inputdropdown,
    input,
    dropdown,
    textarea,
    customdropdown
  }

  export enum Adb2cAuthenticationType {
    azuread = 'AZUREAD',
    azureloaclaccount = 'AZURELOCALACCOUNT',
    sso = 'SSO'
  }
