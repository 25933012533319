import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ButtonValue } from '../../models/ag-models';
import { Localization } from 'src/app/core/localization/Localization';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-custom-input-header-action',
  templateUrl: './custom-input-header-action.component.html',
  styleUrls: ['./custom-input-header-action.component.scss']
})
export class CustomInputHeaderActionComponent implements OnInit {
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  form: UntypedFormGroup;
  
  @Input() customInputHeader: TemplateRef<any>;

  @Input('custform')
  set custformgrp(value) { 
    this.form = value.formname;
    if(this.form)
    {
      this.form.statusChanges.subscribe(() => {
        this.saveButton.disabledproperty = !(this.form.dirty && this.form.valid);
      });
    }
  }
  @Input('editActionObj')
  set editActionObj(value){
    if(value)
    {
      this.element = value;
      this.saveButton.label = "UPDATE"
    }
    
  }
  @Output() save = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() customHeaderEmitter = new EventEmitter();
  
  captions: any;

  constructor(private _Localization: Localization) {
    this.captions = this._Localization.captions;
    this.eleiniitialization();
   }
  element = "";

  ngOnInit() {
    console.log(this.form)
    
  }


  eleiniitialization(){
    this.saveButton = {
      type: 'primary',
      label: this.captions.common.ADD,
      disabledproperty: true
    };
  
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.common.CANCEL,
    };
  }

  onSave(event) {
    this.saveButton.disabledproperty = true;
    if(this.saveButton.label == "ADD")
    {
      this.customHeaderEmitter.emit({
        'from':'save',
        'obj':this.form
      })
      this.form.reset(); 
    }

    if(this.saveButton.label == "UPDATE")
    {
      this.customHeaderEmitter.emit({
        'from':'edit',
        'obj':this.form
      })
      this.form.reset(); 
      this.eleiniitialization()
    }
  }
  onCancel(eve) {
    this.form.reset();
    this.eleiniitialization()
    
    this.customHeaderEmitter.emit({
      'from':'cancel',
      'obj':this.form
    })
  }
}
