import { UntypedFormGroup } from '@angular/forms';

export interface HeaderOptionInteface {
  'key': string;
  'sortingKey'?: string;
  'displayName': string;  
  'alignment': string;
  'sorting': boolean;
  'templatecontainer'?: containerInterface;
  'searchable'?:boolean ;
  'tooTipkey'?:string;
}

export interface containerInterface{
  disabledFlag: boolean;
  templateName: templatenameenum;
}

export interface tableOptionInteface {
  'selectType'?: selecttypeenum;
  'options'?: optionstypesenum[];
  'defaultsortingColoumnKey'?:string;
  'defaultSortOrder'?:string;
  'receivedResponse'?:boolean;
}
export interface EmittedInteface{
  'fromType': string,
  'array': string[],
  'value': string,
  'Obj': any
}

// enum values

export enum SorTypeEnum {
  asc = 'asc',
  desc = 'desc'
}
export enum FromTypeEnum {
  done = 'done',
  cancel = 'cancel',
  allcheckbox = 'allcheckbox',
  rowcheck = 'rowcheck',
  edit = 'edit',
  delete = 'delete',
  dragdrop = 'dragdrop',
  claim = 'claim',
  convert = 'convert',
  menuoption = 'menuoption',
  switch = 'switch',
  userBlock = 'userBlock',
  input = 'input',
  dropdown = 'dropdown',
  radioButton = 'radioButton',
  oninit = 'oninit',
  comment = 'comment',
  preview = 'preview',
  sort = 'sort',
  duration = 'duration',
  menuSlider = 'menuSlider',
  customAction = "customAction",
  sendMail = 'sendMail',

}
export enum ActionTypeEnum {
  checkbox = 'checkbox',
  toggle = 'toggle',
  menu = 'menu',
  action = 'action',
  edit = 'edit',
  delete = 'delete',
  userBlock = 'userBlock',
  dragdrop = 'dragdrop',
  numberInput = 'numberInput',
  timeInput = 'timeInput',
  currencyInput = 'currencyInput',
  decimalInput = 'decimalInput',
  custom = 'custom',
  hdrAdd = 'hdrAdd',
  hdrSave = 'hdrSave',
  dropdown = 'dropdown',
  decimalInputMaxval = 'decimalInputMaxval',
  radioButton = 'radioButton',
  link = 'link',
  none= '',
  alphaNumeric = 'alphaNumeric',
  icon = 'icon',
  duration ='duration',
  menuSlider = 'menuSlider'
}

export enum HdrActionTypeEnum {
  hdrAdd = 'hdrAdd',
  hdrSave = 'hdrSave',
  hdrCheckbox = 'hdrCheckbox',
  hdrFilter = 'hdrFilter',
  hdrNone = ''
}


export enum optionstypesenum{
  edit='edit',
  delete='delete',
  dragdrop='dragdrop',
  claim='claim',
  convert='convert',
  moreoption='moreoption',
  userBlock = 'userBlock',
  custommoreoption = 'custommoreoption'
}
export enum selecttypeenum{
  checkbox='checkbox',
  radiobutton='radiobutton'
}
export enum templatenameenum{
  toggleTemplate='toggleTemplate',
  inputTemplate='inputTemplate',
  templateIconName='templateIconName'
}
export enum isRestrictedEnum{
  isRestrictEdit = 'isRestrictEdit',
  isRestrictDelete = 'isRestrictDelete',
  isRestrictClaim = 'isRestrictClaim',
  isRestrictDrag = 'isRestrictDrag',
  isRestrictuserBlock = 'isRestrictuserBlock',
  isRestrictCheckbox = 'isRestrictCheckbox'
}





export interface TableHeaderOptions {
  alignment?: aligment;
  displayName: string;
  hdrtemplateName?: HdrActionTypeEnum;
  inputType?: ActionTypeEnum;
  isInlineEditable?: boolean;
  key: string;
  searchable?: boolean;
  sorting?: boolean;
  sortingKey?: string;
  templateName?: ActionTypeEnum;
  inputs?: any;
  maxValue?: string | number;
  isCheckboxDisabled?: boolean;
  headerData?: any;
  inputTypeDirective?: string;
  blurEventdisable?: boolean;
  duration?: AgDurationConfig;
}
export interface AgDurationConfig {
  label?: string;
  class?: string;
  disabled?: boolean;
  required?: boolean;
  maxValueErrorMessage?: string;
  minValueErrorMessage?: string;
  invalidErrorMessage?: string;
  formGroup?: UntypedFormGroup;
  controlName?: string;
}
export enum aligment {
  left = 'td-align-left',
  right = 'td-align-right',
  center = 'td-align-center'
}


export enum TableActions {
  comment = 'comment',
  edit = 'edit',
  delete = 'delete',
  drag = 'drag',
  userBlock = 'userBlock',
  salescallletter = 'salescallletter',
  done = 'done',
  cancel = 'cancel',
  copyFunctionDetails = 'copyFunctionDetails',
  copyFunction = 'copyFunction',
  menu = 'menu',
  splitFunction = 'splitFunction',
  preview = 'preview',
  discount = 'discount',
  add = 'add',
  editBooking = 'editBooking',
  // customAction = "customAction"
  sendMail = 'sendMail',
  customAction = "customAction"
}

export interface TableOptions {
  actions?: TableAction[];
  defaultsortingColoumnKey: string;
  defaultSortOrder: string;
  columnFreeze?: {
      firstColumn: boolean;
      lastColumn: boolean;
  };
  deleteMsgKey?: string;
  isDragDisabled?: boolean;
  isInternalEdit?: boolean;
  inlineActions?: TableAction[];
  isHeaderCheckboxAllowed?: boolean;
  noDataLabel?: string;
  ignoreSort?: boolean;
  showDeleteMsg?: boolean;
  commentKey?: string;
  readOnlyKey?: string;
  isReadOnly?: boolean;
  maxRecordCount?: number;
  checkboxKey?: string;
  dispTotRcrd?:string;
  enablePagination? : boolean;
  showTotalRecords?: boolean;
  CDK_showConfigure?: boolean;
}
export interface TableAction {
  type: TableActions;
  tooltip?: string;
  disabled: boolean;
  customClass?: string;
}
export interface ButtonValue {
  label: string;
  type: string;
  disabledproperty?: boolean;
  customclass?: string;
}