import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'; 
import { isEmpty } from 'lodash';
import { ImageData } from 'src/app/ag-common/models/ag-models';

@Component({
  selector: 'ag-img-thumbnail',
  templateUrl: './img-thumbnail.component.html',
  styleUrls: ['./img-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImgThumbnailComponent implements OnChanges {

  @Input() imageContent: ImageData;
  @Input() thumbnail: boolean;
  @Input() imageType: string;
  url: any;
  constructor(private domSanitizer: DomSanitizer) { }

  ngOnChanges() {
    if (this.imageContent && !isEmpty(this.imageContent) && this.imageContent.contentType) {
      // Image thumbail component will only use thumbnail data to show the images.
      // so the below line is changed to use thumbnail
      // let imageUrl = `data:${this.imageContent.contentType};base64,${this.thumbnail ? this.imageContent.thumbnailData : this.imageContent.data}`;
      const imageUrl = `data:${this.imageContent.contentType};base64,${this.imageContent.thumbnailData}`;
      // this.url = this.domSanitizer.bypassSecurityTrustUrl(imageUrl);
      this.url = imageUrl;
    } else {
      if (this.imageType === 'flag') {
        this.url = 'assets/images/flag.jpg';
      } else if (this.imageType === 'client') {
        this.url = 'assets/images/client.png';
      } else if (this.imageType === 'user') {
        this.url = 'assets/images/user.png';
      } else if (this.imageType === 'retailItem') {
        this.url = 'assets/images/shop/emptyshop.jpg';
      }
    }
  }

}
