import { Component, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleSearchComponent implements AfterViewInit {

  searchText = '';
  width: number;
  searchPlaceholder: string;
  searchDisabled = false;
  @ViewChild('searchInput') searchInput: ElementRef;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchOptionChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() options;
  @Input() inputMaxLength: number;
  @Input('value')
  set setSearchField(value: string) {
    this.searchText = value;
  }
  @Input() focusField: boolean;
  @Input('disabled')
  set setDisabled(value: boolean) {
    this.searchDisabled = value;
    this.clearText();
  }

  @Input('placeholder')
  set setPlaceHolder(value: string) {
    if (value) {
      this.searchPlaceholder = value;
      this.width = value.length + 5; // added 5 character length because of the icon space and padding
    }
  }

  resize :boolean;
  @Input('noResize')  // on create model popup opens search resize is restricted
  set callResizeSearchBox(value: boolean) {
    if(value){
      this.resize = value;
    }
    if(!value && this.resize){
      this.resize = false;
    }
  }

  ngAfterViewInit(): void {
      if (this.focusField) {
      this.searchInput.nativeElement.focus();
    }
  }

  setMatformWidth() {
    let minWidthElement;
    if (document.getElementsByClassName('header-wrapper')[0].classList.contains('--actionFirst')) {
      minWidthElement = 'left-action-section';
    } else {
      minWidthElement = 'right-action-section';
    }
    return document.getElementsByClassName(minWidthElement)[0] ?
      document.getElementsByClassName(minWidthElement)[0]['offsetWidth'] : 300; //min-300 max-470
    
  }

  // Event Handlers
  searchValueChange() {
    this.searchChange.emit(this.searchText.trim());
  }
  clearText() {
    this.searchText = '';
    this.searchValueChange();
  }
  optionSelected(arg) {
    this.searchText = arg.option.value.viewValue;
    this.searchOptionChange.emit(arg.option.value);
  }
}
