export enum UserAccessBreakPoints {
	TENANT = 21000,
	PROPERTIES = 21001,
	LANGUAGE = 21002,
	LITERALS = 21003,
	SETTINGS = 21004,
	POLICIES = 21006,
	USER_SETUP = 21007,
	ROLESETUP = 21008,
	USERROLECONFIGURATION = 21009,
	DATARETENTIONPOLICYSETUP=21011,
	CONSENTPOLICYSETUP=21012,
	RETENTIONREASONS=21013,
	MENU=21014,
	COMMON = 21015	,
	EVENTVIEWER=21016,
	ACTIVEUSERS=21018,
	SYNCSTATUS=1500000,
	ADB2CCONFIGURATION =1500005,
	VCARTCONFIGURATION=1500010,
	TENANTSETUPCONFIGURATIONS=1500015
}
