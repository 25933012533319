import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { RouteGuardService } from './Route-Guards/route.guard.service';
import { UserAccessBreakPoints } from '../shared/constants/useraccess.constants';
import {AuthGuard}  from '../login/auth.gaurd'


const routes: Routes = [{
  path: '',
  redirectTo: 'login',
  pathMatch: 'full'
},
{
  path: 'login',
  component: LoginComponent
},
{
  path: 'supportlogin',
  component: LoginComponent
},
{
  path: '',
  component: LayoutComponent,
  canActivate:[AuthGuard],
  // canActivate: [RouteGuardService],
  //canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.TENANT, syncAccess: true,ShowPopup: true, redirectTo: 'menu'},
  children: [
    {
      path: 'home',
      // canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.TENANT, syncAccess: true,redirectTo: 'settings'},
      loadChildren: () => import('../tenant/tenant.module').then(m => m.TenantModule)
    },
    {
      path: 'settings',
      canActivate: [RouteGuardService], data: {BreakPointNumber:  UserAccessBreakPoints.SETTINGS, syncAccess: true, ShowPopup: true},
      loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule)
    },
    {
      path: 'menu',
      canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.MENU, syncAccess: true,ShowPopup: true},
      loadChildren: () => import('../menu-management/menu-module.module').then(m => m.MenuModuleModule)
    },
    {
      path: 'common',
       canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.COMMON, syncAccess: true,ShowPopup: true},
      loadChildren: () => import('../tenant-common/tenant-common.module').then(m => m.TenantCommonModule)
    },
    {
      path: 'utilities',
    //  canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.MENU, syncAccess: true,ShowPopup: true},
      loadChildren: () => import('../utilities/utilities.module').then(m => m.UtilitiesModule)
    }
  ]
},
{
  path: 'systemdiagnostics',
  // canActivate: [RouteGuardService],data: {BreakPointNumber:  UserAccessBreakPoints.MENU, syncAccess: true,ShowPopup: true},
  loadChildren: () => import('../system-diagnostics/system-diagnostics.module').then(m => m.SystemDiagnosticsModule)
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {
  constructor() {
    console.log(routes);
  }
}
