import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AgDateConfig } from '../../models/ag-models';
import {Localization} from 'src/app/core/localization/Localization';


@Component({
  selector: 'app-ag-date-picker',
  templateUrl: './ag-date-picker.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AgDatePickerComponent {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  hidden = false;
  maxDate: string | Date;
  minDate: string | Date;
  placeHolder: string;
  value: string | Date;
  captions = {
    invalid: 'Invalid'
  };
  isDateRequired: boolean;
  floatLabel: string;
  @Output() datePickerChange = new EventEmitter();
  isDisabled: boolean;

 @Input('inputs')
  set inputOptions(value: AgDateConfig) {
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.hidden = value.hidden ? value.hidden : false;
    this.maxDate = value.maxDate;
    this.minDate = value.minDate;
    this.placeHolder = value.placeHolder;
    this.value = value.value;
    this.isDateRequired = value.isDateRequired ? value.isDateRequired : false;
    this.isDisabled = value.isDisabled ? value.isDisabled : false;
  }


  constructor(private _localization: Localization) {
    this.floatLabel = this._localization.setFloatLabel;
   }

  dateChanged(event) {
    this.datePickerChange.emit([this.form, this.formControlName]);
  }
}
