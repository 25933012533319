import { CdkDragDrop, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from './../../../core/localization/Localization';
import { CustomVirtualScrollStrategy, GridTableDataSource } from './cdkvirtual.base';
import { ActionTypeEnum, AgDurationConfig, ButtonValue, FromTypeEnum, HdrActionTypeEnum, SorTypeEnum, TableActions, TableHeaderOptions, TableOptions } from './cdkvirtual.model';
export const TOT_REC_HEIGHT = 24;

@Component({
  selector: 'app-cdkvirtual',
  templateUrl: './cdkvirtual.component.html',
  styleUrls: ['./cdkvirtual.component.scss'],
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },{ provide: MatPaginatorIntl }],
  changeDetection: ChangeDetectionStrategy.OnPush
  ,
  encapsulation: ViewEncapsulation.None
})
export class CdkvirtualComponent implements OnInit, OnDestroy {
  placeholderHeight = 0;
  checkvaluechanged: any;
  checkeddata: any[] = [];
  originaldata: any[];
  _tableContent: any;
  ordertype: string;
  sortingColoumnKey: any;
  selectedData: any[] = [];
  commentsForm: UntypedFormGroup;
  isReadOnlyAllowed: boolean = false;
  @Input() options: TableOptions;
  @Input() headerOptions: TableHeaderOptions[];
  @Input() IsEditModeEnabled: boolean;
  searchText: string;
  tableActionEnums: typeof TableActions;
  actionTypeenum = ActionTypeEnum;
  hdrActionTypeenum = HdrActionTypeEnum;
  sorTypeenum = SorTypeEnum;
  captions: any;
  deleteMsgKey: any;
  showDeleteMsg: boolean;
  isDragInProgress: boolean;
  selectedItemId: string | number;
  onDrag: boolean;
  @Input() childTemplate: TemplateRef<any>;
  inlineEditTriggered: boolean;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  commentKey: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  boundary: any;
  dragDisabled: boolean = true;
  totalMsg: any;
  isInavalidNumber: boolean;
  durationInput: AgDurationConfig;
  @Output() isEditModeOn = new EventEmitter();
  totalrecordHeight = 24;
  
  @Input() isViewMode:boolean;
  paginationHeight: number = 0;
  dynamicHeight = 110;
  clonedTabledata: any;
  isModified: boolean;
  uniqueKey: any;
  @Input() throttletime: number = 5000;
  @Input("searchtext")
  set searchOptions(value) { if (value != undefined) { this.searchText = value; this.SearchFilter(value) } }
  get searchOptions() { return this.searchText; }

  @Input('options')
  set setoptions(value: TableOptions) {
    this.options = _.cloneDeep(value);
    if(this.options){
    this.paginationHeight = this.options.enablePagination ? 54 : 0;
    this.dynamicHeight = ((this.options.showTotalRecords) || (this.options.CDK_showConfigure)) ? this.dynamicHeight + this.totalrecordHeight : this.dynamicHeight
    this.totalrecordHeight = ((this.options.showTotalRecords) || (this.options.CDK_showConfigure)) ? TOT_REC_HEIGHT + this.paginationHeight : 0 + this.paginationHeight;
    if (this.options.enablePagination) {
      this.dataSource = new MatTableDataSource(this._tableContent);      
      this.dataSource.paginator = this.paginator;
    } else{ 
      if(!this.dataSource || this.dataSource.hasOwnProperty('filteredData')){
      this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize);      
      this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
        this.placeholderHeight = offset;
        setTimeout(()=> this.cdRef.detectChanges(),1);
      });
      } else {
        this.dataSource.allData = this._tableContent
      }
    }
    }
  }

  @Input()
  set tableContent(value) {
    this.IsEditModeEnabled = false;
    this._tableContent = _.cloneDeep(value);
    this.originaldata = _.cloneDeep(value);
    if (this.searchText != undefined) { this.SearchFilter(this.searchText); }
    if(!this.ordertype) {
      if(this.options && this.options.defaultSortOrder) {
        this.ordertype = this.options.defaultSortOrder;
      } else {
        this.ordertype = SorTypeEnum.asc;
      }
    }
    if(!this.sortingColoumnKey) {
      if(this.options && this.options.defaultsortingColoumnKey) {
        this.sortingColoumnKey = this.options.defaultsortingColoumnKey;
      } else {
        this.sortingColoumnKey = this.findFirstColoum();
      }
    }
    if (this.options && !this.options.ignoreSort) {
      this.contentSorting(this.sortingColoumnKey, this.ordertype);
    }
    this.isDragInProgress = this.options && this.options.isDragDisabled ? this.options.isDragDisabled : false;
    this.displayedColumns = this.headerOptions && this.headerOptions.map(col => col.key);
    if (this.dataSource && this._tableContent) {
      this.dataSource.allData = this._tableContent;
      this.IsEditModeEnabled = false;
      this.inlineEditTriggered = false;
      this.viewport.scrollToOffset(0);
    }
    if(this.options){
      if (this.options.enablePagination) {
        this.dataSource = new MatTableDataSource<any>(this._tableContent);
        this.dataSource.paginator = this.paginator;
      } else {
        this.dataSource.allData = this._tableContent;
        this.itemSize = this.customRowHeight ? this.customRowHeight: 48;        
        this.dataSource['itemSize'] = this.itemSize;
        this.viewport.scrollToOffset(this.itemSize * this.scrolledIndex);
      }
    }
  }

  displayedColumns: any[];
  dataSource: any;

  itemSize = 48;

  @ViewChild(CdkVirtualScrollViewport, { static: true }) viewport: CdkVirtualScrollViewport;
  @ViewChild('preview') preview;
  @ViewChild('comment') comment;
  @ViewChild('edit') edit;
  @ViewChild('delete') delete;
  @ViewChild('drag') drag;
  @ViewChild('done') done;
  @ViewChild('cancel') cancel;
  @ViewChild('menu') menu;
  @ViewChild('userBlock') userBlock;
  @ViewChild('numberInput') numberInput;
  @ViewChild('decimalInput') decimalInput;
  @ViewChild('currencyInput') currencyInput;
  @ViewChild('decimalInputMaxval') decimalInputMaxval;
  @ViewChild('notesPopover') myPopover;
  @ViewChild('alphaNumeric') alphaNumeric;
  @ViewChild('customAction') customAction;
  @ViewChild('sendMail') sendMail;
  @ContentChildren(CdkDragHandle, { descendants: true }) dragHandles: QueryList<CdkDragHandle>
  @Output() EmittedData = new EventEmitter();
  @Output() EmitHdrClick = new EventEmitter();
  @Output() sortEmit = new EventEmitter();
  @Input() customRowHeight: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  scrolledIndex: number;

  constructor(private _Localization: Localization, private fb: UntypedFormBuilder,public _MatPaginatorIntl: MatPaginatorIntl,private cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this._MatPaginatorIntl = new MatPaginatorIntl();
    this._MatPaginatorIntl.itemsPerPageLabel = "Items per page";
    this.IsEditModeEnabled = false;
    this.captions = this._Localization.captions;
    this.tableActionEnums = TableActions;
    this.ordertype = this.options && this.options.defaultSortOrder ? this.options.defaultSortOrder : SorTypeEnum.asc;

    this.displayedColumns = this.headerOptions && this.headerOptions.map(col => col.key);
    this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.itemSize);
    this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
      this.placeholderHeight = offset;
    });
    if(this.options)    {
      if ( this.options.enablePagination) {
        this.dataSource = new MatTableDataSource(this._tableContent)
      } else {    
        if(!this.dataSource){
        this.dataSource = new GridTableDataSource(this._tableContent, this.viewport, this.customRowHeight ? this.customRowHeight : this.itemSize);           
        this.dataSource['itemSize'] = this.itemSize;   
        this.dataSource.offsetChange.pipe(takeUntil(this.destroyed$)).subscribe(offset => {
          this.placeholderHeight = offset;
        });
        }
      }}
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    };
    this.commentsForm = this.fb.group({
      comment: [''],
      currentRow: ['']
    });
    this.commentsForm.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.actionButton.disabledproperty = !(this.commentsForm.valid && this.commentsForm.dirty);
    });
    if (this._tableContent) {
      this.dataSource.allData = this._tableContent;
      this.emitTableDataOnInit();
    }
  }

  ngAfterViewInit(){
    if(this.options){
      if ( this.options.enablePagination) {
        (this.dataSource as MatTableDataSource<any>).paginator = this.paginator;
      } else {
        this.viewport.scrolledIndexChange.pipe(takeUntil(this.destroyed$)).subscribe(x => {
          this.scrolledIndex = x;
        })
      }
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  placeholderWhen(index: number, val: any) {
    return index == 0;
  }
  /**
   * cdk drop
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    this.emitDragrowdata(event);
    this.onDrag = false;
    this.dragDisabled = true;
  }
  /**
   * more options
   * @param e
   * @param menu
   * @param data
   */
  menuOptionsClick(e, menu, data) {
    if (this.options.isInternalEdit && menu.id == TableActions.edit) {
      this.IsEditModeEnabled = true;
      data.isEditable = true;
      this.inlineEditTriggered = true;
    }
    else {
      this.EmittedData.emit({
        fromType: FromTypeEnum.menuoption,
        array: '',
        value: '',
        Obj: menu,
        menuData: data
      });
    }
  }
  /**
   * <th> checkbox select all
   * @param eve
   */
   emitcheckAlldata(eve, key) {
    this.checkeddata = [];
    const checkBoxKey = key ? key : 'checked';
    if (eve.checked) {
      this.dataSource._data.forEach(x => {
        if (x['isCheckboxDisabled']) {
          return;
        }
        else {
          x[checkBoxKey] = true
        }
      });
      this.checkeddata = _.cloneDeep(this.dataSource._data);
      this.checkeddata = [...this.checkeddata];    
    } else {
      this.dataSource._data.forEach(x => {
        if (x['isCheckboxDisabled']) {
          return;
        }
        else {
          x[checkBoxKey] = false
        }
      });
      this.checkeddata = [];
      this.checkeddata = [...this.checkeddata];
    }
    this.isModified = !this.isModified;
    this.EmittedData.emit({
      fromType: FromTypeEnum.allcheckbox,
      array: this.dataSource._data,
      value: eve.checked,
      Obj: '',
      checkedData: this.checkeddata
    });
  }

  /**
  * <td> checkbox select
  * @param eve
  * @param data
  */
  emitcheckdata(eve, data, key) {

    data[key] = eve.checked;
    this.isModified=!this.isModified
    
    this.checkUncheckDataEmitter(eve.checked, data,key); 
  }

  checkUncheckDataEmitter(isChecked, data,key?:string) {
    if (isChecked) {
      this.checkeddata.push(data);
    } else {
      const index = this.checkeddata.findIndex(x => x[this.uniqueKey] === data[this.uniqueKey]);
      this.checkeddata.splice(index, 1);
      this.checkeddata = [...this.checkeddata];
    }
    this.EmittedData.emit({
      fromType: FromTypeEnum.rowcheck,
      array: this._tableContent,
      value: '',
      Obj: data,
      checkedData: this.checkeddata,
      key
    });
  }
  /**
   * row edit click
   * @param eve
   * @param data
   */
  emitEditrowdata(eve, data) {
    this.IsEditModeEnabled = true;
    if (this.options.isInternalEdit) {
      data.isEditable = true;
      this.inlineEditTriggered = true;
    } else {
      this.EmittedData.emit({
        fromType: FromTypeEnum.edit,
        array: '',
        value: '',
        Obj: data
      });
    }
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }
  /**
   * row delete click
   * @param eve
   * @param data
   */
  emitDeleterowdata(eve, data) {
    this.deleteEmitData(data);
  }



  /**
   * row block click
   * @param eve
   * @param data
   */
  emitBlockrowdata(eve, data) {
    this.blockEmitData(data);
  }


  emitCommentrowdata(arg, data, headervalue, idx) {

    this.commentsForm.get('comment').setValue(data[this.commentKey]);
    this.commentsForm.get('currentRow').setValue(data);
    if (data.isNotesActionHide) this.commentsForm.get('comment').disable();
    else this.commentsForm.get('comment').enable();
    this.positionPopover(arg, headervalue, idx);
  }
  emitDonerowdata(arg, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.done,
      array: '',
      value: '',
      Obj: data
    });
    data.isEditable = false;
    this.inlineEditTriggered = false;
    this.IsEditModeEnabled = false;
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }
  emitCancelrowdata(arg, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.cancel,
      array: '',
      value: '',
      Obj: data
    });
    const orgObj = this.originaldata.find(x => x.id === data.id);
    Object.keys(orgObj).forEach(k => {
      data[k] = orgObj[k];
    });
    data.isEditable = false;
    this.IsEditModeEnabled = false;
    this.inlineEditTriggered = false;
    this.isEditModeOn.emit(this.inlineEditTriggered);
  }

  deleteEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.delete,
      array: '',
      value: '',
      Obj: data
    });
  }

  emitCustomActionrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.customAction,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  blockEmitData(data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.userBlock,
      array: this._tableContent,
      value: '',
      Obj: data
    })
  }

  emitDragrowdata(event) {
    this.isDragInProgress = true;
    const draggedoj = event.item.data;
    const droppeddoj = event.container.data['visibleData'].getValue()[event.currentIndex];

    const data = {
      dragdata: draggedoj,
      dropdata: droppeddoj
    };
    this.EmittedData.emit({
      fromType: FromTypeEnum.dragdrop,
      array: '',
      value: '',
      Obj: data
    });
  }

  positionPopover(arg, header, idx) {
    const viewportElement = this.viewport.elementRef.nativeElement;
    const targetElement = arg.currentTarget.getBoundingClientRect();
    const headerHeight = 37;
    const arrowWidth = 18;
    const arrowHeight = 18;
    const popoverContent = this.myPopover.element.nativeElement.childNodes[0];
    const popoverWidth = popoverContent.offsetWidth;
    const topPosition = viewportElement.offsetTop + arg.currentTarget.offsetHeight + headerHeight + arg.currentTarget.offsetHeight;
    if (this.myPopover.effectivePlacement === 'top') {
      this.myPopover.top = viewportElement.offsetTop - arrowHeight;
    } else {
      this.myPopover.top = topPosition + idx * this.itemSize;
    }
    this.myPopover.left = targetElement.left - (popoverWidth / 2) - arrowWidth;
    this.myPopover.title = header.tooltip ? header.tooltip : '';
  }


  /**
  * <th> on click row sorting
  * @param key
  * @param type
  */
  sorting(key, type) {
    this.sortingColoumnKey = key;
    let sortype;
    if (type == SorTypeEnum.asc) {
      sortype = this.ordertype = SorTypeEnum.desc;
    } else {
      sortype = this.ordertype = SorTypeEnum.asc;
    }
    if (this.dataSource && this._tableContent) {
      this.dataSource.allData = this.contentSorting(key, sortype);
      this.sortEmit.emit({
        fromType: FromTypeEnum.sort,
        array: this._tableContent,
      });
    }
  }

  /**
  * content sorting
  * @param key
  * @param sortype
  */
  contentSorting(key, sortype) {
    if (this._tableContent && this._tableContent.length > 0) {
      this._tableContent = _.orderBy(this._tableContent, [function (o) {
        if (typeof (o[key]) == 'string') {
          return o[key].toLowerCase();
        } else {
          return o[key];
        }
      }], sortype);
   
    }
    return this._tableContent;
  }

  pageIndex(event) {
    if (this.options.enablePagination) {
      let paginatorVariable = event.container.data._paginator;
      let currentIndex = (paginatorVariable._pageSize * paginatorVariable._pageIndex) + event.currentIndex ;
      return event.container.data['filteredData'][currentIndex];
    } else {
      return event.container.data['visibleData'].getValue()[event.currentIndex]
    }
  }

  findFirstColoum(): string {
    const headerarray = this.headerOptions && this.headerOptions.filter(x => x.searchable).map(x => x.key);
    return headerarray && headerarray[0]; 
  }

  /**
   * search filter
   * @param searchText
   */
  SearchFilter(searchText: string): any {
    const headerarray = this.headerOptions.filter(x => x.searchable).map(x => x.key);
    if (headerarray && headerarray.length > 0 && this.originaldata) {
      this._tableContent = this.originaldata.filter(result => {
        const headerKey = headerarray;
        for (const key in result) {
          if (typeof (result[key]) == 'string' && result[key].toLowerCase().includes(searchText.toLowerCase())) {
            if (headerKey.indexOf(key) != -1) {
              return result[key].toLowerCase().includes(searchText.toLowerCase());
            }
          } else if (typeof (result[key]) == 'number') {
            if (headerKey.indexOf(key) != -1) {
              const matchedValue = Number(result[key].toString().toLowerCase().includes(searchText.toLowerCase()));
              if (matchedValue) {
                return matchedValue;
              }
            }
          }
        }
      });
      const sortedResult = this.contentSorting(this.sortingColoumnKey, this.ordertype);
      if (this.dataSource && this._tableContent) {
        this.dataSource.allData = sortedResult;
      }
    }
    this.viewport.checkViewportSize();  
  }

  /**
   * Event on Toggle Change in table tow
   * @param change event
   */
  toggleChange(event, row, key) {
    if (row) {
      row[key].value = event.checked;
      this.EmittedData.emit({
        fromType: FromTypeEnum.switch,
        array: this._tableContent,
        value: event.checked,
        Obj: row
      });
    }
  }

  emitTableData(eve, data, key) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.input,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key,
      isInavalidNumber:this.isInavalidNumber
    });
    console.log(this._tableContent);
  }

  emitTableDataOnInit() {
    this.EmittedData.emit({
      fromType: FromTypeEnum.oninit,
      array: this._tableContent,
      value: '',
      Obj: '',
    });
  }

  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */
  emitDropdownData(eve, data, key) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.dropdown,
      array: this._tableContent,
      value: eve.target.value,
      Obj: data,
      updatedKey: key
    });
    console.log(this._tableContent);
  }
  /**
  * Event on Header Action Click
  * @param event, Hdr Key
  */

  headerActionClick(from, hdr) {
    this.EmitHdrClick.emit(
      {
        fromType: from,
        key: hdr,
        array: this._tableContent
      });
  }

  emitSelecteddata(eve, data, key, radioKey) {
    data[radioKey] = true;
    this.selectedData = [];
    this._tableContent.forEach(x =>{
      if(x[key] == data[key]) {
        x[radioKey] = true;
      } else {
        x[radioKey] = false;
      }
    });
    this.selectedData.push(data);
    this.EmittedData.emit({
      fromType: FromTypeEnum.radioButton,
      array: this._tableContent,
      value: '',
      Obj: data,
      selectedData: this.selectedData
    });
  }

  saveNote() {
    const currentRow = this.commentsForm.get('currentRow').value;
    currentRow[this.commentKey] = this.commentsForm.value.comment;
    this.EmittedData.emit({
      fromType: FromTypeEnum.comment,
      array: this._tableContent,
      value: this.commentsForm.value.comment,
      Obj: currentRow
    });
    this.commentsForm.reset();
  }

  cancelComment() {
    this.commentsForm.reset();
  }

  recordMove(arg) {
    this.onDrag = true;
  }

  trackByFn(idx, obj) {
    return idx;
  }

  validateMaxValue(event, maxValue) {
    if (maxValue && event) {
      if (Number(event.target.value) > maxValue) {
        event.target.classList.add('invalid-number');
       

      } else {
        if (event.target.classList.contains('invalid-number')) {
          event.target.classList.remove('invalid-number');        
        }

      
      }
      this.validateSave();
      this.isInavalidNumberValidate()
      
    }
  }

  isInavalidNumberValidate()
  {
    if (document.getElementsByClassName('invalid-number').length){
      this.isInavalidNumber=true
    }else{
      this.isInavalidNumber=false
    }
  }

  validateSave() {
    if (document.getElementsByClassName('invalid-number').length && document.getElementsByClassName('icon-save')[0]) {
      document.getElementsByClassName('icon-save')[0].classList.add('disabled');
    } else {
      if (document.getElementsByClassName('icon-save')[0]
        && document.getElementsByClassName('icon-save')[0].classList.contains('disabled')) {
        document.getElementsByClassName('icon-save')[0].classList.remove('disabled');
      }
    }
  }

  emitPreviewrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.preview,
      array: this._tableContent,
      value: '',
      Obj: data
    });

  }

  emitMoreAction(eve, data){
    this.EmittedData.emit({
      fromType: FromTypeEnum.menuSlider,
      array: this._tableContent,
      value: '',
      Obj: data
    });
  }

  durationEmit(event,key,data){
    data[key] = event.value;
    this.EmittedData.emit({
      fromType: FromTypeEnum.duration,
      array: this._tableContent,
      value: event,
      Obj: data
    });
  }

  emitSendMailrowdata(eve, data) {
    this.EmittedData.emit({
      fromType: FromTypeEnum.sendMail,
      array: this._tableContent,
      value: '',
      Obj: data
    });

  }

}


import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'isAllSelected'
})
export class IsAllSelectedPipe implements PipeTransform {
    transform(allData, key, isModified) {
        let returnvalue = allData && allData.length;
        if (returnvalue) {
            const checked = allData.map(x => key ? x[key] : x.checked).filter(x => x);
            returnvalue = checked.length === allData.length;
        }
        return returnvalue;
    }
}