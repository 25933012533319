import { Directive, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Directive({
  selector: '[appFormChanges]'
})
export class formChangesDirective {

  form:UntypedFormGroup;  
  formRawValues:any;  
  formCurrentValues:any;    
  compareValuesWith:any;
  
  @Input('appFormChanges')
  set setForm(value: UntypedFormGroup) {   
    this.form = value;
  } 

  @Input('changeInputValues')
  set changeInputValues(value: UntypedFormGroup) {         
    this.compareValuesWith = value;       
  } 
 
  @Output() enableEditSave = new EventEmitter();
  @Output() formStatusChange = new EventEmitter();
  
  private valueSubscription: Subscription;
  private statusSubscription: Subscription;
  
  ngOnInit() {     
    this.statusSubscription = this.form.statusChanges.subscribe(x => {            
      this.formRawValues = this.form.getRawValue();     
      this.formStatusChange.emit([x,this.formCurrentValues,this.formRawValues]);
    });
    
    this.valueSubscription = this.form
      .valueChanges      
      .subscribe(value => {
        let enableSave:boolean; 
        this.formCurrentValues = value;
        this.formRawValues = this.form.getRawValue(); 
        let objectIsEqual = _.isEqual(this.compareValuesWith, this.formRawValues); //if we use disable property in our form and it should also be validated,we use raw values to compare
        // let objectIsEqual = _.isEqual(this.compareValuesWith, next); //if we use disable property in our form and it should be not be validated,we use form values to compare      
        enableSave = !objectIsEqual; 
        this.enableEditSave.emit(enableSave);
    });

  } 

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
    this.statusSubscription.unsubscribe();
  }

}

