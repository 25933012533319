import { Injectable } from '@angular/core';
import { APITenantGroup, APITenant, TenantOrganization, APIProperty, APIPropertyGroup, APILanguage, APIProduct, APICurrency, APITimeZone, ProductFeatures, TenantDetails,APIPropDateFormat, PropertyConfiguration, TenantOrganizationWithPropertyInfo, TenantConfiguration, APIDecimalPlaces,PlatformTenant, ResortFinanceAPIPropertyGroup, APIPropTimeFormat } from './tenant-setup.model';
import { TenantManagementCommunication } from 'src/app/shared/Communication/Common/TenantManagement.Service';
import { TenantRoutes } from 'src/app/core/Extensions/tenant-route';
import { HttpClient } from '@angular/common/http';
import { NotificationServiceCommunication } from 'src/app/shared/Communication/Common/NotificationServices.Service';
import { APIPropertiesGroup } from 'src/app/settings/user-setup-module/user-setup/create-user/user-settings/user-settings.model';
import { PolicySetting } from 'src/app/settings/policies-module/policy.model';

@Injectable()
export class TenantSetupDataService {


    constructor(private _tenantManagementCommunication: TenantManagementCommunication,private _notificationServiceCommunication: NotificationServiceCommunication, private http: HttpClient) {

    }

    public async PostTenantGrouping(tenentData: APITenantGroup): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.postPromise({ route: TenantRoutes.PostTenantGrouping, body: tenentData });

        } catch(e) {
            console.error(e);
            throw e;
        }
  }

    public async PutTenantGrouping(tenentData: APITenantGroup): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.putPromise({ route: TenantRoutes.PutTenantGrouping, body: tenentData });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async PostPropertyGrouping(propertyData: APIPropertyGroup): Promise<number> {
        try {
            return await this._tenantManagementCommunication.postPromise({ route: TenantRoutes.PostPropertyGrouping, body: propertyData });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async PostResortFinancePropertyGroup(propertyData: APIPropertyGroup): Promise<number> {
        try {
            return await this._tenantManagementCommunication.postPromise({ route: TenantRoutes.PostResortFinancePropertyGroup, body: propertyData });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async PutPropertyGrouping(propertyData: APIPropertyGroup): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.putPromise({ route: TenantRoutes.PutPropertyGrouping, body: propertyData });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async PutResortFinancePropertyGroup(propertyData: ResortFinanceAPIPropertyGroup): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.putPromise({ route: TenantRoutes.PutResortFinancePropertyGroup, body: propertyData });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getTimeZones(): Promise<APITimeZone[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<APITimeZone[]>({ route: TenantRoutes.GetAllTimeZones });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getLanguages() {
        try {
            return await this._tenantManagementCommunication.getPromise<APILanguage[]>({ route: TenantRoutes.GetAllLanguages });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getMaximumDecimalPlaces() {
        try {
            return await this._tenantManagementCommunication.getPromise<APIDecimalPlaces[]>({ route: TenantRoutes.GetMaximumDecimalPlaces});

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getPropertyDateFormat() {
        try {
            return await this._tenantManagementCommunication.getPromise<APIPropDateFormat[]>({ route: TenantRoutes.GetPropertyDateFormat});

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getPropertyTimeFormat(){
        try {
            return await this._tenantManagementCommunication.getPromise<APIPropTimeFormat[]>({ route: TenantRoutes.GetPropertyTimeFormat});

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getAllProductFeatures(propertyId : number) {
        try {
            return await this._tenantManagementCommunication.getPromise<ProductFeatures[]>({ route: TenantRoutes.GetAllProductFeatures , uriParams: { propertyId: propertyId }});
 
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getPlatformTenant(searchText : string) {
        try {
            return await this._tenantManagementCommunication.getPromise<PlatformTenant[]>({
                 route: TenantRoutes.GetPlatformTenant , 
                // uriParams: { id: 2}});
                 uriParams: { index: 0, orderBy: "tenantId" ,pageSize:25, searchText: searchText, sort: "asc" }});

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getCurrencies() {
        try {
            return await this._tenantManagementCommunication.getPromise<APICurrency[]>({ route: TenantRoutes.GetAllCurrency });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getProductsList() {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProduct[]>({ route: TenantRoutes.GetAllProducts });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getStandAloneProductsList() {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProduct[]>({ route: TenantRoutes.GetAllStandAloneProducts });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getPropertyList() {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProperty[]>({ route: TenantRoutes.GetPropertyDetails });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getTenantOrganization(): Promise<TenantOrganization[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<TenantOrganization[]>({ route: TenantRoutes.GetTenantOrganization });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getTenantOrganizationWithPropertyInfo(): Promise<TenantOrganizationWithPropertyInfo[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<TenantOrganizationWithPropertyInfo[]>({ route: TenantRoutes.GetTenantOrganizationsWithPropertyInfo });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async getTenantInfobyTenantId(tenantId:number): Promise<APITenant> {
        try {
            return await this._tenantManagementCommunication.getPromise<APITenant>({ 
                route: TenantRoutes.GetTenantInfobyTenantId,uriParams: { tenantId: tenantId }  });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getPasswordSettings(tenantId:number): Promise<any> {
        try {
            return await this._tenantManagementCommunication.getPromise<any>({ 
                route: TenantRoutes.PasswordSetting,uriParams: { TenantId: tenantId }  });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
  
    public async getTenants(): Promise<APITenant[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<APITenant[]>({ route: TenantRoutes.GetTenants });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getPropertiesByTenantId(_tenantId: number): Promise<APIProperty[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProperty[]>({ route: TenantRoutes.GetPropertiesByTenantId, uriParams: { tenantId: _tenantId } });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async getPropertyInfobyPropertyId(propertyId: number): Promise<APIProperty[]> {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProperty[]>({ route: TenantRoutes.GetPropertyInfo, uriParams: { PropertyId: propertyId } });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }


    public async getTenant(_contextId: string): Promise<APITenant> {
        try {
            return await this._tenantManagementCommunication.getPromise<APITenant>({
                route: TenantRoutes.GetTenant,
                uriParams: { contextId: _contextId }
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
        
    }

    public async SavePasswordSettings(data: any): Promise<any> {
        try {
            return await this._tenantManagementCommunication
            .putPromise({
                route: TenantRoutes.UpdatePasswordSettings, 
                body: data 
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }


    public async getProperty(_contextId: string): Promise<APIProperty> {
        try {
            return await this._tenantManagementCommunication.getPromise<APIProperty>({
                route: TenantRoutes.GetProperty,
                uriParams: { contextId: _contextId }
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetTenantByTenantCode(_contextCode: string): Promise<APITenant> {
        try {
            return await this._tenantManagementCommunication.getPromise<APITenant>({
                route: TenantRoutes.GetTenantByContextCode,
                uriParams: { tenantCode: _contextCode }
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async FindDuplicateContextByTenant(_parentContextId: string, _contextCode: string): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.getPromise<boolean>({
                route: TenantRoutes.FindDuplicateContextByTenant,
                uriParams: { parentContextId: _parentContextId, contextCode: _contextCode }
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    public async FindDuplicateContext(_contextId: string,previousContextId: string): Promise<boolean> {
        try {
            return await this._tenantManagementCommunication.getPromise<boolean>({
                route: TenantRoutes.FindDuplicateContext,
                uriParams: { contextId: _contextId, previousContextId: previousContextId}
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async PostTenantConfig(tenentData: TenantDetails): Promise<boolean> {
        try {
            return await this._notificationServiceCommunication.postPromise({ route: TenantRoutes.PostTenantConfiguration, body:tenentData  });

        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetTenantConfig(_propertyId: number):Promise<string> {
        try {
            return await this._notificationServiceCommunication.getPromise({route:TenantRoutes.GetTenantConfiguration,
                uriParams: { propertyId: _propertyId }});
        } catch(e) {
            console.error(e);
            throw e;
        }
    }   

    public async GetPropertyConfig(_propertyId: number, _configName: string, _productId: number = 0): Promise<string> {
        try {
            return await this._tenantManagementCommunication.getPromise({
                route: TenantRoutes.GetPropertyConfiguration,
                uriParams: {
                    productId: _productId,
                    configName: _configName,
                    propertyId: _propertyId
                }
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async createPropertyConfig(propConfig: PropertyConfiguration<string>): Promise<string> {
        try {
            return await this._tenantManagementCommunication.postPromise({
                route: TenantRoutes.CreateOrUpdatePropertyConfiguration,
                body: propConfig
            })
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async updatePropertyConfig(propConfig: PropertyConfiguration<string>): Promise<string> {
        try {
            return await this._tenantManagementCommunication.putPromise({
                route: TenantRoutes.CreateOrUpdatePropertyConfiguration,
                body: propConfig
            })
        } catch(e) {
            console.error(e);
            throw e;
        }
    }


    public async GetPropertyGroupsByTenantId(tenantId: number): Promise<APIPropertiesGroup[]> {
        try {
            return await this._tenantManagementCommunication.getPromise({ 
                route: TenantRoutes.GetPropertyGroupsByTenantId,
                uriParams:{ tenantId} 
              }); 
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetTenantConfiguration(configurationName: string): Promise<TenantConfiguration<any>> {
        try {
            return await this._tenantManagementCommunication.getPromise({ 
                route: TenantRoutes.GetTenantConfigurations,
                uriParams:{ configurationName} 
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetPolicySetting(tenantId: number): Promise<any> {
        try {
            return await this._tenantManagementCommunication.getPromise({ 
                route: TenantRoutes.GetPolicySetting,
                uriParams:{ tenantId} 
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
    
    public async SavePolicySetting(policySetting: any): Promise<any> {
        try {
            return await this._tenantManagementCommunication.postPromise({
                route: TenantRoutes.SavePolicySetting,
                body: policySetting
            })
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetProductsUsingTenantId(tenantId: number): Promise<any> {
        try {
            return await this._tenantManagementCommunication.getPromise({ 
                route: TenantRoutes.GetProductUsingTenantId,
                uriParams:{ tenantId} 
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }

    public async GetDataPolicyConfiguredFlag(tenantId: number): Promise<any> {
        try {
            return await this._tenantManagementCommunication.getPromise({ 
                route: TenantRoutes.GetDataRetentionPolicyConfiguredFlag,
                uriParams:{ tenantId} 
            });
        } catch(e) {
            console.error(e);
            throw e;
        }
    }
}
