import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { RouteLoaderDataService } from './route-loader.data.service';
import { menuTypes } from '../../../shared/global.constant';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RouteLoaderService {

    currentSettings: any;
    menu='';

    constructor(
        private httpClient:HttpClient,
        private injector: Injector,
        private api: RouteLoaderDataService,
    ) { 
        
    }

    loadSettings(): Promise<any> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const router = this.injector.get(Router);
                console.log(router);
               this.api.getSettings().subscribe(// -- for hardcoded menu uncomment this line and comment the previous line
            //    this.api.getProductMenus().then(
                        response => {
                            console.log('is Dynamic Menu available?:'+(!Array.isArray(response) || 0==response.length)? 'DM-No!':'DM-Yes!');
                            this.currentSettings =  response;
                            console.log('current settings',this.currentSettings);
                            resolve(true);
                        },
                    );
            });
        });
    }

    public GetChildMenu(currentRoute, menuType?: menuTypes) {
        const menuList = this.currentSettings;

        if (menuList) {
            if (currentRoute === '/') {
                return menuList;
            }
            let currentMenu = menuList.find(x => x.routePath === currentRoute);
            if (currentMenu) {
                return currentMenu;
            } else {
                for (const x of menuList) {
                    const obj: any[] = x.linkedElement ? x.linkedElement : null;
                    currentMenu = obj && obj.find(sub => sub.routePath === currentRoute);
                    if (currentMenu) {
                        return currentMenu;
                    }
                    if (menuType === menuTypes.lowerLevel && obj) {
                        for (const childMenu of obj) {
                            const childObj: any[] = childMenu.linkedElement ? childMenu.linkedElement : null;
                            const currentChildMenu = childObj && childObj.find(sub => sub.routePath === currentRoute);
                            if (currentChildMenu) {
                                return currentChildMenu;
                            }

                        }
                    }
                }
            }
        }

    }
}