import { Localization } from 'src/app/core/localization/Localization';
import { AgDropdownConfig, DropdownOptions } from './../../models/ag-models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, from, isObservable } from 'rxjs';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-ag-dropdown',
  templateUrl: './ag-dropdown.component.html',
  styleUrls: ['./ag-dropdown.component.scss']
})
export class AgDropdownComponent implements OnInit {
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder: string;
  selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  isFirstEmpty: boolean;
  isSelect: boolean;
  isAll: boolean;
  showRequired: boolean;
  isDisabled = false;
  @Output() selectChange = new EventEmitter();
  captions: any;
  isMultiple: boolean;
  customErrorMessage:string;
  defaultSelectededOptionValue:DropdownOptions;
  isAllSelected: boolean;
  subValue: any;
  valuesSelected;
  dataSourse: DropdownOptions[] = [];
  valueSelectionLogic: boolean;
  floatLabel: string;
  defaultData: any;
  selectedData: any;
  @Input('inputs')
  set inputOptions(value: AgDropdownConfig) {
    this.className = value.className;
    this.errorMessage = value.errorMessage;
    this.form = value.form;
    this.formControlName = value.formControlName;
    this.placeHolder = value.placeHolder;
    this.selectOptions = value.selectOptions;
    this.isFirstEmpty = value.isFirstEmpty ? value.isFirstEmpty : false;
    this.isSelect = value.isSelect ? value.isSelect : false;
    this.isAll = value.isAll? value.isAll : false;
    this.showRequired = value.showRequired ? value.showRequired : false;
    this.isDisabled = value.disabled ? value.disabled : false;
    this.isMultiple = value.isMultiSelect ? value.isMultiSelect : false;
    this.defaultSelectededOptionValue=value.defaultSelectededOptionValue;
    this.customErrorMessage = value.customErrorMessage;
    this.isAllSelected = value.isAllSelected ? value.isAllSelected : false;
    if (this.isAll && this.selectOptions) {
      this.isAllconfig();
    }
   
    if(this.defaultSelectededOptionValue)
    {
      this.form.controls[this.formControlName].setValue(this.defaultSelectededOptionValue);
    }

  }

  constructor(private _localization: Localization) {
    this.floatLabel = this._localization.setFloatLabel;
   }

  ngOnInit() {
    this.captions = this._localization.captions;
  }

  /**
   * Method used to compare id of the option object
   * and returns true if the selected option matches
   * **/
  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  }

  onSelectionChange(e,ctrlname) {
    if(e)
    this.selectChange.emit(e.value);
  }
  checkAll(e){
    if(e.checked){
      // this.selectOptions.then()
      let dropdata : any =this.selectOptions;
      dropdata.subscribe(x => {
        this.subValue = x;
      });
      // console.log(DDdata)
     this.form.controls[this.formControlName].setValue(this.subValue);
    }else{
      this.form.controls[this.formControlName].setValue([]);
    }
    this.selectChange.emit(this.form.controls[this.formControlName].value);
  }
  isAllconfig() {
    from(this.selectOptions).subscribe(result => {
      this.dataSourse = result;
      if (this.isAllSelected) {
        if (this.valueSelectionLogic) {
          this.valuesSelected = result.slice(0, result.length);
        }
        else {
          this.form.controls[this.formControlName].setValue(result?.slice(0, result.length));
        }
      }
    });
    this.form.addControl(`isAllSelected`, new UntypedFormControl(true));
  }
  private toggleClickbtn(data: any, selectedDataArray: any[], allselectedCheck?: boolean): any[] {
    let selectedArray = selectedDataArray ? cloneDeep(selectedDataArray) : [];
    const currentlySelectedOption = data;
    if (currentlySelectedOption.id === this.defaultData.id) { /* For all button click */
      if (allselectedCheck) {
        selectedArray = this.dataSourse.map(x => x);
      } else {
        selectedArray = [];
      }
    } else { /* For other than all button click */
      const currentDataIdx = selectedArray.findIndex(x => x.id == currentlySelectedOption.id);
      if (currentDataIdx === -1) {
        selectedArray.push(currentlySelectedOption);
      } else {
        const defaultDataIdx = selectedArray.findIndex(x => x.id == this.defaultData.id);
        selectedArray.splice(currentDataIdx, 1);

        if (defaultDataIdx !== -1) {
          selectedArray.splice(defaultDataIdx, 1);
        }
      }
    }
    return selectedArray;
  }
  filterDropDownSelected(event: any, data: any, name: string) {
    if (this.isAll) {
      if (this.valueSelectionLogic) {
        if (data && data.viewValue === this.captions.lbl_all) {
          this.valuesSelected = this.toggleClickbtn(this.defaultData, this.valuesSelected, event.checked);
        } else {
          this.valuesSelected = this.toggleClickbtn(data, this.valuesSelected);
        }
        this.form.controls[this.formControlName].setValue(this.valuesSelected);
        this.form.get(`isAllSelected`).setValue(this.dataSourse.length === this.valuesSelected.length);
      } else {
        if (data && data.viewValue === this.captions.lbl_all) {
          if (data.id === this.defaultData.id) {
            if (event.checked) {
              this.form.controls[this.formControlName].setValue(this.dataSourse.map(x => x));
            } else {
              this.form.controls[this.formControlName].setValue([]);
            }
          }
        }
        this.form.get(`isAllSelected`).setValue(this.dataSourse.length === this.form.controls[this.formControlName].value.length);
      }
      this.form.controls[this.formControlName].markAsDirty();
      this.selectChange.emit(data);
    } else {
      this.selectedData = data.viewValue;
      this.form.controls[this.formControlName].markAsDirty();
      this.selectChange.emit(data);
    }
  }

}

function of(arg0: undefined[]): (value: any, options?: Object) => void {
  throw new Error('Function not implemented.');
}

