import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Localization } from 'src/app/core/localization/Localization';
import { HttpCallService } from './http-call.service';
import { ServiceParams, BaseResponse } from '../../models/http.model';
import { environment } from 'src/environments/environment';
import { Utilities } from '../../directives/utilities';

@Injectable()
export class SignalRCommunication extends HttpCallService {

    constructor(httpclient: HttpClient, localization: Localization, Route: ActivatedRoute,_utils:Utilities) {
        super(environment["SignalRService"], httpclient, localization,_utils, Route);
    }

   
    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postWithMinimumHeader<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    private error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }
}
