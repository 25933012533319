import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, CanActivateChild } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserAccessBusiness } from 'src/app/shared/data-services/useraccess.business';
import { Utilities } from 'src/app/shared/directives/utilities';
import { userAccessModel } from 'src/app/shared/data-services/useraccess-model.model';

@Injectable({
    providedIn: 'root'
})
export class RouteGuardService implements CanActivate, CanActivateChild, OnDestroy {
    activatedChildresults: userAccessModel.BreakPointResult[];
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(private _userAccessBusiness: UserAccessBusiness, private router: Router, private _utilities : Utilities) {
        this.router.events.pipe(takeUntil(this.destroyed$)).subscribe(x => {
            if (this.router.url === '/login') {
                this.activatedChildresults = null;
            }
        });
    }

    ngOnDestroy() {
        if (this.destroyed$) {
            this.destroyed$.next(true);
            this.destroyed$.complete();
        }
    }

    /**
     * @function GetCurrentUserBreakPointDetails
     * @param CurrentRoute ActivatedRouteSnapshot
     * @returns CurrentUserAccess UserBreakPoint
     * @description returns the breakpoint based on the BreakPoint number from route
     */
    async GetCurrentUserBreakPointDetails(CurrentRoute: ActivatedRouteSnapshot, ShowPopup?: boolean) {
        let CurrentUserAccess: Promise<userAccessModel.BreakPointResult>;
        if (CurrentRoute.data && CurrentRoute.data.BreakPointNumber) {
            CurrentUserAccess = this._userAccessBusiness.getUserAccess(CurrentRoute.data.BreakPointNumber,
                ShowPopup ? ShowPopup : CurrentRoute.data.ShowPopup);
        }
        return CurrentUserAccess;
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.deactivate(route, state);
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {       
        return this.deactivate(route, state) 
    }

    private async deactivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Promise<boolean> {
        try {
            return await this.checkAccess(route, state);
        } catch(e) {
            console.error(e);
                        throw e;
        } 
    }
  

    private async checkAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        let CurrentUserAccess: userAccessModel.BreakPointResult;
        const breakpoint = route.data.BreakPointNumber;
        const checkSibling = route.data.checkAllSiblings;
        const isModule = route.data.isModule; //True if the current route is module level screen
        const isSubmodule = route.data.isSubmodule; //True if the current route is submodule level screen
        const routeHasChildAttr = checkSibling ? route.parent.data.hasChild : false;
        const extras = this.router.getCurrentNavigation().extras;
        const ShowPopup = route.data.ShowPopup || (extras.state && extras.state.ShowPopup);
        const isSubModuleChange = extras.state && extras.state.onSubmoduleChange && isSubmodule; //True when current menu is submodule and it is clicked
        const isModuleChange = extras.state && extras.state.onModuleChange && isModule; //True when current menu is module and it is clicked
        const redirectLocation = route.data.redirectTo;
        const lastBreakpoint = route.data.lastBreakPointNumber;   
      


        await this.getChildBreakPointNos(route, breakpoint);
        CurrentUserAccess = this.activatedChildresults && this.activatedChildresults.find(x => x.breakPointNumber === breakpoint);
        // Fetch all the current and child break point access detail
        
        if (CurrentUserAccess && !CurrentUserAccess.isAllow && !CurrentUserAccess.isViewOnly && ShowPopup) {
            if (!CurrentUserAccess.isAllow) {
                this._userAccessBusiness.showBreakPointError(CurrentUserAccess.breakPointNumber);
            }
        }
        if (!CurrentUserAccess || route.data.syncAccess) {
            await this.getChildBreakPointNos(route, breakpoint);
        }
 
        // Check if all the siblings and current menu dont have access
        if (routeHasChildAttr &&  (!isSubModuleChange && !isModuleChange)) {
            const childBreakPoints = this.getChildBreakpoints(route);
            const isAnyHasAccess = childBreakPoints.some(x => {
                const currentChild = this.activatedChildresults.find(result => result.breakPointNumber === x.data.BreakPointNumber);
                return currentChild.isAllow || currentChild.isViewOnly;
            });
            if (!isAnyHasAccess && isModule) {
                this.redirectToBetterPath(route.parent.data.redirectTo, route.parent, state);
                return false;
            }
            if (!isAnyHasAccess && isSubmodule) {
                this.redirectToBetterPath(redirectLocation, route, state);
                return false;
            }
        }

        // Check if all the siblings and current menu dont have access
        if (routeHasChildAttr && (isModuleChange || isSubModuleChange)) {
            const childBreakPoints = this.getChildBreakpoints(route);
            const isAnyHasAccess = childBreakPoints.some(x => {
                const currentChild = this.activatedChildresults.find(result => result.breakPointNumber === x.data.BreakPointNumber);
                return currentChild.isAllow || currentChild.isViewOnly;
            });
          if (!isAnyHasAccess) {
            this._userAccessBusiness.showBreakPointError(lastBreakpoint);
            return false;
          }
        }

        // Unless route has breakpoint, allow access to the page
        if (!breakpoint) {
            return true;
        }
        // Show alert if break point alert set in route data
        CurrentUserAccess = this.activatedChildresults && this.activatedChildresults.find(x => x.breakPointNumber === breakpoint);
        

        // CurrentUserAccess = await this.GetCurrentUserBreakPointDetails(route, ShowPopup);
        if (CurrentUserAccess && (CurrentUserAccess.isViewOnly || CurrentUserAccess.isAllow)) {
            return (CurrentUserAccess.isViewOnly || CurrentUserAccess.isAllow);
        }
        if (redirectLocation && !ShowPopup) {
            if(breakpoint && (breakpoint==21007 || breakpoint == 21008))
            {
                this.redirectToURL(redirectLocation,route);
            }
            else
            {
                this.redirectToBetterPath(redirectLocation, route, state);
            }
            
        }
        return false;
    }

    async getChildBreakPointNos(route: ActivatedRouteSnapshot, breakpoint) {
 
        const breakPointNumbers = breakpoint ? [breakpoint] : [];
        
        if (route && route.routeConfig && route.routeConfig.children && route.routeConfig.children.length > 0) {
            route.routeConfig.children.forEach(child => {
                if (child) {
                    if (child.data && child.data.BreakPointNumber) {
                        breakPointNumbers.push(child.data.BreakPointNumber);
                    }
                    if (child.children && child.children.length > 0) {
                        child.children.forEach(y => {
                            if (y && y.data && y.data.BreakPointNumber) {
                                breakPointNumbers.push(y.data.BreakPointNumber);
                            }
                        });
                    }
                }
            });
        }
      
        const result = breakPointNumbers && breakPointNumbers.length > 0 &&
            await this._userAccessBusiness.getUserAccesses(breakPointNumbers);
        if (result) {
            if (!this.activatedChildresults) {
                this.activatedChildresults = result;
            } else {
                result.forEach(breakPt => {
                    const existingIdx = this.activatedChildresults.findIndex(x => x.breakPointNumber === breakPt.breakPointNumber);
                    if (existingIdx > -1) {
                        this.activatedChildresults[existingIdx] = breakPt;
                    } else {
                        this.activatedChildresults.push(breakPt);
                    }
                });
            }
        }

    }

    private redirectToBetterPath(redirectLocation, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const parentUrl = state.url
            .slice(0, state.url.indexOf(route.url[route.url.length - 1].path));
        this.router.navigate([parentUrl, redirectLocation]);
    }

    redirectToURL(redirectLocation, route: ActivatedRouteSnapshot) {
        this.router.navigate([redirectLocation]);
    }


    //Get all the child breakpoint data from parent route
    private getChildBreakpoints(route: ActivatedRouteSnapshot): any {
        let routes: any = [];
            let routeChildrens = route.routeConfig.children;
            if(routeChildrens && routeChildrens.length > 0) {
                routeChildrens.forEach(routeChildren => {
                if(routeChildren.data && routeChildren.data.BreakPointNumber) {
                    routes.push(routeChildren);
                }
                const nestedChildrens =  routeChildren.children;
                if(nestedChildrens && nestedChildrens.length > 0) {
                    nestedChildrens.forEach(nestedChildren => {
                        if(nestedChildren.data && nestedChildren.data.BreakPointNumber) {
                            routes.push(nestedChildren);
                        }
                    });
                }
            });
        }
        return routes;
}
}
