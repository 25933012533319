import { UntypedFormGroup } from '@angular/forms';
import { Time } from '@angular/common';
import { Observable } from 'rxjs';
import { SnackBarType } from 'src/app/shared/enums/enums';

export interface AgAddressField {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface AgAddressConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    value?: string;
    disabled?: boolean;
}

export interface AgContactConfig {
    typeControlName?: string;
    typeClass?: string;
    className?: string;
    extensionClass?: string;
    extnPlaceHolder?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    value?: string | number;
    options?: any[];
    showSwitches?: boolean;
    isPrimaryName?: string;
    isPrivateName?: string;
    primarySwitchLabel?: string;
    privateSwitchLabel?: string;
    disabled?: boolean;
}

export interface AgAddressFieldConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    value?: string;
    disabled?: boolean;
}

export interface Addresscomponent {
    long_name: string;
    short_name: string;
    types: string[];
}
export interface GoogleAddressOutput {
    fullAddress?: Addresscomponent;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export interface AgAddressFieldConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formArrayName?: string;
    formControlName?: string;
    placeHolder?: string;
    value?: string;
}

export interface Addresscomponent {
    long_name: string;
    short_name: string;
    types: string[];
}
export interface GoogleAddressOutput {
    fullAddress?: Addresscomponent;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export interface AgDateConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    hidden?: boolean;
    maxDate?: Date | string;
    minDate?: Date | string;
    placeHolder?: string;
    value?: Date | string;
    isDisabled?: boolean;
    isDateRequired?: boolean;
}

export interface AgFieldConfig {
    id?: string;
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    placeHolder?: string;
    value?: string | number;
    maxlength?: string | number;
    minlength?: string | number;
    disabled?: boolean;
    isRequired?: boolean;
    maxValue?: number;
    showLabel?: boolean;
    labelText?: string;
    minValue?: number;
    incrementBy?: number;
    customErrorMessage?: string;
    maximumValueErrorMsg?: string;
}

export interface AgPhoneDetail {
    id: number;
    phoneType: number;
    phoneNumber: string;
    extension: string;
    isPrimary: boolean;
    isPrivate: boolean;
    disabled?: boolean;
}

export interface AgEMailDetail {
    id: number;
    emailType: number;
    emailId: string;
    primaryEmail: boolean;
    privateInformtionEmail: boolean;
}

export interface AgTimeConfig {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    floatLabel?: string;
    hidden?: boolean;
    maxTime?: string | Time;
    minTime?: string | Time;
    placeHolder?: string;
    value?: string | Time;
    defaultTime?: string;
    customErrorMessage?: string;
    isTimeRequired?: boolean;
}

export interface AgToggleConfig<T = any> {
    label?: string;
    name?: string;
    value?: any;
    group: UntypedFormGroup;
    formControlName?: string;
    className?: string;
    disabled?: boolean;
    horizontal?: boolean;
    data?: T;
}

export interface InputActionPlaceHolder {
    code: string;
    name: string;
    errorMessageCode: string;
    errorMessageName: string;
}


// Aside Filter Panel data model
export interface FilterGroup {
    id: number;
    name: string;
    title: string;
    filters: Filter[];
    filtered?: Filter[];
    value?: Filter; // Value to be selected for single filter by default
    isSingleSelect?: boolean;
    expanded?: boolean;
}

export interface Filter {
    id: number;
    name: string;
    class?: string;
    isSelected?: boolean;
    extraParam?: any;
    title?: string;
}

export interface AsideFilterConfig {
    filterText: string;
    resetText: string;
    displayCount: number;
    alphabets?: string[];
    isMultiSelect: boolean;
    viewMoreConfig?: ViewMoreConfig;
}

export interface AsideDateFilter {
    name: string;
    isDateRange?: boolean;
    filters: DateFilter[];
}

export interface ViewMoreConfig {
    apply: string;
    cancel: string;
    searchByPlaceHolder: string;
    alphabets?: string[];
}

export interface ImageData {
    id: number;
    referenceType: string;
    referenceId: number;
    sequenceNo: number;
    contentType: string;
    data: any[];
    thumbnailData: any[];
}

export interface DateFilter {
    name: string;
    minDate?: Date;
    placeholder: string;
    defaultValue?: Date;
    value?: Date;
    maxDate?: Date;
    disable?: boolean;
}

export interface ButtonValue {
    label: string;
    type: string;
    disabledproperty?: boolean;
    customclass?: string;
}

export interface HeaderInput {
    code: string | number;
    name: string;
    isActive: boolean;
    toggleShow: boolean;
}

export interface ReturnButtonValue {
    event: object;
    buttontype?: string;
    value?: number;
}

export interface DataType {
    id: number;
    name: string;
}

export interface SaveButtonObj {
    isEdit: boolean;
    disabled: boolean;
    customSaveText?: string;
    customCancelText?: string;
}

export interface TableSearchHeader {
    createBtnLabel?: string;
    showInactive?: boolean;
    searchPlaceHolder: string;
    toggleLabel?: string;
    createBtnDisabled?: boolean;
    toggleYes?: string;
    toggleNo?: string;
    toggleDisabled?: boolean;
    hasHeaderAction?: boolean;
    searchDisabled?: boolean;
    headerActionDisabled?: boolean;
    searchBarhide?: boolean;
    advancedSearch?: string;
    links?: string;
    maxLength?: number;
    buttonType?: string;
}

export interface AgDropdownConfig<T = any> {
    data?: T;
    className?: string;
    errorMessage?: string;
    form: UntypedFormGroup;
    formControlName: string;
    placeHolder?: string;
    selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
    isFirstEmpty?: boolean;
    isSelect?: boolean;
    isAll?: boolean;
    showRequired?: boolean;
    disabled?: boolean;
    isMultiSelect?: boolean;
    customErrorMessage?: string;
    defaultSelectededOptionValue?: DropdownOptions;
    isAllSelected?: boolean;
    dropdownWithSearch?: boolean;
}

export interface DropdownOptions {
    checked?: boolean;
    id?: number | string;
    value?: string | any;
    viewValue: string;
    disabled?: boolean;
    otherData?: any;
}

export interface RevenueTypeInput {
    captions: any;
    buildingList: RevenueTypeRadioInput[];
    itemList: RevenueTypeRadioInput[];
    form: UntypedFormGroup;
    buildingControlName: string;
    itemControlName: string;
}

export interface RevenueTypeRadioInput {
    id: number;
    checked: boolean;
    value: string | number;
    viewValue: string;
}

export interface SplitMoreAction {
    icon?: string;
    label: string;
    action: string;
    disabled?: boolean;
    class?: string;
}

export interface AgDurationConfig {
    label?: string;
    class?: string;
    disabled?: boolean;
    required?: boolean;
    maxValueErrorMessage?: string;
    minValueErrorMessage?: string;
    invalidErrorMessage?: string;
    formGroup?: UntypedFormGroup;
    controlName?: string;
}

// For multi select dropdown

export interface MultiDropdownInputs {
    className: string;
    errorMessage?: string;
    form: UntypedFormGroup;
    formControlName: string;
    placeHolder: string;
    selectOptions: DropdownOptions[];
    showRequired: boolean;
    defaultAllSelected?: boolean;
    displayAll?: boolean;
    disabled?: boolean;
    maxSelectionLimit?: number;
    selectionLimitExceededErrorMessage?: string;
}

export enum ButtonType {
    YesNo = 1,
    YesNoCancel = 2,
    OkCancel = 3,
    Ok = 4,
    SaveCancel = 5,
    Continue = 6,
    ContinueCancel = 7,
    AddCancel = 8,
    Save = 9,
    Update = 10,
    Custom = 11
}
export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7
}
export interface SnackBarInputs {
    message: string;
    type: SnackBarType;
}

export interface DateInput {
    className?: string;
    errorMessage?: string;
    form?: UntypedFormGroup;
    formControlName?: string;
    hidden?: boolean;
    maxDate?: Date | string;
    minDate?: Date | string;
    placeHolder?: string;
    value?: Date | string;
    isDisabled?: boolean;
    isDateRequired?: boolean;
}