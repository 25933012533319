import { Injectable } from '@angular/core';
import { TenantRoutes } from 'src/app/core/Extensions/tenant-route';
import { Login } from './login.model';
import { BaseResponse,ServiceParams, } from '../shared/models/http.model';
import { LoginManagementCommunication } from '../shared/Communication/Common/LoginManagement.Service';

@Injectable({
  providedIn: 'root',
})

export class TenantLoginService {

  uTempData : string = 'UUVkNUpFUmxYaVJBUjNra1JHVmVKQT09IFJVRXdPVEExUlVJM05VSTNSRGxDT1E9PSBmQT09IE1UVkJOalF5T1RORU5qTTRNVGxDT1E9PSBKRjVsUkNSNVIwQWtYbVZFSkhsSFFBPT0=';

  constructor(private _tenantManagementCommunication: LoginManagementCommunication) {
  }

  public  async validateLogin(serviceParams : ServiceParams): Promise<BaseResponse<Login>> {
    try {
      return await this._tenantManagementCommunication.postPromise<BaseResponse<Login>>(
        serviceParams,false);
    } catch (error) {
        console.error(error);
    }
  }

  public async makeGetCall<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    let response$: Promise<BaseResponse<T>> = this._tenantManagementCommunication.getPromise<BaseResponse<T>>(params);

    //on error =>
   // response$.catch(err => this.error(err, handleErr));
   response$.catch(err => {
    console.error("Make get");
    console.log(err);

   })    
    //on success =>
    return <any>await response$;
  }

  public async makePostCall<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = this._tenantManagementCommunication.postPromise<BaseResponse<T>>(params);
    //on error =>
    //response$.catch(err => this.error(err, handleErr));
    response$.catch(err => {
      console.error("Make post");
      console.log(err);

    })
    //on success =>
    return <any>await response$;
  }

  public async makePutCall<T>(params: ServiceParams, handleErr: boolean = true) {
    let response$: Promise<BaseResponse<T>> = this._tenantManagementCommunication.putPromise<BaseResponse<T>>(params);
    //on error =>
    response$.catch(err => {
      console.error("Make post");
      console.log(err);

    })
    //on success =>
    try {
      return await response$;   
    } catch(e) {
        console.error(e);
                    throw e;
    }
  }

  public async GetBearerToken(_userName :string):Promise<string>
  {
    try {
      return await this._tenantManagementCommunication.getPromise<string>({route: TenantRoutes.GetBearerToken,uriParams: { username: _userName }});
    } catch(e) {
        console.error(e);
                    throw e;
    }
  }    

  public async GetTenantbyEnvironmentConfig():Promise<string>
  {
    try {
      return await this._tenantManagementCommunication.getPromise<string>({route: TenantRoutes.EnvironmentConfig});
    } catch(e) {
        console.error(e);
                    throw e;
    }
  }

  public GetUTempData(priority:number) : string {
    let res = atob(atob(this.uTempData)
              .split(' ')[priority]);
              
    return res;
  }
}
