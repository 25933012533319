import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (sessionStorage.getItem('_authorizedUser') != null && sessionStorage.getItem('_authorizedUser') != "undefined" && sessionStorage.getItem('_authorizedUser') != undefined){
      return true;
    }


      if(sessionStorage.getItem('supportUserMailId')){
        this.router.navigate(["supportlogin"]);
        return false
      } else {
       this.router.navigate(['/login']);
       return false;
     }

  }
}
