import { InputTypeText, InputTypeNumbers, Utilities } from "./utilities";
import { Localization } from "../../core/localization/Localization";

export class InputTypeValidator {

    private DecimalSeperator: string = '.';

    constructor(private utils: Utilities, private localization: Localization, private value: string, private validators: string[]) {
    }

    private validateNegative = () => isNaN(Number(this.value)) ? false : Number(this.value) > 0;
    private validateNoDecimal = () => !this.value.includes(this.DecimalSeperator);
    private validateDecimal = () => !this.validateNoDecimal;
    private validatePercent = () => false;
    private validateRoundOff = () => false;
    private validatePositiveDecimal = () => false;
    private validatePositiveDecimalOrNumeric = () => false;
    private validateCaps = () => /^[A-Z]*$/.test(this.value.trim());
    private validateSpace = () => /^\S*$/.test(String(this.value).toLowerCase());   //returns true if this.value contains space
    private validateText = () => /^[A-Z]*$/.test(this.value);

    private ValidatePositiveDecimalNumbers = () => {
        if (this.ValidatePositiveDecimal())
            return true
        else
            return this.ValidateNonNegative();
    }

    private ValidatePositiveDecimal = () => {
        let regx = new RegExp("^\\d+(" + this.DecimalSeperator + "\\d{2})$");
        return regx.test(String(this.value).toLowerCase())
    }

    private ValidateNonNegative = () => {
        let regx = /^\d+$/;
        return regx.test(String(this.value).toLowerCase())
    }

    private ValidateNumbers = () => {
        let reg = /^-?[\d]+$/;
        return reg.test(String(this.value).toLowerCase())
    }

    private validateEmail = () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.value).toLowerCase());
    }

    validateSpecialCharacters = () => {
        let regx: RegExp = /^([a-zA-Z0-9 _-]+)$/;
        return regx.test(this.value);
    }

    private readonly validationsArr: validationModel[] = [
        {
            validationType: InputTypeText.EMAIL, validator: this.validateEmail
        },
        {
            validationType: InputTypeText.TEXT, validator: this.validateText
        },
        {
            validationType: InputTypeText.NOSPL, validator: this.validateSpecialCharacters
        },
        {
            validationType: InputTypeText.NOSPACE, validator: this.validateSpace
        },
        {
            validationType: InputTypeNumbers.NUMBERS, validator: this.ValidateNumbers
        },
        {
            validationType: InputTypeNumbers.ONLYPOSITIVE, validator: this.ValidateNonNegative
        },
        {
            validationType: InputTypeNumbers.ONLYNEGATIVE, validator: this.validateNegative
        },
        {
            validationType: InputTypeNumbers.NODECIMAL, validator: this.validateNoDecimal
        },
        {
            validationType: InputTypeNumbers.DECIMAL, validator: this.validateDecimal
        },
        {
            validationType: InputTypeNumbers.PERCENT, validator: this.validatePercent
        },
        {
            validationType: InputTypeNumbers.POSITIVEDECIMAL, validator: this.ValidatePositiveDecimalNumbers
        },
        {
            validationType: InputTypeNumbers.POSITIVEDECIMALORNUMERIC, validator: this.ValidatePositiveDecimalNumbers
        }
    ];

    public Validate(): string {

        let isValidToPaste: boolean = true;
        for (let i = 0; i < this.validationsArr.length; i++) {
            const _validator = this.validationsArr[i];
            let typeBln = this.validators.includes(_validator.validationType);
            let validateBlnFn = _validator.validator.bind(this);
            let validateBln = validateBlnFn();
            if (typeBln && !validateBln) {
                isValidToPaste = false;
                this.utils.showError("Pasted data contains incorrect characters");
                break;
            }
        }
        if (this.validators.includes(InputTypeText.CAP)) {
            this.value = this.value.toUpperCase();
        }
        return isValidToPaste ? this.value : '';
    }

}

interface validationModel {
    validationType: InputTypeText | InputTypeNumbers,
    validator: Function
}