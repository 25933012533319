import { UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import * as _ from 'lodash'
import { Injectable } from "@angular/core";
import { Localization } from "../../core/localization/Localization";
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { AlertType, ButtonType } from 'src/app/ag-common/models/ag-models';
import { AlertAction, Calendar } from '../shared-models';
import { AlertPopupComponent } from 'src/app/ag-common/components/alert-popup/alert-popup.component';
import { SnackBarType } from "../enums/enums";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";
import { AgSnackBarComponent } from "src/app/ag-common/components/ag-snack-bar/ag-snack-bar.component";

export enum RedirectToModules {
    retail,
    order,
    appointment,
    settings,
    Utilities,
    exchange,
    Dayend,
    home
}

export interface PatchJson {
    op: PatchOperation;
    path: string;
    value: any;
}

export interface AppointmentColors {
    BackGround: string;
    BackGroundLight: string;
    Color: string;
    ColorLight: string;
    Border: string;
}

export enum PatchOperation {
    add = "add",
    remove = "remove",
    replace = "replace",
    copy = "copy",
    move = "move",
    test = "test"
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
    Yearly = 3
}



export enum InputTypeNumbers {
    NUMBERS = "onlynumber",
    ONLYPOSITIVE = "nonnegative",
    ONLYNEGATIVE = "onlynegative",
    NODECIMAL = "nodecimal",
    DECIMAL = "decimal",
    ROUNDOFF = "roundoff2",
    PERCENT = "validPercentage",
    POSITIVEDECIMAL = "onlyPositiveDecimal",
    POSITIVEDECIMALORNUMERIC = 'PositiveDecimalOrNumeric',
    NUMBERWITHSEPARATOR = "numberWithSeparator"
}

export enum InputTypeText {
    CAP = "capitalise",
    TEXT = "textonly",
    NOSPL = "nospecailchar",
    NOSPACE = "notallowspace",
    EMAIL = "email",
    FIRST_CHAR_CAP = "firstcharcapitalise",
    NOPRESPACE = "noprespace"
}


export interface CssProp {
    property: string;
    value: string;
}


export function stringFormat(input: string, appendBy?: string) {
    if (appendBy) {
        return input ? (input + appendBy) : "";
    } else {
        return input ?  input : "";
    }
}



export function convertPhoneNoToUiNo(num: string): string {
    if (num != null || num == "") {
        return (
            num.substring(3, 0) +
            " - " +
            num.substring(6, 3) +
            " - " +
            num.substring(num.length, 6)
        );
    } else {
        return "";
    }
}

export function tConvert(tt) {
    var time = tt.substring(tt.indexOf("T") + 1, tt.length);
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
        time
    ];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}

export function addMinutesToGivenTime(time, minTobeAdded) {
    let dummyDate: Date = this.getDate("2019-01-01T" + time);
    let dateTimeValue = dummyDate.setMinutes(
        dummyDate.getMinutes() + minTobeAdded
    );
    let dateTimeWithAddedMinutes = this.getDate(dateTimeValue);

    let hours = dateTimeWithAddedMinutes.getHours();
    let min = dateTimeWithAddedMinutes.getMinutes();

    return (hours < 9 ? "0" + hours.toString() : hours.toString()) + ":" + (min < 9 ? "0" + min.toString() : min.toString());
}

@Injectable({
    providedIn: 'root'
})
export class Utilities extends Localization {
    subscription: any;
    isDoubleClickDisabled: boolean = true;

    constructor(public localization: Localization, private dialog: MatDialog, private route: Router,private snackBar: MatSnackBar,
        public http: HttpClient) {
        super()
    }
    
    /*
         * The method returns the only edited data in PatchJson format.
         * In addition to that, The @param defaultKeys -The values of the controls having the name will be added by default.
         * @param formGroup - FormGroup to get the edited data
    */
    showToastMessage(
        snackMessage: string,
        snackType: SnackBarType = SnackBarType.Info,
        timeout: number = 5000,
        horizontalPos: MatSnackBarHorizontalPosition = 'right',
        verticalPos: MatSnackBarVerticalPosition = 'top',
    ) {

        return this.snackBar.openFromComponent(AgSnackBarComponent, {
            duration: timeout,
            horizontalPosition: horizontalPos,
            verticalPosition: verticalPos,
            data: {
                message: snackMessage,
                type: snackType
            },
            panelClass: ['ag-snackbar-container']
        });
    }

    getEditedData(formGroup: UntypedFormGroup, idKey?: string, defaultKeys?: string[], dateKeys?: string[]): any {
        let patchJson = [];
        let keyValue: string;
        let keys: string[] = Object.keys(formGroup.controls);

        defaultKeys = defaultKeys ? defaultKeys : [];
        let fnconvertDateObjToAPIdate = super.convertDateObjToAPIdate;
        keys.forEach(function (key) {
            if (idKey == key) keyValue = formGroup.controls[key].value;

            if (formGroup.controls[key].dirty || defaultKeys.includes(key)) {
                var val = formGroup.controls[key].value;
                if (dateKeys && dateKeys.includes(key)) {
                    val = typeof val != "string" ? fnconvertDateObjToAPIdate(val) : val;
                }
                patchJson.push({
                    op: PatchOperation.replace,
                    path: "/" + key,
                    value: val
                });
            }
        });
        return {
            key: keyValue,
            patchJson: patchJson
        };
    }

    getLongDaysOfWeek(): string[] {
        return this.localization.getLongDaysOfWeek();
    }

    getLongWeekArrayLocale() {
        const longWeekArr = this.getLongDaysOfWeek(); // locale sorted by default
        const returnArr = [];
        const localizedCalender: Calendar = this.localization.captions.calendar;
        for (let lweek of longWeekArr) {
            const localeDay = lweek;
            switch (localeDay) {
                case localizedCalender.Monday:
                    returnArr.push({ id: 1, name: localeDay });
                    break;
                case localizedCalender.Tuesday:
                    returnArr.push({ id: 2, name: localeDay });
                    break;
                case localizedCalender.Wednesday:
                    returnArr.push({ id: 3, name: localeDay });
                    break;
                case localizedCalender.Thursday:
                    returnArr.push({ id: 4, name: localeDay });
                    break;
                case localizedCalender.Friday:
                    returnArr.push({ id: 5, name: localeDay });
                    break;
                case localizedCalender.Saturday:
                    returnArr.push({ id: 6, name: localeDay });
                    break;
                case localizedCalender.Sunday:
                    returnArr.push({ id: 0, name: localeDay });
                    break;
                default:
                    break;
            }
        }

        return returnArr;
    }

    public ShouldDisableSave(form: UntypedFormGroup, initialValue: any = undefined, otherValidations: boolean = true): boolean {
        let validity: boolean;

        if (initialValue) {
            validity = !(form.dirty && !_.isEqual(form.value, initialValue) && form.valid && otherValidations);
        }
        else {
            validity = !(form.dirty && form.valid && otherValidations);
        }
        return validity;
    }

    getDirtyPatchArray(
        exist: any[],
        edited: any[],
        compareKey: string,
        isObject: boolean = false
    ): any {
        let result: PatchJson[] = [];
        let existArr: any[] = [];
        let editedArr: any[] = [];

        if (isObject) {
            if (exist.length > 0) {
                exist.forEach((existObj)=>{
                    existArr.push(existObj[compareKey]);
                })
            }
            if (edited.length > 0) {
                edited.forEach((editedObj)=>{
                    editedArr.push(editedObj[compareKey]);
                })
            }
        } else {
            existArr = exist;
            editedArr = edited;
        }

        let removed = existArr.filter(value => editedArr.indexOf(value) == -1);
        let newlyAdded = editedArr.filter(value => existArr.indexOf(value) == -1);

        removed.forEach((removedObj)=>{
            result.push(
                this.preparePatchJson(PatchOperation.remove, compareKey, removedObj)
            );
        })

        newlyAdded.forEach((newlyAddedObj)=>{
            result.push(
                this.preparePatchJson(PatchOperation.add, compareKey, newlyAddedObj)
            );
        })

        return result;
    }

    preparePatchJson(
        operation: PatchOperation,
        path: string,
        value: any
    ): PatchJson {
        return {
            op: operation,
            path: "/" + path,
            value: value
        }; 
    }

    /**
    * Converts a javascript date to Invariant date format string (C# API can understand this format).
    * @param Date javascript date or Javascript ISO string.    *
    */
    formatDate(dt: Date): string {
        return super.ConvertDateToISODate(dt);
    }

    public showError(message: string): void {
     this.showAlert(message, AlertType.Error, ButtonType.Ok);
        return;
    }

    GetClientTimeZone(): string {
        let zone: string = '';
        try {
            zone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        catch (e) { }
        return zone;
    }


    public showAlert(message: string, type: AlertType, btnType: ButtonType = ButtonType.Ok,
        callback?: (result: AlertAction, extraParams?: any[]) => void, extraParams?: any[], headerText?: string,
        additionalInfo?: {message: string, class: string}) {
        
        const dialogRef = this.dialog.open(AlertPopupComponent, {
        height: 'auto',
        width: '300px',
        data: { type: type, message: message, buttontype: btnType, header: headerText, additionalInfo },
        panelClass: 'small-popup',
        disableClose: true,
        });
        this.subscription = dialogRef.afterClosed().subscribe(res => {
        if (callback) {
        callback(res, extraParams);
        }
        });
        return dialogRef;
        }
    
}
