import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Localization } from 'src/app/core/localization/Localization';

@Component({
  selector: 'app-alpha-dictonary',
  templateUrl: './alpha-dictonary.component.html',
  styleUrls: ['./alpha-dictonary.component.scss']
})
export class AlphaDictonaryComponent {

  captions: any;
  selectedAlp: string;
  all: string;
  showAlltxt: boolean;

  @Input('conditiondatas')
  set conditiondatasInputs(value) {
    if (value) {
      this.showAlltxt = value.showAlltxt ? value.showAlltxt : false;
      this.selectedAlp = value.selectedalphabet;
    }
  }

  @Output() alphabet = new EventEmitter();



  constructor(private localization: Localization) {
    this.captions = this.localization.captions;
    this.all= this.captions.common.all;
    this.selectedAlp = this.captions.common.alphabets[0];
  }

  AlphabetFilter(alp) {
    this.selectedAlp = alp;
    this.alphabet.emit([{'alp':this.selectedAlp}])
  }
}
