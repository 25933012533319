import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AsideDateFilter, FilterGroup, AsideFilterConfig, DateInput } from '../../models/ag-models';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AsideFilterService } from './aside-filter.service';
import { Localization } from 'src/app/core/localization/Localization';



@Component({
  selector: 'app-aside-filter',
  templateUrl: './aside-filter.component.html',
  styleUrls: ['./aside-filter.component.scss'],
  providers: [AsideFilterService],
  encapsulation: ViewEncapsulation.None
})
export class AsideFilterComponent implements OnChanges, OnDestroy {

  _config: AsideFilterConfig;
  _filterGroups: FilterGroup[];
  _dateSelection: AsideDateFilter;
  _filterLimit: number[];
  _panelHeight = '40px';
  _dateInputs: DateInput[] = [];
  isChanged = false;
  isFilterModified = false;
  filteredDataLength = 0;
  dialogSubscription: Subscription;
  showMore = false;
  togglePanel = true;
  moreOptions: {
    buttonObj: { customSaveText: string; customCancelText: string; };
    searchHeaderOption: { searchPlaceHolder: string; };
    searchByChar: { charArr: string[]; all: boolean; };
    position: any;
    filteredDataArr: {}[];
  };
  currentFilterGroup: FilterGroup;
  _enableResetExternally: boolean;
  public filterForm: UntypedFormGroup;

  @Input() filterLimit: number;
  @Input() displayProp: string;
  @Input() template;

  @Output() dateFormReady: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() dateChangedEmitter: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() filterEvent: EventEmitter<FilterGroup> = new EventEmitter();
  @Output() resetEvent: EventEmitter<FilterGroup[]> = new EventEmitter();
  dataContext;

  // Component properties.
  /**
   * @description Filter Configuration
   * @example
   * { "filterText": "Filter"
   * "resetText": "Reset All",
   * "displayCount": 3
   * }
   */
  @Input()
  set config(value: any) {
    this._config = value;
  }
  @Input()
  set filterGroups(value: any[]) {
    this._filterGroups = value;
    this._filterService.clearFilteredOptions(this._filterGroups, true);
    this._filterService.setUnTouched(this._filterGroups);
  }
  @Input()
  set dateSelection(value: AsideDateFilter) {
    if (value) {
      this._dateInputs = [];
      this._dateSelection = value;
      this.filterForm = this.fb.group({});
      this._dateSelection.filters.forEach(el => {
        this.filterForm.addControl(el.name, new UntypedFormControl(el.defaultValue));
        if (el.disable) {
          this.filterForm.get(el.name).disable();
        }
        this._dateInputs.push(this.getDatePickerInputs(el.name, el.placeholder, el.defaultValue, el.minDate, el.maxDate));
      });
      this.dateFormReady.emit(this.filterForm);
    }
  }
  @Input()
  set externalFormDirty(value: boolean) {
    this._enableResetExternally = value;
  }

  constructor(public fb: UntypedFormBuilder, private _filterService: AsideFilterService, public localization: Localization) {
    this.filterForm = this.fb.group({});
  }

  ngOnChanges() {
    if (!this.displayProp) {
      this.displayProp = 'name';
    }
    this.filterLimit = this.filterLimit ? this.filterLimit : 3;

    if (this._filterGroups) {
      this._filterLimit = this._filterGroups.map(() => this.filterLimit);
      if (!this._config.isMultiSelect) {
        this._filterGroups.forEach(el => {
          const ctrl = this.filterForm.get(el.name);
          if (!ctrl) {
            this.filterForm.addControl(el.name, new UntypedFormControl(el.value));
          } else {
            ctrl.setValue(el.value);
          }
        });
      }
    }
    this.filteredDataLength = this._config.isMultiSelect && this._filterService.calculateFilteredDataLength(this._filterGroups);
  }

  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  onChange(event: any, name: string, index: number) {
    // condition for to date
    if (index === 0 && this._dateSelection.filters && this._dateSelection.filters.length > 1) {
      const toDateControl = event[0].value[this._dateSelection.filters[1].name];
      const fromDate = event[0].value[this._dateSelection.filters[0].name];
      this._dateInputs[1].minDate = fromDate;
      if (toDateControl < fromDate) {
        this._dateInputs[1].value = fromDate;
        this.filterForm.get(this._dateSelection.filters[1].name).setValue(fromDate);
      }
      this._dateInputs[1] = { ...this._dateInputs[1] };
    }
    this.dateChangedEmitter.emit(event[0]);
  }

  resetFilter() {
    this.resetDate();

    // reset rest of the filtergroups
    this._filterService.clearFilteredOptions(this._filterGroups);
    this._filterService.setUnTouched(this._filterGroups);
    this.filteredDataLength = this._config.isMultiSelect && this._filterService.calculateFilteredDataLength(this._filterGroups);
    this.resetEvent.emit(this._filterGroups);
    this._enableResetExternally = false;
    this.isFilterModified = false;
  }

  updateCategoryArr(group, filter, idx) {
    this._filterGroups[idx] = this._filterService.ApplyFilter(group, filter);
    this.isChanged = !this.isChanged;
    this.isFilterModified = this._filterService.isModified(this._filterGroups);
    this._filterGroups[idx].filters = this._filterService.orderData(this._filterGroups[idx]);
    this.filteredDataLength = this._config.isMultiSelect && this._filterService.calculateFilteredDataLength(this._filterGroups);
    this.filterEvent.emit(this._filterGroups[idx]);
  }

  applySingleFilter(group, arg) {
    group.filters.forEach(f => {
      f.isSelected = (f.id === arg.value.id);
    });
    group.filtered = arg.value;
    this.isFilterModified = this._filterService.isModified(this._filterGroups);
    this.filterEvent.emit(group);
  }

  ShowRemaining(e, filterGroup) {
    this.showMore = true;
    this.currentFilterGroup = filterGroup;
    this.formViewMoreInfo([e, e.target.getBoundingClientRect()], filterGroup);
  }

  closeMoreWindow() {
    this.showMore = false;
  }
  moreCancelValueChange() {
    this.showMore = false;
  }
  moreApplyValueChange(arg) {
    console.log(arg);
    this.showMore = false;

    this.currentFilterGroup.filters.forEach(value => value.isSelected = arg.some(x => x.id === value.id));
    this.currentFilterGroup.filtered = this.currentFilterGroup.filters.filter(filter => filter.isSelected);
    this._filterGroups[this.currentFilterGroup.id - 1].filters = this._filterService.orderData(this._filterGroups[this.currentFilterGroup.id - 1]);
    this.filteredDataLength = this._config.isMultiSelect && this._filterService.calculateFilteredDataLength(this._filterGroups);
    this.filterEvent.emit(this.currentFilterGroup);
  }

  Collapse() {
    this.togglePanel = !this.togglePanel;
  }

  private resetDate() {
    if (this.filterForm.dirty) {
      this.filterForm.reset();
      this.filterForm.markAsPristine();
      this._dateSelection.filters.forEach(el => {
        this.filterForm.get(el.name).setValue(el.defaultValue);
      });
      this.dateChangedEmitter.emit(this.filterForm);
    }
  }

  private formViewMoreInfo(e, filterGroup) {
    const filterArray = this._filterService.GetViewMoreFilters(filterGroup);
    this.moreOptions = {
      buttonObj: {
        customSaveText: this._config.viewMoreConfig.apply,
        customCancelText: this._config.viewMoreConfig.cancel
      },
      searchHeaderOption: {
        searchPlaceHolder: `${this._config.viewMoreConfig.searchByPlaceHolder} ${filterGroup.title}`,
      },
      searchByChar: {
        charArr: this._config.viewMoreConfig.alphabets,
        all: true
      },
      position: e,
      filteredDataArr: filterArray
    };
  }


  getDatePickerInputs(formControl, placeHolderText, dateValue, minDate, maxDate) {
    return {
      className: 'filter-date',
      form: this.filterForm,
      formControlName: formControl,
      placeHolder: placeHolderText,
      value: dateValue,
      minDate: minDate,
      maxDate: maxDate
    };
  }
}
