
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormType } from '../../shared/models//form-type.entity';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Localization } from 'src/app/core/localization/Localization';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {TenantRoutes} from "../../core/Extensions/tenant-route";
import {TenantLoginService } from '../TenantLoginService.service';
import { CryptoUtility } from 'src/app/utilities/Crypto/crypto.utility';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  captions: any;
  errorMessage: { oldPassword: string, newPassword: string; confirmPassword: string; };
  setPasswordForms: UntypedFormGroup;
  passwordSetUp: UntypedFormGroup;
  fields: FormType[];
  newPwd: string;
  confirmPwd: string;
  oldPassword: string;
  npSubscribe: Subscription;
  cnSubscribe: Subscription;
  opSubscribe:Subscription;
  IsConfirmed: boolean = false;
  IsLengthValid: boolean;
  IsHavingAllTypes: boolean;
  IsSameAsUserName: boolean;
  IsHavingLowerCase: boolean;
  IsPasswordValid: boolean = false;
  IsLastPassword: boolean = true;
  IsOldPassword: boolean = false;
  buttonDisable: boolean = false;

  minCharacter: any;
  maxCharacter: any;
  allowSpecialCharacters: any;
  formatingType: any;
  allowReuse: any;
  allowUserName: any;
  confirmJson: any;
  hiddenPassword = false;
  hideConfirmPassword=false;
  commonCaptions: any;
  tenantId: any = 1;
  minmumValidationMsg:any;
  passwordReuseValidationMsg:any;
  characterValidationMsg:any;
  uTempDataPrimary : string ;
  uTempDataSecondary : string;
  debounceTime = 1500;
  isDoneValid:boolean = true;
  isConfirmPassword = false;
  isSpecialChar = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<SetPasswordComponent>,
    private utils: Localization,
    private loginService: TenantLoginService,
    private crypto: CryptoUtility,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute) {
    this.passwordSetUp = this.formBuilder.group({});
      }

  async ngOnInit() {
    this.formGenerator();
    this.errorGenerator();
    this.captionGenerator();
    this.OnFormValueChanges();
    let setuppassword: FormType[];
    let changepassword: FormType[];
    this.tenantId = this.data.tenantId;
    if (this.data.setPassword) {
      this.fields = setuppassword;
      this.setPasswordForms.controls['oldpassword'].clearValidators();
     } else {
      this.fields = changepassword;
    }
    this.confirmJson = this.data.passwordSetting;
    this.validationMessage(this.confirmJson);
    if (this.data && this.data.uTempData && this.data.uTempData.uTempPri && this.data.uTempData.uTempSec) {
      this.uTempDataPrimary = this.data.uTempData.uTempPri;
      this.uTempDataSecondary = this.data.uTempData.uTempSec;
    }
  }


  formGenerator() {
    this.setPasswordForms = this.formBuilder.group({
      oldpassword : ['',Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required],
    })
  }

  errorGenerator() {
    this.errorMessage = {
      oldPassword: "Old Password is required",
      newPassword: "New Password is required",
      confirmPassword: "Confirm Password is required"
    }
  }

  captionGenerator() {
      this.captions =  this.utils.captions;
      this.commonCaptions =  this.utils.captions;
  }

  async DoneClick(event: any) {
    let newpwd = this.setPasswordForms.controls['newpassword'].value;
    let cfmpwd = this.setPasswordForms.controls['confirmpassword'].value;
    let oldpwd: string = this.setPasswordForms.controls['oldpassword'].value;
    let isPasswordEncrypted: boolean = false;
    let isValidOldPassword: boolean = oldpwd != undefined && oldpwd.length > 0 ? true : false;
    let savePwdResponse;
    this.CheckPasswordExists(this.data.userName, newpwd, cfmpwd).then(async () => {
      if (!this.IsLastPassword) {
        if (this.uTempDataPrimary && this.uTempDataSecondary) {
          isPasswordEncrypted = true;
          newpwd = this.crypto.EncryptString(newpwd, this.uTempDataPrimary, this.uTempDataSecondary);
          if (isValidOldPassword) {
            oldpwd = this.crypto.EncryptString(oldpwd, this.uTempDataPrimary, this.uTempDataSecondary);
          }
        }
        let newPasswordDetail: NewPasswordDetail = { userName: this.data.userName, newPassword: newpwd, tenantId: this.tenantId, propertyId: 1, oldPassword: oldpwd, isPasswordEncrypted: isPasswordEncrypted };
        let serviceParams = {
          route: TenantRoutes.SavePasswordPost,
          body: newPasswordDetail,
          showError: true,
          baseResponse: true
        };
        let savePwdResponse = await this.loginService.makePostCall(serviceParams);
        if (savePwdResponse.successStatus) {
          this.dialogRef.close();
        } else {
          this.buttonDisable = true;
        }
      } else {
        this.buttonDisable = false;
      }
    });

  }

  CancelClick(event: any) {
    this.newPwd = '';
    this.confirmPwd = '';
    this.dialogRef.close();
  }
  OnFormValueChanges(): any {
    this.npSubscribe = this.setPasswordForms.get('newpassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(res => {
      this.buttonDisable=true;
      this.IsLengthValid = (res.length >= this.minCharacter) && (res.length <= this.maxCharacter) ? true : false;
      let returnFormat = this.formatingTypeValidation(this.formatingType, res, this.allowSpecialCharacters);
      if(this.setPasswordForms.controls.newpassword.value == res && res != ''){
        this.isSpecialChar =  !this.containsSpecialCharacters(res) && !this.allowSpecialCharacters && !this.containsSpecialCharacters(this.setPasswordForms.controls.confirmpassword.value);
      }
      else{
        this.isSpecialChar = false;
      }
      if(this.setPasswordForms.controls.confirmpassword.value == res && res != ''){
        this.isDoneValid = !(this.containsSpecialCharacters(res) && !this.allowSpecialCharacters);
      }
      this.IsHavingAllTypes = returnFormat ? true : false;
      this.IsSameAsUserName = res != "" ? this.allowUserName ? true :  !(res.toLowerCase().includes(this.data.firstName.toLowerCase()) || res.toLowerCase().includes(this.data.lastName.toLowerCase())) : false;
      this.PasswordValidCheck();
      if (this.IsLengthValid) {
        this.CheckPasswordExists(this.data.userName, this.setPasswordForms.controls['newpassword'].value, this)
        .then(() =>
        {
          this.buttonDisable = true;
          if(!this.IsLastPassword)
          {
              this.IsConfirmed = (this.setPasswordForms.controls['confirmpassword'].value == res && res != '') ? true : false;
              this.doneButtonChangeState();
        }
        }
        );
      } else {
        this.IsLastPassword = true;
      }

    });

    this.cnSubscribe = this.setPasswordForms.get('confirmpassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(res => {
      this.isConfirmPassword = (res != '') ? true : false;
      this.buttonDisable=true;
      if(this.setPasswordForms.controls.confirmpassword.value == res && res != ''){
        this.isSpecialChar =  !this.containsSpecialCharacters(res) && !this.allowSpecialCharacters && !this.containsSpecialCharacters(this.setPasswordForms.controls.newpassword.value);
      }
      else{
        this.isSpecialChar = false;
      }
      if(this.setPasswordForms.controls.newpassword.value == res && res != ''){
        this.isDoneValid = !(this.containsSpecialCharacters(res) && !this.allowSpecialCharacters);
      }
      this.PasswordValidCheck();
      this.CheckPasswordExists(this.data.userName, this.setPasswordForms.controls['newpassword'].value, this).then(()=>{
        if(!this.IsLastPassword)
        {
            this.buttonDisable = true;
            this.IsConfirmed = (this.setPasswordForms.controls['newpassword'].value == res && res != '') ? true : false;
            this.doneButtonChangeState();
      }
      });

    });
    if (!this.data.setPassword) {
      this.opSubscribe = this.setPasswordForms.get('oldpassword').valueChanges.pipe(debounceTime(this.debounceTime)).subscribe(async(res) => {
        this.buttonDisable=true;
        this.PasswordValidCheck();
        if (this.setPasswordForms.controls['oldpassword'].value && this.setPasswordForms.controls['oldpassword'].value.length > 0) {
          await this.VerifyPassword(this.data.userName, this.setPasswordForms.controls['oldpassword'].value);
          this.doneButtonChangeState();
        }

      });
      } else {
      this.IsOldPassword = true;
      }

  }

  doneButtonChangeState(){
    this.buttonDisable=true;
    if (this.IsPasswordValid && this.IsLengthValid && this.IsHavingAllTypes && !this.IsLastPassword && this.IsSameAsUserName && this.IsOldPassword && this.isDoneValid) {
      this.buttonDisable = !this.IsConfirmed;
    }
  }

  formatingTypeValidation(e: any, d: any, f: any) {
    let returnType;
    if (e == 1) {
      returnType = true;
    } else if (e == 2) {
      if (f) {
        let exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,}$/;
        let regexpValue = new RegExp(exp,'g');
        returnType = regexpValue.test(d)


        if(returnType)
        {
          let regx = new RegExp('^[^0-9]+$');
          returnType = regx.test(d);

        }
      } else {
        let regexpValue = new RegExp('^[A-Za-z]+$','g');
        returnType = regexpValue.test(d)

      }
    } else if (e == 3) {

      if (f) {

        let exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{10,}$/;
        let regexpValue = new RegExp(exp,'g');
        returnType = regexpValue.test(d)

            if(returnType)
              {
                let regx = new RegExp('^[^A-Za-z]+$');
                returnType = regx.test(d);
              }



      } else
      {
       let regexpValue = new RegExp('^[0-9]+$');

        returnType = regexpValue.test(d)
      }

    } else if (e == 4) {
      if (f) {
      let exp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{4,}$/;
      let regexpValue = new RegExp(exp,'g');

        returnType = regexpValue.test(d)

      } else {
        let exp =  "(?=.*[A-Za-z])(?=.*[0-9])(?!=.*[ !\"#$%&'()*+,-./:;<=>?@\\[\\]^_`{|}~\\\\])";
        let regexpValue = new RegExp(exp,'g');
        returnType = regexpValue.test(d)
      }
    }
    return returnType;
  }
  PasswordValidCheck() {
    this.IsPasswordValid = (this.IsLengthValid && this.IsHavingAllTypes && this.IsSameAsUserName);
  }
  async CheckPasswordExists(userName, password, confirmpassword: any) {
    let resp: any;
    if (password.length != 0) {
      if (this.uTempDataPrimary && this.uTempDataSecondary) {
        let newPasswordDetail: NewPasswordDetail = { userName: userName, newPassword: password, tenantId: this.tenantId, oldPassword: "", isPasswordEncrypted: false };
        let serviceParams = {
          route: TenantRoutes.CheckPasswordPut,
          header: '',
          body: newPasswordDetail,
          showError: true,
          baseResponse: true
        };
        serviceParams.body.newPassword = this.crypto.EncryptString(password, this.uTempDataPrimary, this.uTempDataSecondary);
        resp = password.length != 0 ? await this.loginService.makePutCall(serviceParams) : null;

      }
      else {
        let serviceParams = {
          route: TenantRoutes.CheckPassword,
          uriParams: { UserId: userName, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
          header: '',
          body: '',
          showError: true,
          baseResponse: true
        };
        if (password.length != 0) {
          resp = await this.loginService.makeGetCall(serviceParams);
        }
      }
      this.IsLastPassword = Boolean(resp.result);
    }


  }
   validationMessage(passwordresult: any) {
    this.minCharacter = passwordresult.minimumCharacters;
    this.maxCharacter = passwordresult.maximumCharacters;
    this.formatingType = passwordresult.formattingType;
    this.allowReuse = passwordresult.allowReuse;
    this.allowUserName = (passwordresult.allowUserName) ? true : false;
    this.allowSpecialCharacters = (passwordresult.allowSpecialCharacters) ? true : false;
    this.minmumValidationMsg = this.utils.replacePlaceholders(this.captions.PasswordMustContain,["noOfCharacters"],[this.minCharacter]);
    this.passwordReuseValidationMsg = this.utils.replacePlaceholders(this.captions.CannotBeSameAsTheLast,["noOfPasswords"],[this.allowReuse]);
    if(this.formatingType == 2)
    {
      this.characterValidationMsg = this.allowSpecialCharacters ? this.captions.OneSpecialCharactersAndAlphabets : this.captions.OnlyAlphabets;
    }
    else if(this.formatingType == 3)
    {
      this.characterValidationMsg = this.allowSpecialCharacters ? this.captions.OneSpecialCharactersAndNumbers : this.captions.OnlyNumbers;
    }
    else if(this.formatingType == 4)
    {
      this.characterValidationMsg = this.allowSpecialCharacters ? this.captions.OneSpecialCharacterNumberAlphabet : this.captions.AlphabetsAndNumbers;
    }
  }
  getReturnFormValue(event: any) {
    this.newPwd = event.confirmPwd[0].confirmPwd;
    this.confirmPwd = event.newPwd[0].newPwd;
    if (!this.data.setPassword) {
      this.oldPassword = event.oldPassword[0].oldPassword;
    }
    }
  async VerifyPassword(userName, password) {
    let resp: any;
    if (this.uTempDataPrimary && this.uTempDataSecondary) {
      let newPasswordDetail: NewPasswordDetail = { userName: userName, newPassword: password, tenantId: this.tenantId, oldPassword: "", isPasswordEncrypted: false };
      let serviceParams = {
        route: TenantRoutes.VerifyPasswordPut,
        header: '',
        body: newPasswordDetail,
        showError: true,
        baseResponse: true
      };
      serviceParams.body.newPassword = this.crypto.EncryptString(password, this.uTempDataPrimary, this.uTempDataSecondary);
      resp = await this.loginService.makePutCall(serviceParams);
    }
    else {
      let serviceParams = {
        route: TenantRoutes.VerifyPassword,
        uriParams: { UserId: userName, NewPassword: encodeURIComponent(password), TenantId: this.tenantId },
        header: '',
        body: '',
        showError: true,
        baseResponse: true
      };
      resp = await this.loginService.makeGetCall(serviceParams);
    }

    this.IsOldPassword = Boolean(resp.result);
  }
  ngOnDestroy() {
    if (this.npSubscribe) {
      this.npSubscribe.unsubscribe();
    }
    if (this.cnSubscribe) {
      this.cnSubscribe.unsubscribe();
    }
  }
  containsSpecialCharacters(text: string): boolean {
    const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return regex.test(text);
  }
}
export interface NewPasswordDetail {
  userName: string;
  newPassword: string;
  tenantId: string | number;
  propertyId?: number;
  oldPassword: string;
  isPasswordEncrypted:boolean;
}
