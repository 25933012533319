import { AfterViewInit, Directive, ElementRef, HostListener, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCharacterCount]'
})
export class CharacterCountDirective implements AfterViewInit {
  @Input() maxlength = 0;
  private percent: number;
  private countElement: HTMLElement;
  private initialSpan: HTMLElement;
  valueChangesSubscription: any;

  constructor(private element: ElementRef,@Optional() @Self() private ngControl: NgControl) {}

  ngAfterViewInit() {
    if (this.maxlength > 0) {
      this.percent = this.maxlength * 0.9;
      const currentFocus = this.element.nativeElement.parentNode.lastElementChild.lastElementChild;
      currentFocus.classList.add('w-100');
      this.initialSpan = currentFocus.appendChild(document.createElement('span'));
      this.initialSpan.classList.add('float-right');  
      this.countElement = currentFocus.appendChild(document.createElement('span'));
      this.countElement.classList.add('float-right');     
      if (this.ngControl && this.ngControl.value) {
        this.updateCharacterCount(this.ngControl.value.length);
      }
      // Subscribe to value changes
      if (this.ngControl && this.ngControl.valueChanges) {
        this.valueChangesSubscription=this.ngControl.valueChanges.subscribe((value: string) => {
          this.updateCharacterCount(value.length);
        });
      }
    }
  }

  @HostListener('input', ['$event']) 
  @HostListener('paste', ['$event'])
  onInput(event) {    
    this.updateCharacterCount(event.target.value.length);   
  }

  private updateCharacterCount(currentValueLength:any)
  {
    if (currentValueLength > this.percent) {
      this.countElement.style.display = 'block';
      this.countElement.innerHTML = currentValueLength;
      this.initialSpan.style.display='block'
      this.initialSpan.innerHTML = '/' + this.maxlength;
      if (currentValueLength == this.maxlength) {
        this.setbold();
      } else if (currentValueLength > this.maxlength){
       this.setWarning();
      }else{
        this.removebold();
      }
    } else {
      this.countElement.style.display = 'none';
      this.initialSpan.style.display='none';   
      this.countElement.innerHTML = '0';
      this.countElement.style.color = 'inherit'; 
    }
  }

  setbold(){
    this.countElement.classList.add('blink-bold');
    this.initialSpan.classList.add('blink-bold'); 
    this.countElement.style.color = 'black';
    this.initialSpan.style.color='black'; 
    setTimeout(() => {
      this.countElement.classList.remove('blink-bold');
      this.initialSpan.classList.remove('blink-bold');
    }, 800);
  }

  setWarning(){
    this.countElement.classList.add('blink-bold');
    this.initialSpan.classList.add('blink-bold');
    this.countElement.style.color = 'red';
    this.initialSpan.style.color='red'; 
    setTimeout(() => {
      this.countElement.classList.remove('blink-bold');
      this.initialSpan.classList.remove('blink-bold');
    }, 800);
  }

  removebold(){
    this.countElement.classList.remove('blink-bold');
    this.initialSpan.classList.remove('blink-bold');
  }
  
  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
