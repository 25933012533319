import { Injectable } from '@angular/core';
import { Localization } from 'src/app/core/localization/Localization';
import { localizationJSON, AlertType, ButtonType } from '../shared-models';
import { UserAccessDataService } from './useraccess.data.service';
import { Utilities } from '../directives/utilities';
import { userAccessModel } from './useraccess-model.model';

@Injectable(
    { providedIn: 'root' }
)
export class UserAccessBusiness {

    constructor(private _userAccessDataService: UserAccessDataService
        , public _utilities: Utilities
        , public _localization: Localization) { }

    public userAccessBreakpoints: localizationJSON;

    public async getUserAccess(breakPointNumber: number, showUserMessage: boolean = true, callBack?: any): Promise<userAccessModel.BreakPointResult> {
        let result = await this._userAccessDataService.getUserAccess(breakPointNumber);
        let bpMessage = this._localization.captions.breakpoint[breakPointNumber];
        if (!result.isAllow && !result.isViewOnly && showUserMessage) {
            this.showBreakPointPopup(bpMessage, callBack);
        }

        return result;
    }

    public showBreakPointPopup(functionName?: string, callBack?: any) {
        let message: string;
        message = `${this._localization.captions.common.BreakPointAccessDeniedMsg}
             <br><br>${this._localization.captions.common.Breakpoint}: ${functionName}`;

        this._utilities.showAlert(message, AlertType.AccessDenied, ButtonType.Ok, callBack);
    }

    public showBreakPointError(breakpoint: number): void {
        let message: string = this._localization.captions.breakpoint[breakpoint.toString()];
        this.showBreakPointPopup(message);
    }


    public getUserAccesses(breakpoints: number[]): Promise<userAccessModel.BreakPointResult[]> {
        console.log("getuseraccess called")
        return this._userAccessDataService.getUserAccesses(breakpoints);
    }

}